import React from 'react';
import { Helmet } from 'react-helmet';
//import Navbar from '../../Componentes/Navbar/Navbar';
// import Style from '../../Paginas/Precio/Precio.css';
import './Precio.css';

import TarifaCosto from '../../assets/img/tarifa-costo.jpg';
import IvaCosto from '../../assets/img/precio-iva.jpg';
import IconCheck from '../../assets/img/check.svg';
import IconRow from '../../assets/img/IconRow.svg';

import IconCalendar from '../../assets/img/icon-calendar.svg';
// import IconTimer from '../../assets/img/icon-timer.svg';

function Precio() {
    return (
        <div className="mt-5">
            <Helmet>
                <title>Precio | DitoBanx</title>
            </Helmet><br /><br />
            <section className="container mt-2 mt-lg-5 py-0 py-lg-4 mb-3 ">
                <div className="row">
                    <div className="col-12 col-lg-6 p-3">
                        <h1 className="fw-bold text-center text-lg-start">
                            Precios DitoBanx
                        </h1>
                        <p className="fs-5 lh-sm text-center text-lg-start">
                            Creemos en tarifas justas y precios transparentes. Cobramos una
                            tarifa de transacción del 1%, sin cargos ocultos, tarifas de conversión
                            o costos de registro.
                        </p>
                        <div className="d-grid gap-2 d-md-block">
                            <a target="_blank" rel="nofollow noreferrer" href="https://portal.ditopay.io/register" className="btn text-white btn-dark btn-lg me-2">REGISTRATE AHORA</a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="container px-1 px-lg-4">
                            <div className="row">
                                <div className="col">
                                    <div className="card border-0 rounded shadow">
                                        <img src={TarifaCosto} className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <div className="card-text">
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item border-0 p-0"><img src={IconCheck} alt="" className="p-1" />Sin contra cargos adicionales</li>
                                                    <li className="list-group-item border-0 p-0"><img src={IconCheck} alt="" className="p-1" />Sin costos ocultos</li>
                                                    <li className="list-group-item border-0 p-0"><img src={IconCheck} alt="" className="p-1" />Dépositos en USD en 24 Hrs</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card border-0 rounded shadow">
                                        <img src={IvaCosto} className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <div className="card-text">
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item border-0 p-0"><img src={IconCheck} alt="" className="p-1" />IVA sobre Comisión</li>
                                                    <li className="list-group-item border-0 p-0"><img src={IconCheck} alt="" className="p-1" />Sin costos de implementación</li>
                                                    <li className="list-group-item border-0 p-0"><img src={IconCheck} alt="" className="p-1" />Comprobantes de pago</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section><br /><br />
            <section className="container-fluid bg-light py-5">
                <div className="row offset-lg-3">
                    <div className="col-12 col-lg-8">
                        <h2 className="text-center fw-bold fs-1">
                            Retiros de bitcoins y moneda local
                        </h2><br />
                    </div>
                    <div className="col-12 col-lg-6 offset-lg-1">
                        <div className="card border-0 rounded-3 shadow">
                            <div className="bg-dark rounded-top py-2">
                                <h3 className="text-white text-center py-2">Bitcoin <img src={IconRow} alt="" /> Moneda Local <img src={IconRow} alt="" /> Banco</h3>
                            </div>
                            <div className="card-body d-flex- d-flex-row justify-content-center text-center">
                                <span className="card-text d-inline me-4">
                                    <img src={IconCalendar} alt="" width="38px" />  <b>PROGRAMADO</b>
                                    <ul className="list-group list-group-flush mt-2">
                                        <li className="list-group-item">
                                            <img src={IconCheck} alt="" className="p-1" />
                                            Procesado diariamente</li>
                                        <li className="list-group-item">
                                            <img src={IconCheck} alt="" className="p-1" />
                                            Depósito al siguiente día hábil</li>
                                        <li className="list-group-item">
                                            <img src={IconCheck} alt="" className="p-1" />
                                            Sin costos ocultos</li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section><br /><br />
            <section className="container-fluid py-lg-5 py-2 mb-5">
                <div className="row">
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-5">
                                    <h2 className="fw-bold fs-1 text-center text-lg-start">Preguntas frecuentes</h2>
                                </div>
                                <div className="col col-lg-7 mt-lg-0 mt-3">
                                    <div className="accordion shadow border-0 " id="faq">
                                        <div className="accordion-item border-top-0 border-start-0 border-end-0">
                                            <h3 className="accordion-header " id="headingOne">
                                                <button className="fw-bold accordion-button text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    ¿Que es bitcoin?
                                                </button>
                                            </h3>
                                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p className="mb-0">
                                                        Es una moneda virtual o medio de intercambio para hacer compras de productos o servicios de forma rápida, segura y de muy bajo costo de comisión, <strong>Bitcoin</strong> ahora es una moneda de curso legal en
                                                        <strong> El Salvador</strong>, con la cual puedes realizar compras en cualquier establecimiento del País.<br /><br />
                                                        Solo existirán 21 millones de Bitcoin, lo que ayuda a que Bitcoin mantenga su valor a lo largo del tiempo.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item border-top-0 border-start-0 border-end-0">
                                            <h3 className="accordion-header" id="headingTwo">
                                                <button className="fw-bold accordion-button text-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    ¿Porque integrar DitoBanx como pasarela de pago?
                                                </button>
                                            </h3>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li>
                                                        Contamos con más de 8 formas de recibir pagos en Bitcoin en tu negocio.
                                                        </li>
                                                        <li>
                                                        Plataforma robusta con acceso ilimitado y soporte técnico especializado.
                                                        </li>
                                                        <li>
                                                        Contamos con una tasa de interes por transacción más baja del mercado.
                                                        </li>
                                                        <li>
                                                            Cartera de clientes reconocidos que ya cuentan con nuestra pasarela de pago.
                                                        </li>
                                                        <li>
                                                        Soporte Técnico en Español especializado dispobible 247.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item border-top-0 border-start-0 border-end-0" >
                                            <h3 className="accordion-header" id="headingThree">
                                                <button className="fw-bold accordion-button text-dark collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    ¿Ventaja de aceptar bitcoin en un negocio?
                                                </button>
                                            </h3>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item">
                                                            Hay 18 millones de Bitcoin aproximadamente circulando a nivel mundial en lo que va del año 2021 y continúa aumentando, solo en El Salvador hay <strong> 200,000 Bitcoin</strong> circulando como moneda de curso legal.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Precio;