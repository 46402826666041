import React from 'react';
/*Import Custom Components */

import PostCardArticulos from '../../../Componentes/PostCard/PostCardArticulos';
import MailChimp from '../../../Componentes/MailChimp/MailChimp'
// import SocialShare from '../../../Componentes/SocialShare/SocialShare';

import IconBuzon from '../../../assets/img/icon-buzon.svg';


/*Import Data Articulos */
import DataPost from './Articulos.json'

/*Import Boostram Components */
import { Col, Container, Row, Card } from 'react-bootstrap';
import Helmet from 'react-helmet';
// import { Link } from 'react-router-dom';

export default class Articulos extends React.Component {
    render() {
        const urlLocation = window.location.href;
        const urlShareFacebook = urlLocation + '/';
        return (
            <>
                <Helmet>
                    <title>Articulos DitoBanx</title>
                </Helmet>
                <div className='mt-5 pt-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Row>
                        <Col>
                            <Container className='p-0'>
                                <Row>
                                    <Col className='pt-5 pt-md-4 pb-3'>
                                        <h1 className='fw-bold lh-1 text-center text-md-start fs-3'>Noticias bitcoin</h1>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Container>
                        <Row>
                            <Col className='col-12 col-md-8 rounded-3 mb-3'>
                                <Container className='p-0'>
                                    <Row>
                                        {DataPost.map((post) => (
                                            <Col  key={post.id} className='ps-0 col-12 col-md-6'>
                                                <PostCardArticulos
                                                    FeaturedImage={post.featuredimg}
                                                    Category={post.category}
                                                    Date={post.datepost}
                                                    Titulo={post.title}
                                                    TituloTwitter={post.titletwitter}
                                                    TituloLinkedin={post.titlelinkedin}
                                                    Author={post.author}
                                                    Puesto={post.puesto}
                                                    Slug={post.slugpost}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </Container>
                            </Col>
                            <Col className='col-12 col-md-4 px-0 px-md-3'>
                                <Card className='vertical-align-custom2 rounded-3 shadow-sm bg-body border-0 pt-5 mb-4'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}