import React from "react";
import { Helmet } from "react-helmet";
//Fecha: 8/2/2022
function PoliticasPrivacidad() {
  return (
    <div>
      <Helmet>
        <title>Políticas | Dito Pay</title>
      </Helmet>
      <br />
      <br />
      <div className="container-fluid bg-light mt-5">
        <div className="row">
          <div className="col-lg-9 p-5 offset-lg-2 mt-4 mb-4 text-center bg-white shadow-sm p-3 mb-5 bg-body rounded-3 ">
            <h1> Politicas de Privacidad</h1>
            <br />
            <p className="text-justify fs-5 lh-sm ">
              La presente Política de Privacidad, establece los términos en que
              DITOBANX EL SALVADOR S.A. DE C.V. usa y protege la información que
              es proporcionada por sus usuarios al momento de utilizar su sitio
              web. DITOBANX EL SALVADOR S.A. DE C.V., está comprometida con la
              seguridad de los datos de sus usuarios. Cuando le pedimos llenar
              los campos de información personal con la cual usted pueda ser
              identificado, lo hacemos asegurando que sólo se empleará de
              acuerdo con los términos de este documento. Sin embargo, esta
              Política de Privacidad puede cambiar con el tiempo o ser
              actualizada por lo que le recomendamos y enfatizamos revisar
              continuamente esta página para asegurarse que está de acuerdo con
              dichos cambios.
              <br />
              <br />
              <span className="text-uppercase">
                <b>Información que es recogida</b>
              </span>
              <br />
              Nuestro sitio web podrá recoger información personal, por ejemplo:
              Nombre, información de contacto como su dirección de correo
              electrónica e información demográfica. Así mismo cuando sea
              necesario podrá ser requerida información específica para procesar
              algún pedido o realizar una entrega o facturación.
              <br />
              <br />
              <span className="text-uppercase">
                <b>USO DE LA INFORMACIÓN RECOGIDA</b>
              </span>
              <br />
              Nuestro sitio web podrá recoger información personal, por ejemplo:
              Nombre, información de contacto como su dirección de correo
              electrónica e información demográfica. Así mismo cuando sea
              necesario podrá ser requerida información específica para procesar
              algún pedido o realizar una entrega o facturación.
              <br />
              <br />
              <span className="text-uppercase">
                <b>COOKIES</b>
              </span>
              <br />
              Una cookie se refiere a un fichero que es enviado con la finalidad
              de solicitar permiso para almacenarse en su ordenador, al aceptar
              dicho fichero se crea y la cookie sirve entonces para tener
              información respecto al tráfico web, y también facilita las
              futuras visitas a una web recurrente. Otra función que tienen las
              cookies es que con ellas las webs pueden reconocerte
              individualmente y por tanto brindarte el mejor servicio
              personalizado de su web. Nuestro sitio web emplea las cookies para
              poder identificar las páginas que son visitadas y su frecuencia.
              Esta información es empleada únicamente para análisis estadístico
              y después la información se elimina de forma permanente. Usted
              puede eliminar las cookies en cualquier momento desde su
              ordenador. Sin embargo, las cookies ayudan a proporcionar un mejor
              servicio de los sitios web, estás no dan acceso a información de
              su ordenador ni de usted, a menos de que usted así lo quiera y la
              proporcione directamente. Usted puede aceptar o negar el uso de
              cookies, sin embargo, la mayoría de los navegadores aceptan
              cookies automáticamente pues sirve para tener un mejor servicio
              web. También usted puede cambiar la configuración de su ordenador
              para declinar las cookies. Si se declinan es posible que no pueda
              utilizar algunos de nuestros servicios.
              <br />
              <br />
              <span className="text-uppercase">
                <b>ENLACES A TERCEROS</b>
              </span>
              <br />
              Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.
              <br />
              <br />
              <span className="text-uppercase">
                <b>CONTROL DE SU INFORMACIÓN PERSONAL</b>
              </span>
              <br />
              En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web. Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico. En caso de que haya marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento. DITOBANX EL SALVADOR S.A. DE C.V., no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con una orden judicial. DITOBANX EL SALVADOR S.A. DE C.V. se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.
            </p>
            <a
              className="btn btn-primary btn-lg text-white"
              href="https://ditobanx.com/policita_de_privacidad_ditobanx.pdf"
            >
              Descargar o Imprimir
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PoliticasPrivacidad;
