import React, { Component } from 'react';
import './mastercardComunicado.css';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';

/*Import custom Components */
import MailChimp from '../../../../../Componentes/MailChimp/MailChimp';
import SocialShare from '../../../../../Componentes/SocialShare/SocialShare';

/*import assets */
import IconArrow from '../../../../../assets/img/icon-row-right.svg';
// import IconFB from '../../../../../assets/img/ico-social/ico-fb.svg';
// import IconIn from '../../../../../assets/img/ico-social/ico-in.svg';
// import IconTwt from '../../../../../assets/img/ico-social/ico-twt.svg';
// import IconInsta from '../../../../../assets/img/ico-social/ico-insta.svg';

/*Import Images Feed */
import FeaturedImage from '../../../../../assets/img/img-comunicados/ditobanx-mastercard.png';
import IconBuzon from '../../../../../assets/img/icon-buzon.svg';
import Profile from '../../../../../assets/img/img-comunicados/ditobanx-mastercard.png';


export default class MastercardComunicado extends Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
                <div className='mt-5 pt-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-8 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <a href={'/noticias-prensa'} className='text-decoration-none'>
                                            <img  src={IconArrow} alt='' width={22} className='mb-2  '  />
                                            <span className='px-2 h1-custom'>Comunicados de prensa</span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="DitoBanx potencia el mercado financiero en El Salvador y lanza una billetera digital que permite a los consumidores gastar sus criptomonedas"
                                            LinkedInTitle="DitoBanx potencia el mercado financiero en El Salvador y lanza una billetera digital que permite a los consumidores gastar sus criptomonedas"
                                            Size="32px"
                                            className="d-none"

                                        />
                                    </div>
                                </div>
                                <Col className='py-3 col-12 px-2'>
                                    <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                    <span className='mx-2 color-custom-date'>JULIO 10, 2023</span>
                                </Col>
                                <Col className="mb-3 mx-2" style={{marginBottom:'4rem'}}>
                                    <img src={FeaturedImage} className="custom-featured-image img-fluid rounded-3 custom-featured-imageGf" alt='Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America' />
                                   
                                </Col>
                                <Col className="mb-3 mx-2">
                                <div className='color-custom-date py-1 px-3 border-bottom'>Image created by Team Ditobanx</div>
                                </Col>
                                <Col>
                                    <h1 className='fw-bold lh-1 pb-2 px-2'>DitoBanx potencia el mercado financiero en El Salvador y lanza una billetera digital que permite a los consumidores gastar sus criptomonedas
                                    </h1>
                                    <span className='fw-bold px-2'>Por DitoBanx</span>
                                </Col>
                                <Col className="py-3 px-2">
                                    {/* <img src={Profile} style={{ width: '18.2rem' }} className="d-block pe-3 text-center mx-auto float-center float-md-start rounded-3 pb-3 pb-md-0" alt='itoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' /> */}
                                    <p className='lh-sm text-justify'>
                                    DitoBanx, una empresa de tecnología financiera salvadoreña enfocada en la <strong>inclusión financiera</strong>, ha lanzado un producto innovador para el mercado salvadoreño:<strong> DitoBanx Wallet, una billetera digital impulsada por una tarjeta prepagada de Mastercard.</strong> 
                                    <br/><br/>

                                    Este nuevo producto, disponible para su descarga en las tiendas de aplicaciones a partir de Julio, <strong>permitirá a los usuarios vender sus tenencias de Bitcoin y la criptomoneda estable USDC y cargar la moneda fiduciaria en su tarjeta prepagada de Mastercard</strong>, la cual se puede utilizar en más de 100 millones de lugares de aceptación en todo el mundo.
                                     <br/><br/>
                                     <span  style={{fontStyle: ''}}>
                                     El lanzamiento de este producto permitirá a los salvadoreños descargar la aplicación desde la tienda de aplicaciones de sus teléfonos inteligentes y, después de un rápido proceso de registro, les proporcionará inmediatamente una tarjeta digital prepagada de Mastercard. Además, los usuarios también podrán solicitar una tarjeta física a través de la aplicación.
                                     </span>
                                     <br/><br/>
                                     
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>
                                     Guillermo Contreras, CEO de DitoBanx, consideró que este lanzamiento del producto <strong>es un paso enorme hacia la inclusión financiera en el país: "En DitoBanx, nos enfocamos en la inclusión financiera y el Bitcoin como un elemento clave de este proceso</strong>, ya que permite a los usuarios realizar pagos y recibir dinero al instante, desde cualquier lugar del mundo, en todo momento. Para obtenerlo, solo se necesita ser mayor de edad, tener una identificación y un número de teléfono".</span>
                                     
                                     <span  style={{fontStyle: 'italic'}}>
                                     La billetera digital impulsada por una tarjeta prepagada de Mastercard proporciona a los usuarios salvadoreños beneficios relevantes, como señaló Contreras:<strong> "Los usuarios pueden pagar sus facturas de agua, electricidad, teléfono y otros 120 servicios directamente a través de la aplicación, o pueden retirar efectivo de su saldo en un cajero automático, a través de un corresponsal o una cuenta bancaria tradicional".</strong>
                                     </span>
                                     <br/><br/>
                                     

                                 
                                    </p>
                                </Col>
                                
                                <Col>
                                    <h3 className='fs-5'><strong>Acerca de DitoBanx</strong> </h3>
                                    <p className='lh-sm text-justify'>
                                    DitoBanx es una empresa de tecnología financiera considerada como la "primer Fintech salvadoreña para los no bancarizados". Cuenta con una licencia de Proveedor de Servicios de Bitcoin emitida por el Banco Central, Licencia de proveedor de activos digitales y está supervisada por la Superintendencia Financiera de El Salvador. Su objetivo principal es abrir oportunidades para aquellos tradicionalmente excluidos del sector financiero mediante el uso de productos y herramientas financieras innovadoras.
                                        {/* Bolt Observer es una empresa tecnológica fundada en 2022 que se dedica a resolver la complejidad asociada a la gestión de nodos Lightning Network y liquidez, permitiendo a las empresas mejorar su experiencia y al ecosistema entero a crecer. 
                                        Sitio web: <a style={{color:'#f7931a'}} href={'https://bolt.observer/'} target="_blank" >https://bolt.observer </a>para más información puede escribir a info@bolt.observer.  */}

                                    </p>
                                </Col>

                                <Col>
                                    <h3 className='fs-5'><strong>Acerca de Mastercard (NYSE: MA)</strong> </h3>
                                    <p className='lh-sm text-justify'>
                                    Mastercard es una empresa global de tecnología en la industria de pagos. Nuestra misión es conectar y potenciar una economía digital inclusiva que beneficie a todos, en todas partes, al realizar transacciones seguras, simples, inteligentes y accesibles. Mediante el uso de datos y redes seguras, asociaciones y pasión, nuestras innovaciones y soluciones ayudan a individuos, instituciones financieras, gobiernos y empresas a alcanzar su mayor potencial. Con conexiones en más de 210 países y territorios, estamos construyendo un mundo sostenible que desbloquea posibilidades invaluables para todos.
                                    </p>
                                </Col>
                                <br/>
                                <Col>
                                   
                                    <p className='lh-sm text-justify'>
                                    Para más información sobre DitoBanx y Mastercard, porfavor visita sus respectivos sitios web.
                                    
                                    
                                    </p>
                                </Col>
                                <br/>
                                <Col>
                                    Contacto:
                                    <span> Michael Miebach, CEO</span><br/>
                                    <span> Mastercard Networks</span><br/>
                                    <span>  <a style={{color:'#f7931a'}} href={' www.mastercard.com/'} target="_blank" > www.mastercard.com/ </a>https://b2b.mastercard.com/contact-us/</span><br/><br/>
                                    <span> Guillermo Contreras, CEO</span><br/>
                                    <span> DitoBanx</span><br/>
                                    <span>  <a style={{color:'#f7931a'}} href={'https://www.ditobanx.com'} target="_blank" >www.ditobanx.com </a> hola@ditobanx.com</span><br/>
                                    
                                </Col>
                                <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                    <Row>
                                        <Col>
                                            <Card.Body className='pt-0'>
                                                <Card.Title className="mb-0 text-center text-md-start">Equipo DitoBanx</Card.Title>
                                                <span className='d-block text-center text-md-start fw-bold'>Noticias</span>
                                                <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                    Presentadora
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                        <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                            <div className="col text-center tex-md-end">
                                                <SocialShare
                                                    PostUrl={urlLocation}
                                                    TwitterTitle="DitoBanx potencia el mercado financiero en El Salvador y lanza una billetera digital que permite a los consumidores gastar sus criptomonedas"
                                                    LinkedInTitle="DitoBanx potencia el mercado financiero en El Salvador y lanza una billetera digital que permite a los consumidores gastar sus criptomonedas"
                                                    Size="32px"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='col-12 col-md-3 px-0 px-md-0 ms-0 ms-md-4'>
                                <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0 pt-5'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
