import React from 'react';
import { Helmet } from 'react-helmet';
/*Import Custom Components */

import PostComunicado from '../../../Componentes/PostCard/PostComunicado';
import MailChimp from '../../../Componentes/MailChimp/MailChimp'
import SocialShare from '../../../Componentes/SocialShare/SocialShare';

/*Imports Assets */
import imagen1 from '../../../assets/img/img-comunicados/Ditobanx, Startup en servicios btc en el salvador.png';
import ImgBTC1 from '../../../assets/img/img-comunicados/beneficio-mercado-rojo.jpg'
import ImgBTC2 from '../../../assets/img/img-comunicados/2jugadoresnba.jpg'
import giftcardImage1 from '../../../assets/img/img-comunicados/giftcardArticulo.jpg'
import comunicadoImage from '../../../assets/img/img-comunicados/boltobserver-ditobanx.png'
import qredoComunicadoImage from '../../../assets/img/img-comunicados/qredo-ditobanx.jpeg'
import activosDigComunicadoImage from '../../../assets/img/img-comunicados/activosDitoBanx.png'
import neutronpayComunicado from '../../../assets/img/img-comunicados/ditobanx-neutronpayrs.png'
import mastercardComunicado from '../../../assets/img/img-comunicados/ditobanx-mastercard.png'
import MastercardComunicadoEn from '../../../assets/img/img-comunicados/ditobanx-mastercard.png';



import IconBuzon from '../../../assets/img/icon-buzon.svg';

/*Import Custom Css */
import './NoticiasPrensa.css';

/*Import Boostram Components */
import { Col, Container, Row, Button, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

export default class NoticiasPrensa extends React.Component {
    render() {
        const urlLocation = window.location.href;
        const urlShareFacebook = urlLocation + '/';
        return (
            <>
                <Helmet>
                    <title>Comunicados de Prensa</title>
                    
                </Helmet>
                <div className='mt-5 pt-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Row>
                        <Col className='pt-4 pb-3'>
                            <h1 className='fw-bold lh-1 text-center fs-3'>Comunicados de Prensa</h1>
                        </Col>
                    </Row>
                    
                    <Container>
                    <Row>
                            <Col className='px-0'>
                                <NavLink to="/noticias-prensa/ditobanx-mastercard-en" className="text-decoration-none color-">
                                    <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0'>
                                        <Row>
                                            <Col className='col-md-6 col-12  '>
                                                <img src={MastercardComunicadoEn} className="custom-featured-image  img-fluid rounded-3" alt='Ditobanx and Mastercard' />
                                            </Col>
                                            <Col className='col-md-6 mt-1 d-flex flex-column'>
                                                <Card.Body className='pe-5'>
                                                    <Row className='d-flex flex-column'>
                                                        <Col className='lh-1 text-start col-md-12'>
                                                            <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN </Button>
                                                            <span className='mx-2 color-custom-date'>JULY 10 , 2023</span>
                                                            <h2 className='fw-bold lh-1 mt-2 pb-2'>DitoBanx enhances the financial market in El Salvador and launches a digital wallet that allows consumers to spend their cryptocurrencies

                                                            </h2>
                                                            <p className='lh-sm'>
                                                            DitoBanx, a Salvadorian Fintech focused on financial inclusion, has launched an innovative product for the Salvadorian market: Dito Wallet, a digital wallet powered by a Mastercard prepaid card.

                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Row className='ps-4 mb-2 border-0 col-12'>
                                                    <Col className='d-none d-md-block'>
                                                        <Card.Body className='pb-1 px-0'>
                                                            Published by:
                                                            <Card.Title className="mb-0 text-center text-md-start fs-custom-author">DitoBanx Team</Card.Title>
                                                            <span className='d-block text-center text-md-start speciality'>Noticias</span>
                                                        </Card.Body>
                                                    </Col>
                                                    <Col className='col-12 col-md-4 vertical-align-custom pe-0 pb-md-0 pb-3'>
                                                        {/* <SocialFollows Size="28px" /> */}
                                                        <div className="col text-center tex-md-end">
                                                            <SocialShare
                                                                PostUrl={urlShareFacebook + "ditobanx-mastercard-en"}
                                                                TwitterTitle="DitoBanx enhances the financial market in El Salvador and launches a digital wallet that allows consumers to spend their cryptocurrencies"
                                                                
                                                                LinkedInTitle="DitoBanx enhances the financial market in El Salvador and launches a digital wallet that allows consumers to spend their cryptocurrencies"
                                                                
                                                                Size="28px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </NavLink>
                            </Col>
                        </Row>
                        
                    <Row>
                            <Col className='px-0'>
                                <NavLink to="/noticias-prensa/ditobanx-mastercard-es" className="text-decoration-none color-">
                                    <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0'>
                                        <Row>
                                            <Col className='col-md-6 col-12  '>
                                                <img src={mastercardComunicado} className="custom-featured-image  img-fluid rounded-3" alt='Ditobanx y Mastercard' />
                                            </Col>
                                            <Col className='col-md-6 mt-1 d-flex flex-column'>
                                                <Card.Body className='pe-5'>
                                                    <Row className='d-flex flex-column'>
                                                        <Col className='lh-1 text-start col-md-12'>
                                                            <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN </Button>
                                                            <span className='mx-2 color-custom-date'>JULIO 10, 2023</span>
                                                            <h2 className='fw-bold lh-1 mt-2 pb-2'>DitoBanx potencia el mercado financiero en El Salvador y lanza una billetera digital que permite a los consumidores gastar sus criptomonedas

                                                            </h2>
                                                            <p className='lh-sm'>
                                                            DitoBanx, una empresa de tecnología financiera salvadoreña enfocada en la inclusión financiera, ha lanzado un producto innovador para el mercado salvadoreño: DitoBanx Wallet, una billetera digital impulsada por una tarjeta prepagada de Mastercard. 

                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Row className='ps-4 mb-2 border-0 col-12'>
                                                    <Col className='d-none d-md-block'>
                                                        <Card.Body className='pb-1 px-0'>
                                                            Publicado por:
                                                            <Card.Title className="mb-0 text-center text-md-start fs-custom-author">Equipo DitoBanx</Card.Title>
                                                            <span className='d-block text-center text-md-start speciality'>Noticias</span>
                                                        </Card.Body>
                                                    </Col>
                                                    <Col className='col-12 col-md-4 vertical-align-custom pe-0 pb-md-0 pb-3'>
                                                        {/* <SocialFollows Size="28px" /> */}
                                                        <div className="col text-center tex-md-end">
                                                            <SocialShare
                                                                PostUrl={urlShareFacebook + "ditobanx-neutronpay-es"}
                                                                TwitterTitle="Neutronpay expands to Canada and partners Ditobanx
                                                                "
                                                                LinkedInTitle="Neutronpay expands to Canada and partners Ditobanx
                                                                "
                                                                Size="28px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </NavLink>
                            </Col>
                        </Row>
                    <Row>
                            <Col className='px-0'>
                                <NavLink to="/noticias-prensa/ditobanx-neutronpay" className="text-decoration-none color-">
                                    <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0'>
                                        <Row>
                                            <Col className='col-md-6 col-12  '>
                                                <img src={neutronpayComunicado} className="custom-featured-image  img-fluid rounded-3" alt='neutronpay y DitoBanx' />
                                            </Col>
                                            <Col className='col-md-6 mt-1 d-flex flex-column'>
                                                <Card.Body className='pe-5'>
                                                    <Row className='d-flex flex-column'>
                                                        <Col className='lh-1 text-start col-md-12'>
                                                            <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN </Button>
                                                            <span className='mx-2 color-custom-date'>MAYO 16, 2023</span>
                                                            <h2 className='fw-bold lh-1 mt-2 pb-2'>Canada-based Neutronpay has launched its Lightning-powered app in Canada and partnered Ditobanx for cross-border remittances.

                                                            </h2>
                                                            <p className='lh-sm'>
                                                            Neutronpay launched its Bitcoin and Lightning mobile app in Canada, also available in Vietnam, with plans for future expansion. The app charges a simple 1% spread and allows users to pay in their local currency while abroad. Neutronpay partnered with El Salvador-based Ditobanx to help El Salvadorians in Canada send and receive money over the Lightning Network.

                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Row className='ps-4 mb-2 border-0 col-12'>
                                                    <Col className='d-none d-md-block'>
                                                        <Card.Body className='pb-1 px-0'>
                                                            Publicado por:
                                                            <Card.Title className="mb-0 text-center text-md-start fs-custom-author">Equipo DitoBanx</Card.Title>
                                                            <span className='d-block text-center text-md-start speciality'>Noticias</span>
                                                        </Card.Body>
                                                    </Col>
                                                    <Col className='col-12 col-md-4 vertical-align-custom pe-0 pb-md-0 pb-3'>
                                                        {/* <SocialFollows Size="28px" /> */}
                                                        <div className="col text-center tex-md-end">
                                                            <SocialShare
                                                                PostUrl={urlShareFacebook + "ditobanx-neutronpay"}
                                                                TwitterTitle="Neutronpay expands to Canada and partners Ditobanx
                                                                "
                                                                LinkedInTitle="Neutronpay expands to Canada and partners Ditobanx
                                                                "
                                                                Size="28px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </NavLink>
                            </Col>
                        </Row>
                  
                    <Row>
                            <Col className='px-0'>
                                <NavLink to="/noticias-prensa/ditobanx-activos-digitales-en" className="text-decoration-none color-">
                                    <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0'>
                                        <Row>
                                            <Col className='col-md-6 col-12  '>
                                                <img src={activosDigComunicadoImage} className="custom-featured-image  img-fluid rounded-3" alt='Bolt Observer y DitoBanx' />
                                            </Col>
                                            <Col className='col-md-6 mt-1 d-flex flex-column'>
                                                <Card.Body className='pe-5'>
                                                    <Row className='d-flex flex-column'>
                                                        <Col className='lh-1 text-start col-md-12'>
                                                            <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN </Button>
                                                            <span className='mx-2 color-custom-date'>ABRIL 14, 2023</span>
                                                            <h2 className='fw-bold lh-1 mt-2 pb-2'>DitoBanx Obtains Digital Asset License in El Salvador, Expanding Financial Inclusion Offerings
                                                            </h2>
                                                            <p className='lh-sm'>
                                                            DitoBanx, a leading financial technology company, announced today that it has obtained a digital asset license in El Salvador, enabling the company to expand its financial inclusion offerings in the country.
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Row className='ps-4 mb-2 border-0 col-12'>
                                                    <Col className='d-none d-md-block'>
                                                        <Card.Body className='pb-1 px-0'>
                                                            Publicado por:
                                                            <Card.Title className="mb-0 text-center text-md-start fs-custom-author">Equipo DitoBanx</Card.Title>
                                                            <span className='d-block text-center text-md-start speciality'>Noticias</span>
                                                        </Card.Body>
                                                    </Col>
                                                    <Col className='col-12 col-md-4 vertical-align-custom pe-0 pb-md-0 pb-3'>
                                                        {/* <SocialFollows Size="28px" /> */}
                                                        <div className="col text-center tex-md-end">
                                                            <SocialShare
                                                                PostUrl={urlShareFacebook + "ditobanx-activos-digitales-en"}
                                                                TwitterTitle="DitoBanx Obtains Digital Asset License in El Salvador, Expanding Financial Inclusion Offerings
                                                                "
                                                                LinkedInTitle="DitoBanx Obtains Digital Asset License in El Salvador, Expanding Financial Inclusion Offerings
                                                                "
                                                                Size="28px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </NavLink>
                            </Col>
                        </Row>

                    <Row>
                            <Col className='px-0'>
                                <NavLink to="/noticias-prensa/ditobanx-activos-digitales-es" className="text-decoration-none color-">
                                    <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0'>
                                        <Row>
                                            <Col className='col-md-6 col-12  '>
                                                <img src={activosDigComunicadoImage} className="custom-featured-image  img-fluid rounded-3" alt='Bolt Observer y DitoBanx' />
                                            </Col>
                                            <Col className='col-md-6 mt-1 d-flex flex-column'>
                                                <Card.Body className='pe-5'>
                                                    <Row className='d-flex flex-column'>
                                                        <Col className='lh-1 text-start col-md-12'>
                                                            <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN </Button>
                                                            <span className='mx-2 color-custom-date'>ABRIL 14, 2023</span>
                                                            <h2 className='fw-bold lh-1 mt-2 pb-2'>DitoBanx obtiene licencia de activos digitales en El Salvador, ampliando su oferta de inclusión financiera

                                                            </h2>
                                                            <p className='lh-sm'>
                                                            DitoBanx, una importante empresa de tecnología financiera, anunció hoy que ha obtenido una licencia de activos digitales en El Salvador, lo que le permite ampliar su oferta de inclusión financiera en el país.
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Row className='ps-4 mb-2 border-0 col-12'>
                                                    <Col className='d-none d-md-block'>
                                                        <Card.Body className='pb-1 px-0'>
                                                            Publicado por:
                                                            <Card.Title className="mb-0 text-center text-md-start fs-custom-author">Equipo DitoBanx</Card.Title>
                                                            <span className='d-block text-center text-md-start speciality'>Noticias</span>
                                                        </Card.Body>
                                                    </Col>
                                                    <Col className='col-12 col-md-4 vertical-align-custom pe-0 pb-md-0 pb-3'>
                                                        {/* <SocialFollows Size="28px" /> */}
                                                        <div className="col text-center tex-md-end">
                                                            <SocialShare
                                                                PostUrl={urlShareFacebook + "ditobanx-activos-digitales-es"}
                                                                TwitterTitle="DitoBanx obtiene licencia de activos digitales en El Salvador, ampliando su oferta de inclusión financiera
                                                                "
                                                                LinkedInTitle="DitoBanx obtiene licencia de activos digitales en El Salvador, ampliando su oferta de inclusión financiera
                                                                "
                                                                Size="28px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </NavLink>
                            </Col>
                        </Row> 
                    <Row>
                            <Col className='px-0'>
                                <NavLink to="/noticias-prensa/ditobanx-qredo" className="text-decoration-none color-">
                                    <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0'>
                                        <Row>
                                            <Col className='col-md-6 col-12  '>
                                                <img src={qredoComunicadoImage} className="custom-featured-image  img-fluid rounded-3" alt='Bolt Observer y DitoBanx' />
                                            </Col>
                                            <Col className='col-md-6 mt-1 d-flex flex-column'>
                                                <Card.Body className='pe-5'>
                                                    <Row className='d-flex flex-column'>
                                                        <Col className='lh-1 text-start col-md-12'>
                                                            <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN </Button>
                                                            <span className='mx-2 color-custom-date'>FEBRERO 10, 2023</span>
                                                            <h2 className='fw-bold lh-1 mt-2 pb-2'>Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America

                                                            </h2>
                                                            <p className='lh-sm'>
                                                            Qredo, a leading provider of institutional-grade digital asset custody solutions, and DitoBanx, a fintech for the unbanked in Latin America, today announced a partnership to offer secure and regulated digital asset custody services to banking institutions and fintech companies in the region.

                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Row className='ps-4 mb-2 border-0 col-12'>
                                                    <Col className='d-none d-md-block'>
                                                        <Card.Body className='pb-1 px-0'>
                                                            Publicado por:
                                                            <Card.Title className="mb-0 text-center text-md-start fs-custom-author">Equipo DitoBanx</Card.Title>
                                                            <span className='d-block text-center text-md-start speciality'>Noticias</span>
                                                        </Card.Body>
                                                    </Col>
                                                    <Col className='col-12 col-md-4 vertical-align-custom pe-0 pb-md-0 pb-3'>
                                                        {/* <SocialFollows Size="28px" /> */}
                                                        <div className="col text-center tex-md-end">
                                                            <SocialShare
                                                                PostUrl={urlShareFacebook + "ditobanx-qredo"}
                                                                TwitterTitle="Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America
                                                                "
                                                                LinkedInTitle="Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America
                                                                "
                                                                Size="28px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </NavLink>
                            </Col>
                        </Row>
                    <Row>
                            <Col className='px-0'>
                                <NavLink to="/noticias-prensa/ditobanx-bolt" className="text-decoration-none color-">
                                    <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0'>
                                        <Row>
                                            <Col className='col-md-6 col-12  '>
                                                <img src={comunicadoImage} className="custom-featured-image  img-fluid rounded-3" alt='Bolt Observer y DitoBanx' />
                                            </Col>
                                            <Col className='col-md-6 mt-1 d-flex flex-column'>
                                                <Card.Body className='pe-5'>
                                                    <Row className='d-flex flex-column'>
                                                        <Col className='lh-1 text-start col-md-12'>
                                                            <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN </Button>
                                                            <span className='mx-2 color-custom-date'>FEBRERO 1, 2023</span>
                                                            <h2 className='fw-bold lh-1 mt-2 pb-2'>Bolt Observer y DitoBanx anuncian alianza para investigar y mejorar los servicios de monitoreo de infraestructura Bitcoin Lightning

                                                            </h2>
                                                            <p className='lh-sm'>
                                                            Bolt Observer y DitoBanx, dos líderes en el espacio de Bitcoin, han anunciado su alianza para investigar y mejorar los servicios de administración de infraestructura de Bitcoin Lightning.

                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Row className='ps-4 mb-2 border-0 col-12'>
                                                    <Col className='d-none d-md-block'>
                                                        <Card.Body className='pb-1 px-0'>
                                                            Publicado por:
                                                            <Card.Title className="mb-0 text-center text-md-start fs-custom-author">Equipo DitoBanx</Card.Title>
                                                            <span className='d-block text-center text-md-start speciality'>Noticias</span>
                                                        </Card.Body>
                                                    </Col>
                                                    <Col className='col-12 col-md-4 vertical-align-custom pe-0 pb-md-0 pb-3'>
                                                        {/* <SocialFollows Size="28px" /> */}
                                                        <div className="col text-center tex-md-end">
                                                            <SocialShare
                                                                PostUrl={urlShareFacebook + "ditobanx-bolt"}
                                                                TwitterTitle="Bolt Observer y DitoBanx anuncian alianza para investigar y mejorar los servicios de monitoreo de infraestructura Bitcoin Lightning
                                                                "
                                                                LinkedInTitle="Bolt Observer y DitoBanx anuncian alianza para investigar y mejorar los servicios de monitoreo de infraestructura Bitcoin Lightning
                                                                "
                                                                Size="28px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </NavLink>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='px-0'>
                                <NavLink to="/noticias-prensa/ditobanx-giftcard" className="text-decoration-none color-">
                                    <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0'>
                                        <Row>
                                            <Col className='col-md-6 col-12  '>
                                                <img src={giftcardImage1} className="custom-featured-image  img-fluid rounded-3" alt='DitoBanx, giftcards' />
                                            </Col>
                                            <Col className='col-md-6 mt-1 d-flex flex-column'>
                                                <Card.Body className='pe-5'>
                                                    <Row className='d-flex flex-column'>
                                                        <Col className='lh-1 text-start col-md-12'>
                                                            <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                                            <span className='mx-2 color-custom-date'>AGOSTO 26, 2022</span>
                                                            <h2 className='fw-bold lh-1 mt-2 pb-2'>DitoBanx,  anuncia Tarjeta raspable de Bitcoin para facilitar su adquisición a los no
                                                            bancarizados

                                                            </h2>
                                                            <p className='lh-sm'>
                                                            El Salvador, 26 de Agosto de 2022. DitoBanx, fintech crypto enfocado en la inclusión
                                                                financiera anunció este día el lanzamiento de su giftcard raspable la cual facilitará la compra
                                                                de bitcoin sin necesidad de una cuenta bancaria o tarjeta de crédito.

                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Row className='ps-4 mb-2 border-0 col-12'>
                                                    <Col className='d-none d-md-block'>
                                                        <Card.Body className='pb-1 px-0'>
                                                            Publicado por:
                                                            <Card.Title className="mb-0 text-center text-md-start fs-custom-author">Equipo DitoBanx</Card.Title>
                                                            <span className='d-block text-center text-md-start speciality'>Noticias</span>
                                                        </Card.Body>
                                                    </Col>
                                                    <Col className='col-12 col-md-4 vertical-align-custom pe-0 pb-md-0 pb-3'>
                                                        {/* <SocialFollows Size="28px" /> */}
                                                        <div className="col text-center tex-md-end">
                                                            <SocialShare
                                                                PostUrl={urlShareFacebook + "ditobanx-giftcard"}
                                                                TwitterTitle="DitoBanx anuncia Tarjeta raspable de Bitcoin para facilitar su adquisición a los no bancarizados
                                                                "
                                                                LinkedInTitle="DitoBanx anuncia Tarjeta raspable de Bitcoin para facilitar su adquisición a los no bancarizados
                                                                "
                                                                Size="28px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </NavLink>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='px-0'>
                                <NavLink to="/noticias-prensa/ditoBanx-startup-en-servicios-bitcoin" className="text-decoration-none color-">
                                    <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0'>
                                        <Row>
                                            <Col className='col-md-6 col-12  '>
                                                <img src={imagen1} className="custom-featured-image  img-fluid rounded-3" alt='DitoBanx, startup en servicios Bitcoin' />
                                            </Col>
                                            <Col className='col-md-6 mt-1 d-flex flex-column'>
                                                <Card.Body className='pe-5'>
                                                    <Row className='d-flex flex-column'>
                                                        <Col className='lh-1 text-start col-md-12'>
                                                            <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                                            <span className='mx-2 color-custom-date'>ENERO 22, 2022</span>
                                                            <h2 className='fw-bold lh-1 mt-2 pb-2'>DitoBanx,  startup en servicios Bitcoin,
                                                                se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares
                                                            </h2>
                                                            <p className='lh-sm'>
                                                                El Salvador, enero de 2022. DitoBanx empresa 100% salvadoreña, con tecnología propia y con una matriz única de servicios financieros basados en Bitcoin
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Row className='ps-4 mb-2 border-0 col-12'>
                                                    <Col className='d-none d-md-block'>
                                                        <Card.Body className='pb-1 px-0'>
                                                            Publicado por:
                                                            <Card.Title className="mb-0 text-center text-md-start fs-custom-author">Equipo DitoBanx</Card.Title>
                                                            <span className='d-block text-center text-md-start speciality'>Noticias</span>
                                                        </Card.Body>
                                                    </Col>
                                                    <Col className='col-12 col-md-4 vertical-align-custom pe-0 pb-md-0 pb-3'>
                                                        {/* <SocialFollows Size="28px" /> */}
                                                        <div className="col text-center tex-md-end">
                                                            <SocialShare
                                                                PostUrl={urlShareFacebook + "ditoBanx-startup-en-servicios-bitcoin"}
                                                                TwitterTitle="DitoBanx,  startup en servicios Bitcoin, 
                                                                se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares
                                                                "
                                                                LinkedInTitle="DitoBanx,  startup en servicios Bitcoin, 
                                                                se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares
                                                                "
                                                                Size="28px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </NavLink>
                            </Col>
                        </Row>
                    </Container>
                    {/* <Container className='d-none'>
                        <Row>
                            <Col className='col-12 col-md-8 rounded-3 mb-3'>
                                <Container className='p-0'>
                                    <Row>
                                        <Col className='ps-0 col-12 col-md-6'>
                                            <PostComunicado
                                                FeaturedImage={ImgBTC1}
                                                Category="Inovacion"
                                                Date="Enero 11, 2022"
                                                Titulo="Cómo beneficiarse de un mercado en rojo"
                                                TituloTwitter="Cómo beneficiarse de un mercado en rojo"
                                                TituloLinkedin="Cómo beneficiarse de un mercado en rojo"
                                                Author="ING. GUILLERMO CONTRERAS"
                                                Puesto="CEO"
                                                Slug="comunicado"
                                            />
                                        </Col>
                                        <Col className='ps-0 col-12 col-md-6'>
                                            <PostComunicado
                                                FeaturedImage={ImgBTC2}
                                                Category="Inovacion"
                                                Date="Enero 11, 2022"
                                                Titulo="2 jugadores de la NBA recibirán parte de su salario en bitcoin"
                                                TituloTwitter="2 jugadores de la NBA recibirán parte de su salario en bitcoin"
                                                TituloLinkedin="2 jugadores de la NBA recibirán parte de su salario en bitcoin"
                                                Author="ING. GUILLERMO CONTRERAS"
                                                Puesto="CEO"
                                                Slug="comunicado"
                                            />

                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col className='col-12 col-md-4 px-0 px-md-3'>
                                <Card className='vertical-align-custom2 rounded-3 shadow-sm bg-body border-0 pt-5 mb-4'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container> */}
                </Container>
            </>
        )
    }
}