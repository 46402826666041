import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

//import custom components
import SlideClientes from '../../Componentes/SlideClientes/SlideClientes';

//import Navbar from '../../Componentes/Navbar/Navbar.js';
import './PosFisico.css';
// import VideoList from '../../Componentes/ListVideo/ListVideo';

//import images
// import Phone from "../../assets/img/Pos Fisico dito pay terminal de pagos bitcoin.png"
import Icon1 from "../../assets/img/Recibir pagos bitcoin ditopay.svg"
import Icon2 from "../../assets/img/Dito Pay Scan QR Code.svg"
import Icon3 from "../../assets/img/Transacciones Dito Pay.svg"
import Icon4 from "../../assets/img/Dito Pay Multiples Pos Fisicos.svg"
import Infobox1 from "../../assets/img/Confiabilidad Dito Pay.svg"
import Infobox2 from "../../assets/img/Agililidad dito pay.svg"
import Infobox3 from "../../assets/img/Seguridad dito pay.svg"
import Infobox4 from "../../assets/img/Terminal Movil app dito pay.svg"
import GooglePlay from "../../assets/img/googleplay-mv.png"
import celular from '../../assets/img/cellphonePOS.gif';
// import FavIconWhite from "../../assets/img/favicon-white-ditobanx.svg"

//images
import Restaurantes from "../../assets/img/Pasarela de pago bitcoin para restaurantes.jpg";
import Delivery from "../../assets/img/Acepta pagos en bitcoin negocios de servicios adomicilio.jpg";
import Tiendas from "../../assets/img/Pasarela de Pagos bitcoin para tiendas y comercios Dito Pay.jpg";
import Profesionales from "../../assets/img/Pos Movil bitcoin para profesionales.jpg";
import Empresas from "../../assets/img/Pos Movil bitcoin para empresas.jpg";
import Hoteles from "../../assets/img/Pasarela de pagos bitcoin para Hoteles.jpg";
import BannerPC from "../../assets/img/Pos Fisico - Dito Pay Terminal de pagos bitcoin.jpg";
import BannerMobile from "../../assets/img/banner-image-mobile.jpg";
// import VideoApp from "../../assets/video/ditobanx-post-bitcoin.mp4";

//import video

export default class PosFisico extends Component {
    constructor() {
        super();
        this.state = {
            width: 0
        };
        this.update = this.update.bind(this);
        window.addEventListener("resize", this.update);
    }
    componentDidMount() {
        this.update();
    }
    update = () => {
        window.scrollTo(0, 0);
        this.setState({
            width: window.innerWidth
        });
    };
    render() {
        
        return (
            <>
                <Helmet>
                    <title>DitoBanx | Procesamientos de pagos Empresariales Bitcoin</title>
                </Helmet>
                {/* Section Banner  */}
                <div className='mrtop container-fluid'>
                    <div className='row'>
                        <div className='col p-0 m-0'>
                            <a href='https://play.google.com/store/apps/details?id=com.dito.ditowallet' rel="nofollow noreferrer noopener" target="_blank">
                                <img src={this.state.width > 414 ? BannerPC : BannerMobile} className="img-fluid" alt="Recibir pagos en bitcoin desde cualquier billetera lightning y recibirlo en dólares al día siguiente hábil en la cuenta asignada de banco." />
                            </a>
                        </div>
                    </div>
                </div>
                {/* <section className="mrtop container-fluid img-fluid BannerImg d-block">
            <div className="row pt-5 pt-5">
                <div className="col d-none d-lg-block"></div>
                <div className="col-12 col-lg-6 pb-lg-3">
                    <div className="perheight"></div>
                </div>
                <div className="col d-none d-lg-block"></div>
            </div>
        </section> */}
                <section className='bg-000000 text-white'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col pt-4'>
                                <h1 className="lh-1 mb-3 mb-lg-4 text-white text-center">
                                    Recepción de pagos Empresariales
                                </h1>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Section Banner  */}
                <section className="container mb-5 mt-2">
                    <div className="row">
                        <div className="col-12 col-lg-12 py-4">
                            <h2 className="text-center fs-3 fw-bold">POS Fisico</h2>
                        </div>
                        <div className="col-12 col-lg-6 align-middle text-center pb-4 pb-lg-0">
                            <a target="_blank" rel="nofollow noreferrer" href="https://play.google.com/store/apps/details?id=com.dito.ditowallet">
                                {/* <img src={Phone} width="310" className="img-fluid" alt="Pos Fisico - App DitoBanx Terminal de Pagos Bitcoin" /> */}
                                {/* <video className='absolute bottom-0 z-0 lg:flex lg:h-full lg:pl-24' src={VideoApp} width="270" height="auto" loop="loop" playsinline autoplay="true" /> */}
                                <img 
                        className='absolute bottom-0 z-0 lg:flex lg:h-full lg:pl-24'
                        src={celular}
                        width="270" height="auto" 
                        alt='Pos Fisico - App DitoBanx Terminal de Pagos Bitcoin' 
                    />
                            </a>

                        </div>
                        <div className="col col-lg-6 d-flex align-items-center">
                            <div>
                                <div className="d-flex text-start align-items-center mb-3">
                                    <img src={Icon1} width="100" className="img-fluid " alt="Recibir pagos en bitcoin desde cualquier billetera lightning y recibirlo en dólares al día siguiente hábil en la cuenta asignada de banco" />
                                    <p className="ms-3 ptext-18 lh-sm">
                                        <strong>Recibir pagos</strong> en <strong>bitcoin</strong> desde cualquier <strong>billetera lightning</strong> y <strong>recibirlo en dólares </strong> al día siguiente hábil en la cuenta asignada de banco.
                                    </p>
                                </div>
                                <div className="d-flex text-start align-items-center my-3">
                                    <img src={Icon2} width="100" className="img-fluid " alt="Flexible a recibir pagos mediante Código QR o mediante enlace" />
                                    <p className="ms-3 ptext-18 lh-sm">
                                        Flexible a recibir <strong>pagos</strong> mediante <strong>Código QR</strong> o mediante <strong>enlace</strong>.
                                    </p>
                                </div>
                                <div className="d-flex text-start align-items-center my-3">
                                    <img src={Icon3} width="100" className="img-fluid " alt="Ver todas las transacciones realizadas fallidas y correctas directamente en la aplicación" />
                                    <p className="ms-3 ptext-18 lh-sm">
                                        Ver todas las transacciones realizadas fallidas y correctas directamente en la aplicación.
                                    </p>
                                </div><div className="d-flex text-start align-items-center my-3">
                                    <img src={Icon4} width="100" className="img-fluid " alt="Puedes tener Múltiples Pos Físicos en distintos lugares y estar centralizados en una sola plataforma robusta y segura" />
                                    <p className="ms-3 ptext-18 lh-sm">
                                        Puedes tener <strong>Múltiples Pos Físicos</strong> en distintos lugares y estar <strong>centralizados</strong> en una sola plataforma robusta y segura.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container-fluid py-3 bg-colorgray">
                    <div className="row">
                        <div className="col">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-6">
                                        <div className="card pb-2 pt-2 bg-transparent border-0">
                                            <img src={Infobox1} className="img-fluid mx-auto d-block" width="120px" alt="Confiabilidad para el cliente al momento del cobro" />
                                            <div className="card-body">
                                                <h3 className="card-title lh-1 text-black text-center fs-5 ">Confiabilidad para el cliente al momento del cobro</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="card pb-2 pt-2 bg-transparent border-0">
                                            <img src={Infobox2} className="img-fluid mx-auto d-block" width="120px" alt="Agilidad en el cobro" />
                                            <div className="card-body">
                                                <h3 className="card-title lh-1 text-black text-center fs-5 ">Agilidad en el cobro</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="card pb-2 pt-2 bg-transparent border-0">
                                            <img src={Infobox3} className="img-fluid mx-auto d-block" width="120px" alt="Seguridad en las transacciones" />
                                            <div className="card-body">
                                                <h3 className="card-title lh-1 text-black text-center fs-5 ">Seguridad en las transacciones</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="card pb-2 pt-2 bg-transparent border-0">
                                            <img src={Infobox4} className="img-fluid mx-auto d-block" width="120px" alt="Movilidad" />
                                            <div className="card-body">
                                                <h3 className="card-title lh-1 text-black text-center fs-5 ">Movilidad</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Section Player List Videos */}
                {/* <section className="container-fluid pt-4 pb-5 bg-colorgray">
            <div className="row">
                <div className="col-lg-12 mt-2 mb-3">
                    <h2 className="text-center fs-3 fw-bold"><span className="text-bitcoin">Descubre lo fácil </span> que es usar Pos Fisico de DitoBanx</h2>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 card px-4 py-3">
                    <VideoList />
                </div>
                <div className="col-lg-1"></div>
            </div>
        </section> */}
                <section className="container-fluid bg-000000">
                    <div className="row py-2">
                        <div className="col py-4 text-center text-md-end">
                            <a rel="nofollow noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.dito.ditowallet" >
                                <img src={GooglePlay} className="text-center" alt="Pos fisico - DitoBanx Terminal de Pagos" />
                            </a>
                        </div>
                        <div className='col-12 col-md-6 d-md-flex d-block align-items-center pb-4 pb-md-0'>
                            <p className="text-callaction text-center text-md-start m-0 text-white lh-sm fs-16px">
                                Descarga DitoBanx POS Bitcoin <br />desde tu teléfono Android en Google Play
                            </p>
                        </div>
                    </div>
                </section>
                <section className="container-fluid bg-colorgray py-4">
                    <div className="row">
                        <div className="col">
                            <h2 className="text-center fs-3 fw-bold py-3">¿Dónde pueden ser utilizadas las herramientas de DitoBanx?</h2>
                            <div className="container mt-4">
                                <div className="row">
                                    <div className="col-12 col-lg-4 pb-3">
                                        <div className="card rounded-6 shadow-sm pb-2">
                                            <img src={Restaurantes} className="card-img-top rounded-img-6" alt="Para hacer cobros al los clientes que usan billeteras lightning" />
                                            <div className="card-body">
                                                <h3 className="card-title text-center fs-4 fw-bold">Restaurantes</h3>
                                                <p className="card-text text-center lh-sm">Para hacer cobros al los clientes que usan billeteras lightning.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 pb-3">
                                        <div className="card rounded-6 shadow-sm pb-2">
                                            <img src={Delivery} className="card-img-top rounded-img-6" alt="Servicios de mensajería y venta de productos a domicilio." />
                                            <div className="card-body">
                                                <h3 className="card-title text-center fs-4 fw-bold">Servicios a Domicilio</h3>
                                                <p className="card-text text-center lh-sm">Servicios de mensajería y venta de productos a domicilio.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 pb-3">
                                        <div className="card rounded-6 shadow-sm pb-2">
                                            <img src={Tiendas} className="card-img-top rounded-img-6" alt="Tiendas pequeñas, medianas y grandes que desean facilitarle el pago a los clientes" />
                                            <div className="card-body">
                                                <h3 className="card-title text-center fs-4 fw-bold">Tiendas</h3>
                                                <p className="card-text text-center lh-sm">Tiendas pequeñas, medianas y grandes que desean facilitarle el pago a los clientes. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-2"></div>
                                    <div className="col-12 col-lg-4 pb-3">
                                        <div className="card rounded-6 shadow-sm pb-2">
                                            <img src={Profesionales} className="card-img-top rounded-img-6" alt="Profesionales y otros trabajadores independientes que brindan servicios, como servicios express, taxis, grúas otros." />
                                            <div className="card-body">
                                                <h3 className="card-title text-center fs-4 fw-bold">Profesionales</h3>
                                                <p className="card-text text-center lh-sm">Profesionales y otros trabajadores independientes que brindan servicios, como servicios express, taxis, grúas otros.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 pb-3">
                                        <div className="card rounded-6 shadow-sm pb-2">
                                            <img src={Empresas} className="card-img-top rounded-img-6" alt="Grandes, medianas y pequeñas empresas que transaccionan a diario productos o servicios" />
                                            <div className="card-body">
                                                <h3 className="card-title text-center fs-4 fw-bold">Empresas</h3>
                                                <p className="card-text text-center lh-sm">Grandes, medianas y pequeñas empresas que transaccionan a diario productos o servicios.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 pb-3">
                                        <div className="card rounded-6 shadow-sm pb-2">
                                            <img src={Hoteles} className="card-img-top rounded-img-6" alt="Para Hoteles o Personas que alquilan sus inmuebles por días por contratos definidos" />
                                            <div className="card-body">
                                                <h3 className="card-title text-center fs-4 fw-bold">Hoteles</h3>
                                                <p className="card-text text-center lh-sm">Para Hoteles o Personas que alquilan sus inmuebles por días por contratos definidos.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-12">
                                        <p className="text-center lh-1">
                                            Al crear una cuenta en DitoBanx, tendrás acceso a todos nuestros servicios, los cuales no te generan un costo adicional por usar más de uno de nuestros servicios, DitoBanx puede usarlo tanto persona Jurídica como persona natural, si deseas comenzar a cobrar a tus clientes en bitcoin haz clic en el botón.
                                        </p>
                                        <div className="text-center mt-4">
                                            <a className="btn bg-000000 text-white btn-lg" rel="nofollow noreferrer" target="_blank" href="https://portal.ditobanx.com/">ABRIR CUENTA EN DITOBANX</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container py-4 ">
                        <div className="row">
                            <div className="col py-4 ">
                                <h2 className="text-center fw-bold">Aliados<span className="text-bitcoin"> comerciales</span></h2>
                                <p className="mt-3 fs-5 text-center lh-1">
                                    Empresas y plataformas que ya cuentan con la tecnología de Dito Pay integrada.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="fluid-container bg-light py-4 py-lg-5">
                        <div className="container">
                            <div className="row">
                                <SlideClientes />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}