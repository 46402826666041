/* eslint-disable react/style-prop-object */
import React from 'react';
import { Helmet } from 'react-helmet';
// Fecha: 8/2/2022
function WalletTerminosCondiciones() {
    return (
        <div>
            <Helmet>
                <title>Términos | Dito Pay</title>
            </Helmet>
            <br /><br />
            <div className="container-fluid bg-light mt-5">
                <div className="row">
                    <div className="col-lg-9 p-5 offset-lg-2 mt-4 mb-4 text-center bg-white shadow-sm p-3 mb-5 bg-body rounded-3 ">
                        <h1 className="fs-2"> Wallet de Personas - Terminos y Condiciones</h1>
                        {/* <span>Version 2.0</span> */}
                        <p className="text-center lh-sm">
                           DitoBanx
                        </p>
                        <br />
                        <p className="text-justify lh-sm">
                        Términos y condiciones de uso (en adelante los <u >"Términos de Uso"</u> o <u>“Términos”</u>, indistintamente) para la prestación de los servicios de administración, redención, conversión y transmisión de Bitcoin y Activos Digitales (según dicho término se define más adelante) que ofrece Ditobanx El Salvador, S.A. de C.V., (<u>"Ditobanx"</u> o el “Proveedor”, indistintamente), con Número de Identificación Tributaria: 0614-200522-113-8. 
                        </p>
                        <p className="text-justify lh-sm">
                        Por favor, lee atentamente estos Términos de Uso, así como cualquier otro documento relacionado, mismos que comprenderán los acuerdos entre tu (en adelante <u>“Tu”</u> o <u>“Cliente”</u> y conjuntamente con Ditobanx como las “Partes”) y Ditobanx para la prestación de los Servicios (según dicho término se define más adelante).
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx está debidamente registrada como proveedor de servicios Bitcoin ante el Banco Central de la Reserva de El Salvador bajo el número de registro 64b4e4f40715645e0c514070, de conformidad con la obligación establecida en el Artículo 3 del Reglamento de la Ley Bitcoin y registrado ante la Comisión Nacional de Activos Digitales como Proveedor de Servicios de Activos Digitales, bajo el numero P/02-2023. De conformidad con la normatividad aplicable, Ditobanx en su carácter de proveedor de servicios Bitcoin y de Activos Digitales en la República de El Salvador se encuentra bajo la inspección y vigilancia de la Superintendencia del Sistema Financiero (“SSF”) y la Comisión Nacional de Activos Digitales (“CNAD”). 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong> TÉRMINOS DE USO </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Los presentes Términos de Uso rigen el contrato de prestación de servicios de  administración, redención y transmisión de Bitcoin y Activos Digitales, que celebran, por una parte Ditobanx y por la otra parte Tu, por tu propio derecho y en cuenta propia, por lo que al acceder y/o al hacer uso de los Servicios de Ditobanx y sus Aplicaciones (según dicho término se define más adelante), o cualquier otro medio electrónico que te permita usar cualquier Servicio prestado por Ditobanx, aceptas los presentes Términos de Uso, mismos que serán válidos y su vigencia iniciará a partir del momento en que utilices los Servicios. Ditobanx declara por medio de su representante, que es una sociedad de naturaleza comercial debidamente constituida conforme a las leyes de la República de El Salvador. 
                        </p>
                        <p className="text-justify lh-sm">
                        Los presentes Términos de Uso rigen el contrato de prestación de servicios de  administración, redención y transmisión de Bitcoin y Activos Digitales, que celebran, por una parte Ditobanx y por la otra parte Tu, por tu propio derecho y en cuenta propia, por lo que al acceder y/o al hacer uso de los Servicios de Ditobanx y sus Aplicaciones (según dicho término se define más adelante), o cualquier otro medio electrónico que te permita usar cualquier Servicio prestado por Ditobanx, aceptas los presentes Términos de Uso, mismos que serán válidos y su vigencia iniciará a partir del momento en que utilices los Servicios. Ditobanx declara por medio de su representante, que es una sociedad de naturaleza comercial debidamente constituida conforme a las leyes de la República de El Salvador. 
                        </p>
                        <p className="text-justify lh-sm">
                        El uso y acceso a nuestro sitio o a nuestros Servicios no está permitido desde aquellos estados o jurisdicciones respecto de las cuales, de conformidad con diversas prohibiciones legales y normativas, (i) no sea posible realizar dichas operaciones de Bitcoin y/o Activos Digitales o (ii) en caso de que consideremos que existe un riesgo de operación al realizar operaciones en ciertas jurisdicciones y/o que Ditobanx considere que no sea posible realizar dichas operaciones. Dependiendo del lugar de conexión del Cliente algunos Servicios pudieran no estar disponibles. Ditobanx no se hace responsable de las acciones que tomen los Clientes en desacato de esta previsión. Los servicios que Ditobanx ofrece están dirigidos a salvadoreños y extranjeros debidamente identificados. Si el Cliente no es residente salvadoreño, para efectos de nuestra relación jurídica, las leyes aplicables serán las salvadoreñas.
                        </p>
                        <p className="text-justify lh-sm">
                        <strong> CLÁUSULAS  </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Primera - Objeto.  </strong>
                        </p>

                        <p className="text-justify lh-sm">
                        El presente acuerdo de voluntades tiene por objeto regular la relación existente entre Ditobanx y el Cliente, en el entendido de que Ditobanx fungirá como una plataforma de Bitcoin y Activos Digitales, a la cual tendrá acceso el Cliente y a partir de la cual el Cliente podrá enviar recursos y disponer de sus propios en Bitcoin y Activos Digitales, realizar pagos en dichos activos, así como enviar Bitcoin y Activos Digitales a diversos destinatarios. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Segunda – Consentimiento.   </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Las Partes acuerdan los Términos de Uso son aceptados mediante el uso de la plataforma o la aplicación, es decir los Servicios, independientemente de que exista o no algún medio de aceptación (incluyendo sin limitar cualquier click o acto de consentimiento expreso), (i) al acceder o hacer uso de los Servicios de Ditobanx o cualquiera de sus Aplicaciones, (ii) acceder al sitio web de Ditobanx o cualquiera de sus Aplicaciones o (iii) al usar cualquier servicio prestado por Ditobanx. Si los Términos de Uso no resultan aceptables para el Cliente, éste debe parar inmediatamente el uso de las Aplicaciones, dejar de acceder a las Aplicaciones de Ditobanx y dejar de usar cualquier servicio, plataforma, medio de disposición o pago, operado o administrado por Ditobanx. 
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente reconoce que la aceptación de los presentes Términos de Uso tiene el mismo efecto jurídico que si hubiere plasmado la firma física o electrónica en un acuerdo de voluntades y acepta que su consentimiento será recabado de manera electrónica conforme con la legislación aplicable. De igual forma acepta que la totalidad de sus datos recabados de forma electrónica podrán ser verificados para determinar su autenticidad. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Tercera – Definiciones.   </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Para efectos de los Términos de Uso, los términos con letra mayúscula inicial tendrán los significados que se señalan a continuación, excepto en aquellas ocasiones en las que no sean conformes con el texto: 
                        </p>
                        <p className="text-justify lh-sm">
                            <ul>
                                <li><strong>"Aplicación", "Aplicaciones"</strong> Medio de acceso a los Servicios, tanto en plataforma móvil como en la plataforma web.</li>
                                <li><strong>"Ditobanx", "Nosotros", "Nuestro", "Sociedad"</strong> o demás términos equivalentes: Ditobanx El Salvador, S.A. de C.V.</li>
                                <li><strong>"Comisión"</strong> Las tarifas a las que estarán sujetos los Servicios. </li>
                                <li><strong>"Criptoactivos"</strong> Aquellos fondos que estén contabilizados en un registro electrónico de cuentas transaccionales, incluyendo Bitcoin y diversos activos digitales, que podrán disponerse en criptoactivos de valor estable (“stable-coins”), es decir, referidos a un valor monetario equivalente a una cantidad determinada de dinero, en moneda nacional y/o Bitcoin u otros activos virtuales, en su caso. </li>
                                <li><strong>"Servicios"</strong>: los servicios que presta Ditobanx que consisten en la administración, redención, compra, conversión, venta y transmisión de Criptoactivos. </li>
                                <li><strong>"Usuario", "Usted" o "Clientes"</strong> indistintamente, los usuarios de los Servicios de Ditobanx     , incluyendo sin limitar a los usuarios registrados, así como cualquier visitante que utilice las Aplicaciones de Ditobanx, sin importar el nivel en que su cuenta se encuentre verificada. </li>
                                <li><strong>"Wallet"</strong> las cuentas de Criptoactivos aperturadas en la Ditobanx para cada Cliente que tienen como finalidad almacenar y reflejar la información de valor y almacenamiento de Criptoactivos, así como de las distintas transacciones de Criptoactivos realizadas por los Clientes, al encontrarse ligada y referida a los diversos sistemas de compensaciones, abonos y pagos utilizados por Ditobanx. </li>
                                <li><strong>“Comprador”</strong> significa un Titular de Cuenta que coloca una Orden para comprar Criptoactivos a través de la Plataforma y realizar una Operación.</li>
                                <li><strong>“Protocolo de Confirmación”</strong> significa las condiciones requeridas para que una transferencia de Criptoactivos específica en el blockchain o red específica se considere liquidada y completada. En algunos casos, este protocolo de confirmación puede significar que una operación se ha registrado en un bloque y una cantidad de bloques subsiguientes consecutivos que se refieren a dicho bloque se han agregado al blockchain de dicho Criptoactivos de tal manera que cualquier reversión o intento de reversión de tal registro se vuelve económicamente impracticable. </li>
                                <li><strong>“Plataforma” </strong>significa la plataforma digital www.ditobanx.com, disponible a través de las Aplicaciones, para la compra, venta y almacenamiento de Criptoactivos. </li>
                                <li><strong>“Transacción”</strong> significa (i) depositar fondos en tu cuenta; (ii) el uso de los Servicios y/o de las Aplicaciones para el comercio de Criptoactivos; (iii) retirar fondos de tu cuenta, (iv) transferencia entre cada titular de Cuenta; y (v) la conversión de Criptoactivos en la Plataforma a dinero fiduciario o Fiat.</li>
                            </ul>
                        </p>
                        <p className="text-justify lh-sm">
                        Los términos en singular incluyen los términos en plural y viceversa. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Cuarta – Registro.   </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Para poder utilizar el Sitio Web o la App, así como todos los Servicios de Ditobanx, debe ser mayor de edad o, en su defecto, acceder bajo la supervisión y consentimiento de sus padres o tutor legal. Cualquier acceso o uso por personas incapaces legalmente de contratar es prohibido y responsabilidad de su representante legal. A través del uso continuado a nuestras Aplicaciones, así como la aceptación de los presentes Términos de Uso, el Cliente reconoce y conviene que deberá estar registrado conforme a las políticas aplicables de Ditobanx.
                        </p>
                        <p className="text-justify lh-sm">
                        Será necesario crear una cuenta en la plataforma para poder utilizar algunos de nuestros Servicios. Para eso, se le pedirá una contraseña y un nombre de usuario, además de algunos datos para el registro. Deberá proporcionar información certera, completa y actualizada. En caso de que dicha información sufra modificaciones, usted acuerda que será su responsabilidad mantenerla al día y actualizada; velando siempre porque sea información completa y certera. No hacerlo representa una violación de las Condiciones y podría significar la cancelación inmediata de la cuenta.
                        </p>
                        <p className="text-justify lh-sm">
                        Al registrarse, cada uno de los Clientes recibirá un nombre de usuario el cual, junto con su correo electrónico de registro y su número telefónico registrado, mismos que fungirá como dato identificador e individualizador del Cliente frente a la Plataforma. Para poder acceder a la Plataforma será necesario que realice un proceso de verificación de identidad que podrá ser realizado por Nosotros o por medio de terceros. Como nombre de usuario no podrá utilizar el nombre de otra persona o entidad sobre los que no tenga derechos, tampoco algún nombre o marca cuyos derechos le pertenezcan a otra persona o entidad a menos que cuente con la autorización debida, ni alguno que resulte ofensivo, vulgar y obsceno. Además, queda prohibida la apertura de cuentas a robots o métodos automatizados.
                        </p>
                        <p className="text-justify lh-sm">
                        Es responsabilidad del usuario cuidar sus datos de ingreso y credenciales, además, el único responsable de cualquier actividad dentro de su cuenta. Si sufre alguna violación de seguridad o identifica algún tipo de actividad no autorizada, deberás notificarlo a Ditobanx de manera inmediata. Ditobanx no se hace responsable por cualquier acto u omisión del usuario, incluyendo cualquier tipo de daños que resulten de lo anterior.
                        </p>
                        <p className="text-justify lh-sm">
                        El registro de más de una Wallet para una misma persona está prohibido ya que tal hecho implica, de acuerdo con nuestros controles, la alteración parcial o total de información para poder crear otro registro. Lo anterior implica el cierre de ambas cuentas y la imposibilidad de volver a operar en la plataforma de Ditobanx. 
                        </p>
                        <p className="text-justify lh-sm">
                        Sujeto a estos Términos y Condiciones, y todas nuestras políticas, se le otorga al Cliente una licencia personal, limitada, no exclusiva, no transferible y completamente revocable, a fin de que pueda hacer uso de nuestros Servicios. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Quinta- Servicios.   </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Los Servicios de Ditobanx tienen como finalidad ser un medio para que los Clientes de forma personal e independiente abonen recursos a su Wallet por medio de los diferentes canales que podremos a su disposición para llevar a cabo la transferencia de Criptoactivos. 
                        </p>
                        <p className="text-justify lh-sm">
                        Cualquier recurso, abono o cantidad en dinero que Ditobanx reciba por parte de los canales, generará la emisión de Criptoactivos los cuales serán imputados a la Wallet del Cliente respectiva con la finalidad de que dichos Clientes estén en posibilidad de utilizar los Servicios y realizar transferencias, sin que Ditobanx deba abonar intereses, premio o prima alguna de cualquier naturaleza, por lo que el Cliente reconoce que el servicio de Wallet no es un servicio de captación de fondos o depósito de fondos, y libera a Ditobanx de cualquier responsabilidad al respecto, fuera de aquellos previstos por los ordenamientos vigentes. 
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx, o un tercero actuando por Ditobanx, mantendrá los recursos recibidos de cualquier Cliente hasta en tanto sean entregados a un beneficiario o destinatario, sean transferidos a otra entidad facultada para participar en servicios de pago a que haya lugar, se realice una conversión a fiat o dichos Clientes adquieran Criptoactivos.
                        </p>
                        <p className="text-justify lh-sm">
                        Las obligaciones, los Servicios y lo relativo a Criptoactivos descritos en los presentes Términos de Uso, no se encuentran garantizados por ninguna subsidiaria, afiliada o tercero, incluyendo a cualquier autoridad o gobierno. 
                        </p>
                        <p className="text-justify lh-sm">
                        Las transacciones de los Clientes se entenderán realizadas y por lo tanto eficaces, cuando estas hayan sido confirmadas por Ditobanx y no necesariamente cuando el Cliente las ha iniciado u ordenado. El Cliente reconoce y asume cualquier riesgo que se derive de las operaciones con Criptoactivos bajo los presentes Términos de Uso. 
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente reconoce que, aún y cuando Ditobanx cuenta con todos los medios de seguridad tecnológica, existen diversos riesgos tecnológicos y cibernéticos, incluyendo el fraude, que son inherentes al uso de medios electrónicos o digitales en el sistema financiero, incluyendo la operación con Criptoactivos, de las Aplicaciones y al uso de plataformas tecnológicas en general de los cuales, en ningún caso, Ditobanx resultará responsable. 
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente reconoce y acepta que los distintos Servicios de Ditobanx no cuentan con seguro alguno sobre el depósito de los Criptoactivos y/o sobre los recursos del Cliente.
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx se encontrará en posibilidad de reembolsar al Cliente, cuando éste así lo solicite, la cantidad equivalente a los Criptoactivos emitidos de que dicho Cliente disponga en los registros respectivos de Ditobanx, siempre y cuando proporcione los datos para realizar la conversión a fiat y no se hayan entregado a un beneficiario o destinatario, o bien, transferido a otra entidad facultada para participar en servicios de pago a que haya lugar, de acuerdo con lo dispuesto por los Términos de Uso y las órdenes de dicho Cliente. Asimismo, el cliente podrá requerir que su reembolso sea remitido en Cripotactivos, para lo cual deberá proporcionar los datos de la nueva billetera a reembolsar, misma que deberá cumplir los parámetros internos de cumplimiento respectivos.
                        </p>
                        <p className="text-justify lh-sm">
                        La operación de la plataforma podría restringirse parcial o totalmente en cualquier momento, de manera programada o de manera repentina, lo que pudiera generar la no ejecución de Operaciones pendientes, pudiendo ocasionar un perjuicio económico al Cliente, sin embargo, el Cliente, por medio de la aceptación de los presentes Términos de Uso, acepta que Ditobanx no es ni será responsable, bajo ningún motivo y en ninguna circunstancia responsable de los daños o perjuicios que situaciones similares o idénticas pudieran causar, obligándose a sacar en paz y a salvo a la Ditobanx o de cualquier reclamación que pudiera presentarse en este sentido o en otros similares. 
                        </p>
                        <p className="text-justify lh-sm">
                        Las pérdidas por transacciones fraudulentas o accidentales pueden no ser recuperables y, adicionalmente, una vez que hayan sido confirmadas, el Cliente estará obligado a cumplirlas. Ditobanx no tiene responsabilidad alguna respecto de todas aquellas operaciones realizadas por los Clientes cuando éstas sean fraudulentas o accidentales o sean producto de la negligencia por parte de los Clientes en el cuidado de sus llaves de acceso públicas o contraseñas privadas, conforme a lo establecido en la cláusula Novena de los presentes Términos de Uso. En caso de que la Ditobanx      con la autorización de las autoridades correspondientes llegase a poner a disposición de sus Clientes medios de disposición por medio de tarjetas, Ditobanx no será responsable por el robo, extravío o mal uso de las mismas, a excepción de aquellas operaciones que hayan sido efectuadas una vez que el Cliente haya notificado a Ditobanx del robo o extravío de las mismas por medio de los canales de contacto destinados para ello. 
                        </p>
                        <p className="text-justify lh-sm">
                        Los Servicios son y serán prestados exclusivamente por Ditobanx, las 24 horas los 365 días del año, a excepción de aquellos horarios en que se programen mantenimientos o que por cuestiones de fuerza mayor o ajenas a Ditobanx sea necesario interrumpir la operatividad de la plataforma, quien asume todas las responsabilidades y obligaciones conforme a los Términos de Uso, y es titular o tiene derecho al uso de las interfaces, páginas de internet o cualquier otra dirección o liga que se indican en los presentes Términos de Uso, siendo Ditobanx el único responsable de su utilización frente a los Clientes. Cuando el Cliente realiza una Operación, Ditobanx podrá retener el monto disponible correspondiente a los Servicios solicitados, de tal forma que los recursos estén disponibles para completar la Operación. 
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx mantiene la totalidad de los recursos de los Clientes en custodia, en los tiempos y plazos necesarios hasta recibir una instrucción distinta del Cliente. Dichos recursos estarán disponibles para ser utilizados para los Servicios que ofrece Ditobanx mediante el sitio o sus Aplicaciones, así como para cualquier transferencia entre los Clientes. 
                        </p>
                        <p className="text-justify lh-sm">
                        Por seguridad de nuestros Clientes, no asumimos responsabilidad alguna, ni seremos responsables de forma alguna, por cualquier transmisión de Criptoactivos que se haya realizado en términos de la instrucción e información proporcionada por el Cliente. El Cliente reconoce y acepta que las transferencias desde su Wallet, con independencia de la identidad del beneficiario o destinatario de dicha operación, son realizadas bajo su entera responsabilidad y en ningún caso Ditobanx será responsable. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Sexta- Transacciones   </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Los Criptoactivos que podrán ser objeto de las Transacciones serán únicamente aquellos que  Ditobanx determine de tiempo en tiempo con la autorización de la SSF y el CNAD, encontrándose dentro de estos los activos digitales de valor estable aparejados a las monedas en curso legal dentro del territorio de la República de El Salvador. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Conversiones   </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Mediante determinados Servicios que identificamos expresamente en las Aplicaciones puedes convertir Criptoactivos a través de los diferentes canales provistos por nosotros, para lo cual pueden aplicar comisiones específicas. 
                        </p>
                        <p className="text-justify lh-sm">
                        Dado que la Conversión podría requerir integración con otras redes de Criptoactivos, nos encontramos sujetos a sus términos y a ciertas reglas de no mantener los precios en relación con el valor de divisas fiduciarias, (por ejemplo, fijar el Dólar de los Estados Unidos de América y un Criptoactivos  en paridad 1:1). Por lo tanto, no podemos garantizar que en todo momento la paridad se mantendrá 1:1, ya que podrán presentarse ligeras variaciones. 
                        </p>
                        <p className="text-justify lh-sm">
                        Cambios legislativos y regulatorios o actos de la autoridad podrán afectar de manera negativa la tokenización de los Criptoactivos, como la conversión del Dólar de los Estados Unidos de América en Criptoactivos (por ejemplo, el USDC) y el uso, transferencia, redención y/o valor de los Criptoactivos (por ejemplo, el USDC). 
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente podrá realizar operaciones de cash-in o cash-out en Criptoactivos por un límite que corresponda a los niveles que Ditobanx determine de tiempo en tiempo. El ingreso de efectivo a la Plataforma quedará reflejado en dólares de los Estados Unidos de América.  Los Clientes, ya sean personas físicas o personas morales deberán cumplir con los requisitos de identificación que Ditobanx establezca de tiempo en tiempo de conformidad a sus políticas de Conoce a tu Cliente o Proveedor. 
                        </p>
                        <p className="text-justify lh-sm">
                        Habiendo cumplido con los requerimientos de información y documentación mencionados, el Cliente podrá utilizar su Wallet con los límites de depósitos, transacciones y de operaciones mensuales determinados. Lo anterior no exime al Cliente de entregar o actualizar la información y/o documentación que Ditobanx, de tiempo en tiempo, solicite en cumplimiento del contenido de los manuales internos de control de riesgo y prevención de lavado de dinero. 
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx se reserva el derecho a no abrir Wallets a personas que, a su más entera discreción, no satisfagan los requisitos o que por cualquier otra razón no cumplan, al más entero juicio de Ditobanx, cualquiera de las situaciones que éste determine de tiempo en tiempo en atención a los intereses de Ditobanx y el cumplimiento de cualquier legislación nacional o internacional. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Séptima  - Comisiones   </strong>
                        </p>
                        <p className="text-justify lh-sm">
                         Las Comisiones por Transacciones aplican a determinados Servicios, tal como se establece a continuación: 
                        </p>
                        <p className="text-justify lh-sm">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td><strong>CONCEPTO</strong></td>
                                        <td><strong>COSTO</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>APERTURA DE CUENTA DITO WALLET PERSONAS</td>
                                        <td>SIN COSTO</td>
                                    </tr>
                                    <tr>
                                        <td class='text-center'><strong>CONVERSION DE CRIPTOMONEDAS</strong></td>
                                        <td>{' '}</td>
                                    </tr>
                                    <tr>
                                        <td>CONVERSION DE  BTC a MONEDA ESTABLE ( USDC - USDT )</td>
                                        <td>1%</td>
                                    </tr>
                                    <tr>
                                        <td>CONVERSION DE  MONEDA ESTABLE ( USDC - USDT ) A BTC</td>
                                        <td>1%</td>
                                    </tr>
                                    <tr>
                                        <td>CONVERSION DE  OTRAS MONEDAS A BTC/MONEDA ESTABLE</td>
                                        <td>1%</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class='text-center'><strong>ENVIO / RECEPCION DE SALDOS ENTRE USUARIOS DITO WALLET</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>ENVIO/RECEPCION DE BTC ENTRE USUARIOS DITO WALLET </td>
                                        <td>SIN COSTO</td>
                                    </tr>
                                    <tr>
                                        <td>ENVIO/RECEPCION DE MONEDA ESTABLE ENTRE USUARIOS DITO WALLET </td>
                                        <td>SIN COSTO</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class='text-center'><strong>TRANSFERENCIA/ RECEPCION DE SALDOS A WALLETS EXTERNAS</strong></td>
                                        <td>{' '}</td>
                                    </tr>
                                    <tr>
                                        <td>RECEPCION DE BTC VIA ONCHAIN o LIGHTNING DESDE WALLET EXTERNA</td>
                                        <td>SIN COSTO*</td>
                                    </tr>
                                    <tr>
                                        <td>RECEPCION DE MONEDA ESTABLE USDC o USDT DESDE WALLET EXTERNA</td>
                                        <td>SIN COSTO*</td>
                                    </tr>
                                    <tr>
                                        <td>ENVIO DE BTC VIA ONCHAIN o LIGHTNING HACIA WALLET EXTERNA</td>
                                        <td>SIN COSTO*</td>
                                    </tr>
                                    <tr>
                                        <td>ENVIO DE MONEDA ESTABLE USDC o USDT HACIA WALLET EXTERNA</td>
                                        <td>SIN COSTO*</td>
                                    </tr>
                                    <tr>
                                        <td>*Aplican costos de confirmacion de la Red Bitcoin o Ethereum</td>
                                        <td>{' '}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class='text-center'><strong>OPERACIONES CON USD</strong></td>
                                        <td>{' '}</td>
                                    </tr>
                                    <tr>
                                        <td>RETIRO DE EFECTIVO EN CORRESPONSALES DE RED </td>
                                        <td>1% + $1.25</td>
                                    </tr>
                                    <tr>
                                        <td>RETIRO DE EFECTIVO POR MEDIO DE CUENTA BANCARIA</td>
                                        <td>1%</td>
                                    </tr>
                                    <tr>
                                        <td>PAGO DE RECIBOS DE PROVEEDORES DE SERVICIOS</td>
                                        <td>SIN COSTO</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class='text-center'><strong>OPERACIONES CON TARJETA PRE-PAGO MASTERCARD</strong></td>
                                        <td>{' '}</td>
                                    </tr>
                                    <tr>
                                        <td>ASIGNACION DE FONDOS A TARJETA POR MEDIO DE WALLET DIGITAL</td>
                                        <td>SIN COSTO</td>
                                    </tr>
                                    <tr>
                                        <td>SOLICITUD DE TARJETA DIGITAL</td>
                                        <td>SIN COSTO</td>
                                    </tr>
                                    <tr>
                                        <td>SOLICTUD DE TARJETA FISICA</td>
                                        <td>$5.00 ANUALES</td>
                                    </tr>
                                    <tr>
                                        <td>RETIRO DE EFECTIVO EN ATMs de RED PARTICIPANTES</td>
                                        <td>1%</td>
                                    </tr>
                                    <tr>
                                        <td>RETIRO DE EFECTIVO EN ATMs de RED NO PARTICIPANTES</td>
                                        <td>1% + Comision ATM</td>
                                    </tr>
                                    <tr>
                                        <td>REPOSICION DE TARJETA FISICA</td>
                                        <td>$5.00</td>
                                    </tr>
                                    <tr>
                                        <td>ENVIO DE TARJETA FISICA POR COURIER </td>
                                        <td>VARIABLE X ZONA</td>
                                    </tr>
                                </tbody>
                            </table>
                        </p>
                        <br/>
                        <p className="text-justify lh-sm">
                        Al completar una Transacción, aceptas que, en caso de ser aplicable, la Comisión por Transacción aplicable será deducida automáticamente de la Orden a la tarifa calculada por la Plataforma. Esto resultará en que recibas el monto neto de la Orden, el cual representa el monto original menos la Comisión por Transacción. 
                        </p>
                        <p className="text-justify lh-sm">
                        Nos reservamos el derecho, y aceptas otorgarnos el derecho de modificar unilateralmente, de tiempo en tiempo, cualquiera de las Comisiones por Transacciones. Cuando se modifique una Comisión por Transacción, entrará en vigor inmediatamente después de su publicación en las Aplicaciones. 
                        </p>
                        <p className="text-justify lh-sm">
                        El envío de un Criptoactivo a una dirección de red incompatible o incorrecta se considera una Transacción de cadenas cruzadas. La recuperación de transacciones de cadenas cruzadas es un proceso intrínsecamente complicado y lento, y no todos los depósitos se pueden recuperar. En un análisis caso por caso, determinaremos si es factible una recuperación entre cadenas cruzadas y determinaremos, a nuestra discreción, las comisiones aplicables, según se incluyen en la Página de Comisiones. Deberás informar a Ditobanx sobre cualquier Transacción de cadenas cruzadas por medio de los canales de comunicación que se establecen en los presentes Términos.
                        </p>
                        <p className="text-justify lh-sm">
                        En ocasiones, existirán Servicios, que por sus condiciones particulares les serán aplicables términos y condiciones específicas (promociones, descuentos, productos o servicios de temporada, productos personalizados, etc). Dichos términos y condiciones específicas serán comunicados oportunamente al Cliente. Los términos y condiciones específicos prevalecerán sobre los términos y condiciones en el caso de conflicto con respecto a los productos o servicios aplicables.
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Octava- Cumplimiento  </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Conforme al esquema de Prevención del Lavado de Dinero y de Activos y Financiamiento al Terrorismo (“LA/FT”) de Ditobanx que se guía por la legislación aplicable en El Salvador y los criterios internacionales que ha emitido el Grupo de Acción Financiera Internacional sobre el Blanqueo de Capitales: 
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente reconoce que Ditobanx dará estricto cumplimiento a los requerimientos que cualquier autoridad competente le formule, en virtud de los cuales podrán existir requisitos adicionales a los establecidos en los presentes Términos de Uso, los cuales pueden estar relacionados con el nivel de riesgo tanto operativo, legal, reputacional, geográfico o por actividad preponderante, entre otros. 
                        </p>
                        <p className="text-justify lh-sm">
                        Dichos requisitos deberán ser cumplidos a cabalidad a efecto de establecer y/o en su caso continuar con la prestación de los Servicios. El cumplimiento a los requerimientos en materia de datos personales realizados por autoridad competente estará sujeto en todo momento a lo dispuesto por nuestro Aviso de Privacidad y por la legislación vigente. 
                        </p>
                        <p className="text-justify lh-sm">
                        En el caso de que se sospeche que tu Wallet en Ditobanx está incumpliendo con cualquier disposición de LA/FT , nacional o internacional, Ditobanx tendrá el derecho de llevar a cabo cualquier acción de revisión y auditoría que consideremos necesaria o conveniente, tal como solicitar y recabar cualquier información o documentación necesaria o que consideremos conveniente, así como reportar, a nuestra entera discreción, cualquier actividad a cualquier autoridad y, en su caso, tomar cualquier medida o llevar a cabo cualquier acto contemplado implícita o explícitamente en los Términos de Uso o en la legislación aplicable, incluyendo sin limitar la suspensión temporal o total de cualquier Wallet e o del acceso a las Aplicaciones. 
                        </p>
                        <p className="text-justify lh-sm">
                        Asimismo, Ditobanx se reserva el derecho de requerir cualquier información adicional, con la intención de determinar o corroborar el perfil transaccional ya sea declarado u operado de sus Clientes así como el origen y el destino de los fondos involucrados en las operaciones que el Cliente realice en nuestra plataforma o para cualquier otro fin relacionado o conveniente y, de no recibir la información necesaria, Ditobanx podrá suspender o dar por terminados los Servicios y/o la relación contractual con el Cliente, incluyendo su acceso a nuestras Aplicaciones. Los recursos que los Clientes depositan en la Wallet de Ditobanx en todo caso deberán provenir y podrán ser reintegrados en cuentas de depósito abiertas en la entidad financiera autorizada conforme a la normatividad que les resulte aplicable, en caso contrario, previa resolución legal o judicial en ese sentido, el Cliente renuncia a todos los fondos que se encontrasen en Ditobanx conforme a lo que determinen las autoridades competentes. 
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx establecerá interfaces de programación de aplicaciones informáticas estandarizadas que posibiliten la conectividad y acceso de otras interfaces desarrolladas o administradas por la entidad financiera y por terceros especializados en tecnologías de la información, con el fin de compartir diversos datos e información según se prevea en la normatividad aplicable.
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Novena – Seguridad. </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        El buen uso, manejo y custodia de las firmas, llaves privadas, claves de acceso, contraseñas y cualquier otro dato de acceso a tus Wallets son responsabilidad tuya. Ditobanx no se hará responsable de la falta de diligencia, negligencia o impericia a cargo de los Clientes en el control y cuidado de estas, así como de las posibles consecuencias que esto pueda implicar, las cuales incluyen, de manera enunciativa más no limitativa, posibles pérdidas de fondos o de información. 
                        </p>
                        <p className="text-justify lh-sm">
                        Al registrarse en la Plataforma, el Cliente, por su propia seguridad deberá generar una contraseña la cual servirá como factor de autenticación, misma que deberá estar compuesta al menos por ocho caracteres consecutivos, e incluir caracteres alfanuméricos y especiales. Adicionalmente Ditobanx asignará a cada Cliente al momento de apertura de la Wallet un nombre de usuario de identificación y un número de identificación personal el cual fungirá como factor de autenticación para la aprobación de operaciones con Criptoactivos. Dicho número de identificación personal podrá ser modificado por el Cliente en cualquier momento. 
                        </p>
                        <p className="text-justify lh-sm">
                        Es responsabilidad del Cliente activar el método de autenticación de múltiples factores. Para lo cual, Ditobanx cuenta con tecnología que permite la implementación de generadores de contraseñas dinámicas, mediante el uso de datos biométricos, a elección del Cliente. Entre las formas de autenticación de múltiples factores, además de sus credenciales o contraseñas de acceso pueden existir códigos entregados a través del servicio de mensajes cortos o un mecanismo de autenticación de dos factores a través de alguna aplicación generadora de códigos dinámicos. 
                        </p>
                        <p className="text-justify lh-sm">
                        Para efectos del párrafo anterior y en términos de la legislación aplicable, los mensajes de datos que el Cliente emita o comunique de manera posterior a su autenticación se tendrán por válidos para todos los efectos a que haya lugar y como emitidos a través de dispositivos seguros y aprobados por el Cliente con el valor de una firma electrónica simple, así como recibidos íntegramente y sin alteraciones en los servidores de Ditobanx. Por el solo hecho de acceder al sitio de Ditobanx y ordenar o celebrar cualquier Operación, el Cliente se obliga a responder con la totalidad de su patrimonio de las obligaciones que le sean atribuibles o de aquellas en las cuales haya incurrido. 
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx no será en ningún caso responsable por el mal uso de las formas de autenticación que a su discreción utilice el Cliente. Ditobanx no se hace responsable de phishing, suplantación de identidad, ataques cibernéticos o de cualquier tipo de ataque de los que el Cliente sea víctima. Tampoco será responsable por actos de terceros que afecten las Aplicaciones, la red mundial de internet o cualquier otra plataforma de interconexión de forma general. 
                        </p>
                        <p className="text-justify lh-sm">
                        Sólo con el fin de resguardar la seguridad en la cuenta del Cliente cuando Ditobanx tenga algún indicio de que ésta pudiera haber sido vulnerada por un tercero no autorizado, así como cualquier acto que limite, restrinja o impida a cualquier persona en igualdad de condiciones la contratación de algún producto o servicios cumpliendo con los requisitos previstos por la Ditobanx, ésta podrá restringir temporalmente el acceso a sus Aplicaciones a cualquier persona, incluyendo el acceso a su Wallet. Para ello, Ditobanx enviará al Cliente una alerta a través de la Aplicación informando de tal situación para que éste pueda realizar la aclaración correspondiente.
                        </p>
                        <p className="text-justify lh-sm">
                        El proceso de recuperación de número de Cliente o contraseña solamente se realizará mediante los pasos que para ello ha establecido Ditobanx tomando en cuenta los más altos estándares de seguridad. Dicho proceso únicamente podrá ser iniciado por el Cliente y nunca será iniciado por parte de Ditobanx. En ninguna circunstancia Ditobanx le pedirá a sus Clientes, por correo domiciliado, correo electrónico ni por vía telefónica que revelen las credenciales o contraseñas que el Cliente utiliza para acceder a su Wallet. 
                        </p>
                        <p className="text-justify lh-sm">
                        Es responsabilidad del Cliente no compartir ni proporcionar sus datos de identificación, credenciales y llaves de acceso a terceros, así como asegurarse que ingresa sus datos o credenciales en la dirección segura y certificada. El acceso al sitio oficial de Ditobanx es responsabilidad del Cliente, Ditobanx por ningún motivo será responsable de la seguridad de la conexión desde la cual acceda el Cliente. 
                        </p>
                        <p className="text-justify lh-sm">
                        Derivado de lo anterior, es responsabilidad del Cliente notificar a Ditobanx si cree o sospecha que su Wallet o sus credenciales pueden estar comprometidas, o si un tercero está actuando por su cuenta, para lo cual deberá crear un ticket de soporte y deberá seleccionar la opción que más se ajuste a su solicitud. Además, el Cliente se obliga a tomar todas las medidas necesarias o convenientes que le solicite Ditobanx, así como a seguir los procedimientos establecidos por Ditobanx para recuperar el control de su cuenta bajo pena de perder el mismo definitivamente. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Décima – Notificaciones. </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente acepta que Ditobanx tomará como medio de contacto, la dirección de correo electrónico con la que se ha dado de alta al abrir su Cuenta, por lo que ésta será la vía a través de la cual recibirá avisos relacionados con los Servicios y a la Plataforma. Adicionalmente, respecto a la notificación de Operaciones concretadas, Ditobanx podrá informar las mismas a través de las propias Aplicaciones, o bien, en caso de que el Cliente así lo seleccione, a través de correo electrónico. El Cliente acepta como válidas todas aquellas comunicaciones intercambiadas por medios electrónicos, ya sea para la formación de acuerdos de voluntades o intercambio de notificaciones. 
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx también podrá utilizar la dirección de correo electrónico del Cliente para otras comunicaciones, incluidos los avisos con respecto a los Términos de Uso y sus operaciones, abonos o retiros realizados a la Wallet del Cliente, así como cualquier otra comunicación futura entre el Cliente y Ditobanx. El Cliente deberá mantener su dirección de correo electrónico actualizada y notificar a Ditobanx de cualquier cambio a la misma. Ditobanx tendrá por válidas todas las comunicaciones enviadas a la dirección de correo electrónico registrada por parte del Cliente con independencia de su efectiva recepción. Ditobanx pondrá a disposición el registro de movimientos y los estados de Cuenta del Cliente en la sección correspondiente a “historial” dentro del perfil del Cliente, una vez que éste haya iniciado sesión a través de los medios descritos en el presente documento. El Cliente conviene y acepta que será el único medio disponible para consultar los estados de cuenta que se generen producto de la operación de las Aplicaciones. 
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx pone a disposición del Cliente su oficina de atención por medio de los siguientes canales para atender sus comentarios, quejas o reclamos: 
                        </p>
                        <p className="text-justify lh-sm">
                        <ol type="a">
                            <li><strong>Correo Electrónico: </strong>hola@ditobanx.com</li>
                            <li><strong>Canales Virtuales de mensajería vía WhatsApp:</strong> +503 2239-2425.</li>
                        </ol>
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Décima primera – Obligaciones.  </strong>
                        </p>
                        <p className="text-justify lh-sm">
                            Ditobanx deberá: 
                        </p>
                        <p className="text-justify lh-sm">
                            <ol type="a">
                                <li>Mantener funcionando y a disposición del Cliente la plataforma tecnológica y la prestación de servicios conforme a los presentes Términos y Condiciones. </li>
                                <li>Poner a disposición del cliente Medios de Pago y otros canales para efectuar las transacciones.</li>
                                <li>Atender las quejas y reclamos de los Clientes conforme a su política de recepción de reclamos. </li>
                                <li>Tratar los datos personales conforme a su Política de Privacidad. </li>
                                <li>Los otros que establezcan específicamente los presente Términos y Condiciones. </li>
                            </ol>
                        </p>
                        <p className="text-justify lh-sm">
                        Por su parte el Cliente deberá: 
                        </p>
                        <p className="text-justify lh-sm">
                            <ol type="a">
                                <li>Proporcionar información veraz, completa y actualizarla cuando la misma ya no correspondan a su realidad actual. </li>
                                <li>Realizar las transacciones utilizando únicamente aquellos medios de pago sobre los cuales el Cliente es titular. </li>
                                <li>Reconocer las transacciones como propias, salvo que judicialmente se haya comprobado una suplantación de identidad conforme a la Ley de Delitos Informáticos u otra legislación que posteriormente regule dicho acto. </li>
                                <li>No realizar procedimientos informáticos, Con la intención de dañar, modificar, interrumpir, o desactivar la plataforma de Ditobanx, o los procesos de funcionamiento de los sistemas, utilizando mecanismos como virus, bombas informáticas, troyanos, etcétera.</li>
                                <li>Contar con los servicios de internet que le permitan acceder a la plataforma. </li>
                                <li>Crear por los medios que Ditobanx ponga a su disposición su usuario y contraseña por medio de la cual se identificará con el proveedor para la realización de transacciones, resguardarlos y nunca hacerlos del conocimiento de terceros. </li>
                                <li>Respetar los derechos de autor, logos, marcas y otros signos distintivos de Ditobanx.</li>
                                <li>Los otros que establezcan específicamente los presente Términos y Condiciones. </li>
                            </ol>
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Décima Segunda – Límite de responsabilidad.   </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Los Criptoactivos no están protegidos por ningún tipo de seguro, protección o garantía de depósito. 
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente reconoce que todas sus decisiones se realizan única y exclusivamente a nombre y cuenta propia y sin injerencia ni asesoría de ningún tercero. En consecuencia, cada Cliente está obligado a llevar a cabo una evaluación independiente respecto de los Criptoactivos, las operaciones que puede llevar a cabo en la plataforma de Ditobanx, los Servicios otorgados por ésta y cualquier otra circunstancia relacionada con la misma. Ditobanx no será responsable en ningún caso frente al Cliente por cualquier tipo de decisiones tomadas por éste, independientemente de la actualización de un daño o perjuicio con motivo de dicha decisión. Cualquier operación que el Cliente realice en la Plataforma es responsabilidad exclusiva del mismo. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Décima Tercera – Confidencialidad.  </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Los datos del Cliente serán tratados conforme a la Política de Privacidad proporcionada en la plataforma. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Décima Cuarta – Abonos a Wallets de Clientes.  </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Los abonos desde cuentas en entidades financieras a nombre de terceros distintos al Cliente titular de la Cuenta en Diotbanx están prohibidos. Las transferencias siempre deberán provenir de una cuenta a nombre del Cliente aperturada en la entidad financiera, de lo contrario serán devueltos a la cuenta originadora salvo que el Cliente compruebe de forma fehaciente la razón de recibir fondos de algún tercero y en cuyo caso Ditobanx deberá identificar plenamente al tercero originador de un fondeo con la información que Ditobanx juzgue conveniente pero respetando la misma condición de que los fondos se realicen a través de una entidad financiera nacional o extranjera. Ditobanx se reserva el derecho de devolver cualquier transferencia efectuada por cualquier tercero en contravención a lo previsto en los Términos de Uso y a los requisitos que Ditobanx determine de tiempo en tiempo, así como de iniciar cualquier acción relacionada conforme a la legislación aplicable. El Cliente se compromete a notificar inmediatamente de cualquier uso no autorizado de su contraseña o identificación de Cliente, así como de cualquier otra violación de seguridad. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Décima Quinta – Cierre de Wallets.  </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente tiene derecho a cerrar en cualquier momento y sin condición alguna su Wallet, para lo cual deberá seguir el proceso de desvinculación. Con motivo del cierre de una Wallet, se cancelarán todas las solicitudes de Servicios o Servicios pendientes; deberán cumplirse todas las operaciones que se hubiere concertado y que no pudieren cancelarse; y el Cliente deberá retirar todos sus fondos de Ditobanx según se establece en el presente numeral. 
                        </p>
                        <p className="text-justify lh-sm">
                        Asimismo, el Cliente deberá completar un protocolo de seguridad para la protección de la Wallet del Cliente, según lo requiera de tiempo en tiempo Ditobanx. Ditobanx pondrá a disposición del Cliente el monto que mantenga para que mediante la transferencia de fondos a la entidad financiera que el Cliente determine pueda retirar la totalidad del saldo que mantenga en la Cuenta de Criptoactivos o por medio de los canales disponibles. 
                        </p>
                        <p className="text-justify lh-sm">
                        Cuando Ditobanx reciba alguna solicitud de cierre anteriormente referida proporcionará al Cliente por medios digitales, un número de referencia de dicha solicitud, la fecha y hora en que esta se recibió. Asimismo, entregará a sus Clientes la información referida a más tardar al cierre del día hábil en el que hayan presentado la solicitud de alguna de las formas pactadas al efecto. 
                        </p>
                        <p className="text-justify lh-sm">
                        El cierre al que se refiere esta cláusula surtirá efectos al cierre del día hábil en que Ditobanx reciba de su Cliente la solicitud correspondiente. Una vez que surta efectos el cierre referido, Ditobanx se abstendrá de realizar cargos en la Wallet del Cliente de que se trate y pondrá a disposición del Cliente la totalidad del monto que mantenga en la citada Wallet.
                        </p>
                        <p className="text-justify lh-sm">
                        Derivado de la cancelación y en caso de que existiera saldo a favor en tu Wallet en forma de Criptoactivos, estos serán abonados a tu cuenta de conformidad con lo previsto por los presentes Términos y Condiciones. 
                        </p>
                        <p className="text-justify lh-sm">
                        De contar con un medio de disposición vinculado a la Wallet y como consecuencia de tu solicitud de terminación y cierre de tu Wallet, deberás manifestar bajo protesta de decir verdad que cualquier medio de disposición ligado a tu cuenta fue destruido o que no cuentas con ellos. 
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx se reserva el derecho de suspender, cancelar o modificar cualquier aspecto de la Wallet o su disponibilidad, en cualquier momento sin previo aviso, sin tener responsabilidad alguna. Además, Ditobanx se reserva el derecho de suspender, modificar, desactivar o cancelar la Wallet de los Clientes, así como su acceso a todos o parte de los servicios de Ditobanx de inmediato y sin previo aviso en los casos en que: 
                        </p>
                        <p className="text-justify lh-sm">
                            <ul>
                                <li>El Cliente viole cualquier disposición contractual o de los Términos de Uso; </li>
                                <li>El Cliente realice el fondeo de su Wallet desde otra cuenta sobre la cual no tiene la propiedad, real o aparente; </li>
                                <li>Se emita una orden judicial para el congelamiento de fondos; </li>
                                <li>El Cliente o su Wallet están sujeto a algún litigio pendiente, investigación o procedimiento gubernamental; </li>
                                <li>Se tenga una sospecha razonable de infracción por parte del Cliente a nuestra programa de LA/FT o ha incumplido con cualesquiera de las disposiciones aplicables; </li>
                                <li>El Cliente no nos proporcione información verídica, actualizada y correcta; </li>
                                <li>El Cliente fondee su Wallet mediante el uso de técnicas o procesos fraudulentos o de manera ilegal; </li>
                                <li>Si se hace de nuestro conocimiento que sus fondos son producto de una actividad ilícita o delictiva; </li>
                                <li>Si, en la opinión razonable de Ditobanx, la Wallet del Cliente interactúa con servicios de inversión fraudulentos o de rendimientos insostenibles; </li>
                                <li>Por cualquier otra razón por la cual Ditobanx considere que la Wallet del Cliente o las operaciones que ha realizado representan un riesgo para las operaciones de Ditobanx, y; </li>
                                <li>Por cualquier otra causa, a discreción de Ditobanx. </li>
                            </ul>
                        </p>
                        <p className="text-justify lh-sm">
                        En aquellos casos en que, por cualquiera de las razones arriba mencionadas, Ditobanx proceda al cierre de Wallets, Ditobanx hará esfuerzos razonables, dentro de su control, para notificarle dicha situación al Cliente y en caso de que resulte aplicable iniciar con el proceso de devolución de fondos remanentes en su Wallet directamente a la cuenta en la Entidad Financiera o por medio de los canales específicos.
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Décima Sexta – Fondos sin reclamar.  </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        En el caso de que tu Wallet sea cerrada por cualquier razón, cualquier fondo permanecerá en tu Cuenta como propiedad no reclamada, hasta en tanto no se reclamen los fondos. Si esto ocurre, Ditobanx hará esfuerzos razonables, dentro de su propio control, para notificarte la situación en la dirección de correo electrónico registrada. 
                        </p>
                        <p className="text-justify lh-sm">
                        Las Wallets que en el transcurso de tres años no hayan tenido movimiento por abonos, redención, transmisión o consulta serán enviados a fondos ajenos en custodia ante el Ministerio de Hacienda. Ditobanx dará aviso por escrito de esta situación al Cliente en la cuenta de correo electrónico registrada con noventa días de anticipación. En relación con lo anterior, no se considerarán movimientos aquellos relacionados con el cobro de comisiones. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Décima Séptima – Errores del Sistema.  </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Es posible que, debido a factores tecnológicos, mantenimientos programados u otros factores, fuera o dentro de nuestro control, la plataforma de Ditobanx u otros Servicios sean interrumpidos temporalmente. Si bien haremos lo posible para solventar en su caso dichos factores, el Cliente acepta que no somos responsables por los daños y perjuicios derivados de dicha interrupción y renuncia a cualquier derecho o acción que pudiere tener en contra de Ditobanx, por motivos de errores en el sistema. 
                        </p>
                        <p className="text-justify lh-sm">
                        En caso de que suceda un evento como el descrito en este párrafo, Ditobanx publicará un comunicado indicando los pasos a seguir. 
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx se reserva el derecho de revertir cualquier operación que haya sido efectuada como consecuencia de una interrupción en el servicio o falla en el funcionamiento de las comunicaciones electrónicas, instalaciones comerciales, instalaciones de almacenamiento, mecanismos de grabación u otros componentes de los Servicios. En estas circunstancias las operaciones se revisarán caso por caso, y Ditobanx te informará mediante la dirección de correo electrónico proporcionada por el Cliente, si alguna de tus operaciones está en esta situación. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Decima Octava – Validez Parcial. </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        En caso de que una autoridad competente declaré ilegal, nula o inexigible los presentes términos y condiciones, ya sea en su totalidad o en parte, de conformidad con la legislación aplicable, el resto de las disposiciones de los términos y condiciones se considerará aplicable y vigente. Los presentes términos y condiciones constituyen el contrato íntegro y el entendimiento entre las partes en relación con los servicios.
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Decima Novena –Propiedad Industrial e Intelectual. </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Las Aplicaciones y los Servicios son propiedad de Ditobanx y/o afiliadas o Ditobanx es pertenecientes al mismo grupo empresarial. Asimismo, Ditobanx es propietaria de los Servicios que ofrece. Nos reservamos todos los derechos sobre la misma y cualquier tecnología asociada a la misma. Cualquier creación, idea, invención, mejora, know-how, concepto, algoritmo, protocolo, datos, procesos, procedimientos, métodos, técnicas, protocolos, fórmulas, sistemas, herramientas, composiciones, códigos, software, prototipos, documentos, componentes o cualquier otra información que sea de la autoría de Ditobanx o de persona alguna, que derive exclusivamente como consecuencia del uso de las Aplicaciones de Ditobanx, sea o no patentable o susceptible de estar bajo la protección de derechos de autor, o cualquier tipo de derechos de propiedad intelectual o industrial, son propiedad de Ditobanx y/o afiliada, teniendo Ditobanx el derecho de iniciar cualquier acción, o tomar cualquier medida, de cualquier naturaleza, para obtener su registro y la respectiva protección ante cualquier autoridad (incluyendo cualquier Autoridad en materia de propiedad intelectual). 
                        </p>
                        <p className="text-justify lh-sm">
                        Del mismo modo, cualquier obra derivada, tales como, de manera descriptiva más no limitativa, una traducción, modificación, mejora, personalización, adaptación, compilación o cualquier tipo de producto que use como origen cualquier elemento de nuestra plataforma será propiedad de Ditobanx y/o afiliada, por lo que no concedemos ningún tipo de licencia, ni permiso para la reproducción de alguna obra derivada o permiso o licencia para su uso por cualquier tercero. En su caso, cualquier producción derivada será propiedad única y exclusiva de Ditobanx. 
                        </p>
                        <p className="text-justify lh-sm">
                        Todos los materiales y datos del sitio y cualquier otro sitio web propiedad, operado, licenciado o controlado por nosotros tendrá el carácter de información privada y será propiedad intelectual de Ditobanx y/o afiliada, por lo que nos reservamos todos los derechos sobre los mismos. Todos los materiales incluidos en el sitio, incluyendo sin limitar compilaciones de datos y software son propiedad de Ditobanx y/o afiliada y están protegidos por las leyes de derechos de autor. 
                        </p>
                        <p className="text-justify lh-sm">
                        Se prohíbe el uso, creación, imitación de logotipos de cualquier marca dentro del Sitio Web y la App. También nos reservamos el derecho de remover cualquier comentario o adjunto que se haga a el Sitio Web y la App por un Usuario y/o tercero no autorizado. El uso de Links, Deeplinking y Framing es prohibido en el Sitio Web y la App. La inobservancia de estas prohibiciones será una violación a los derechos de propiedad intelectual sobre los Contenidos y a los derechos sobre la Propiedad Industrial.
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Vigésima – Legislación Aplicable.  </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Cualquier disputa, conflicto, reclamación o controversia, del tipo que sea, que resulte del uso de la plataforma o de los presentes términos y condiciones o que se relacione en gran parte con ellas, incluyendo las relativas a su validez, interpretación y exigibilidad, deberá someterse a los tribunales salvadoreños. 
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Vigésima Primera – Contacto del Supervisor.   </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Cualquier duda o consulta no resuelta podrá ser sometida a la SFF a través de los medios contenidos en el siguiente enlace: <a class='text-primary' href='https://ssf.gob.sv/atencion-al-publico/' rel='nofollow nopenner'> https://ssf.gob.sv/atencion-al-publico/</a>  en donde encontraras los datos actualizados de la oficina de atención al usuario de la SSF. Whatsapp:7840-9741 o 7593-9103; correo electrónico: <a class='text-primary' href='mailto:atenciónalusuario@ssf.gob.sv' rel='nofollow nopenner'>atenciónalusuario@ssf.gob.sv </a>; y teléfono:2999-9999.
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente tendrá en todo momento acceso a los presentes Términos de Uso, y los mismos estarán a su disposición después de su firma en la sección correspondiente a su perfil dentro de la Aplicación.
                        </p>
                        <p className="text-justify lh-sm">
                        <strong>Vigésima Segunda – Medios de disposición.  </strong>
                        </p>
                        <p className="text-justify lh-sm">
                        Ditobanx podrá emitir, comunicar y poner a disposición de los Clientes que así lo soliciten uno o varios de los Medios de Disposición para hacer uso/retirar  sus Cripotactivos depositados en su Wallet. Ditobanx cuenta con las autorizaciones necesarias para emitir Tarjetas Prepagos para el uso de Criptoactivos; dichas Tarjetas deberá ser solicitada por el Cliente por medio de las Aplicaciones. Toda Tarjeta será entregada al cliente desactivada y para su activación el Cliente deberá solicitarlo expresamente a través de la Plataforma y cumplir con los requisitos correspondientes.
                        </p>
                        <p className="text-justify lh-sm">
                        La Tarjeta que Ditobanx entregue al Cliente se encontrará sujeta a lo dispuesto en la misma, de modo que su utilización a través de cualquier medio se considerará como plena aceptación de los términos que ahí́ se establecen y del riesgo que su uso conlleva.
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente podrá́ adquirir bienes y servicios mediante el uso de la Tarjeta hasta por el límite de sus Criptoactivos en moneda estable asignados a la tarjeta y que serán mostrados como saldo disponible en su Aplicación, mediante la presentación de la Tarjeta correspondiente y, en su caso, la suscripción en cada operación de unas notas de venta, fichas de compra o cualquier otro documento similar. Así́ mismo el Cliente también podrá́ realizar las adquisiciones antes referidas mediante la presentación de la Tarjeta correspondiente a través de consumos que por vía telefónica o por alguna vía electrónica con establecimientos que ofrezcan este tipo de servicios, siempre y cuando dicho sistema de adquisiciones se encuentre disponible en ese momento. Las Tarjetas emitidas podrán ser físicas y/o virtuales, según el Cliente lo requiera.
                        </p>
                        <p className="text-justify lh-sm">
                        Los límites transaccionales de la tarjeta estarán supeditados al perfil transacciónal de cada cliente de cara a los presentes Términos y Condiciones. En caso desees modificar dichos límites, será necesario que lo solicites a servicio al cliente y podria ser requerida informacion adicional a fin de sustentar dicha solicitud. El Cliente asimismo, se compromete a no utilizar la Tarjeta para ningún fin contrario a la ley, conforme a los Términos y Condiciones.
                        </p>
                        <p className="text-justify lh-sm">
                        Las adquisiciones de bienes y/o servicios o de disposiciones de Criptoactivos que se realicen con la Tarjeta serán cargadas a la Wallet del Cliente o retenidas de las mismas provisionalmente el mismo día en que la operación se realice. Las compras, adquisiciones de bienes y/o servicios o disposiciones que se realicen con la Tarjeta, con independencia del Criptoactivo, se cargarán a los fondos en Moneda Estable el mismo día en que se efectúe la operación o retenidas provisionalmente. El tipo de cambio que se utilizará para calcular su equivalencia en Dólares será́ el que se encuentre vigente y que, para tales efectos comunique el proveedor de la Moneda Estable conforme a los reglamentos aplicables del CNAD en el momento en que se efectúe la operación correspondiente o el cargo respectivo o el tipo de cambio que el Titular de Marca o cualquier otra empresa operadora o coordinadora de pagos a la cual se encuentre afiliada la Tarjeta.
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente podrá hacer uso de la Tarjeta con la firma o la digitación de su Número de Identificación Personal que en forma confidencial asignará el mismo a través de su aplicación y será considerado su firma electrónica en los términos de la Ley de Firma Electrónica. El Cliente reconoce sin reservas como propias todas las transacciones realizadas con la Tarjeta.
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente reconoce y acepta, para todos los efectos legales a que haya lugar, el carácter intransferible de la Tarjeta, así́ como la confidencialidad de su Número de Identificación Personal, por lo que el Cliente será́ el único responsable del uso que se le dé a los mismos, liberando de toda responsabilidad a Diotbanx, empleados, directivos, afiliadas y/o subsidiarias de cualquier mal uso que se haga de estos.
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente podrá solicitar a Ditobanx, en cualquier momento y a través de las Aplicaciones, que (i) Ditobanx bloquee el procesamiento de pagos con la Tarjeta, referidos a determinados medios de acceso involucrados en dicho procesamiento, y (ii) se establezcan límites máximos respecto al monto por el que podrán realizarse operaciones con la Tarjeta o bien. Los bloqueos o límites a que se refiere este párrafo se entenderán por tiempo indefinido, salvo que medie instrucción expresa del Cliente que indique lo contrario.
                        </p>
                        <p className="text-justify lh-sm">
                        Cuando el Usuario extravíe su Tarjeta o ésta le sea robada, éste deberá notificar de manera inmediata dicha situación a Ditobanx, y deberá realizarlo a través de los medios de atención al Cliente que los presentes Términos y Condiciones establecen. El equipo de soporte de Ditobanx atenderá la solicitud, bloqueando la tarjeta del Cliente, y pudiendo el Cliente reportar cualquier compra no reconocida. En el momento en que el Cliente notifique el extravío o robo, o dentro de las veinticuatro horas posteriores a que se haya presentado dicho aviso, Ditobanx proporcionará un número de referencia por cada reporte que haga el Cliente; el cual contendrá la fecha y hora en que el reporte fue dado, mismo que el Cliente deberá conservar.
                        </p>
                        <p className="text-justify lh-sm">
                        Una vez que el Cliente haya recibido el número de referencia correspondiente a cualquiera de los avisos señalados en el párrafo anterior; cesará su responsabilidad.
                        </p>
                        <p className="text-justify lh-sm">
                        Posterior a la notificación del número de referencia, Ditobanx inhabilitará la Tarjeta del Cliente. Ditobanx no será responsable respecto de dicha inhabilitación, por lo que el Cliente entiende, autoriza e instruye a Ditobanx para que pueda bloquear temporalmente o cancelar el uso de la Tarjeta extraviada o robada, eximiendo a Ditobanx de cualquier responsabilidad a su cargo. En caso de cualquier cargo no reconocido, usted deberá proporcionar la información necesaria a solicitud de Ditobanx, dentro de los 90 días posteriores al reclamo, para determinar que dicho cargo efectivamente no ha sido realizado por usted. 
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente no será responsable de los cargos realizados a su Wallet que se hayan efectuado con motivo del uso de la Tarjeta a partir de la presentación del aviso a que hace referencia la presente Cláusula. Sin embargo, Ditobanx estará facultada a exigir el pago de los cargos de la Wallet del Cliente que hayan sido autorizados de manera previa al aviso.
                        </p>
                        <p className="text-justify lh-sm">
                        El Cliente podrá solicitar en cualquier momento a Ditobanx la cancelación de la Tarjeta. Ditobanx procederá con dicha cancelación en la fecha que reciba dicha solicitud y, por su parte, el Cliente deberá manifestar bajo protesta de decir verdad, que la Tarjeta fue destruida o que ya no cuenta con ella, por lo que no podrá hacer disposición alguna con dicho Medio de Disposición a partir de la fecha de su solicitud de cancelación.
                        </p>
                        
                        <div className='py-5'></div>

                    </div>
                </div>
            </div>
        </div>
    );
};
export default WalletTerminosCondiciones;