import React, { useState, useEffect } from 'react'
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
// import data from './Clientes.json'

//import css
import style from '../../Paginas/Inicio/Inicio.module.css';
import img1 from '../../assets/img/home/1.png'; 
import img2 from '../../assets/img/home/2.png'; 
import img3 from '../../assets/img/home/3.png'; 
import img4 from '../../assets/img/home/4.png'; 
import img5 from '../../assets/img/home/5.png'; 
import img6 from '../../assets/img/home/6.png'; 
import img7 from '../../assets/img/home/7.png'; 
import img8 from '../../assets/img/home/8.png'; 
import img9 from '../../assets/img/home/9.png'; 
import img10 from '../../assets/img/home/10.png'; 
import img11 from '../../assets/img/home/11.png'; 
import img12 from '../../assets/img/home/12.png'; 
import img13 from '../../assets/img/home/13.png'; 
import img14 from '../../assets/img/home/14.png'; 
import img15 from '../../assets/img/home/15.png'; 
import img16 from '../../assets/img/home/16.png'; 
import img17 from '../../assets/img/home/17.png'; 
import img18 from '../../assets/img/home/18.png'; 
import img19 from '../../assets/img/home/19.png'; 
import img20 from '../../assets/img/home/20.png'; 
import img21 from '../../assets/img/home/21.png'; 
import img22 from '../../assets/img/home/22.png'; 
import img23 from '../../assets/img/home/23.png'; 
import img24 from '../../assets/img/home/24.png'; 
import img25 from '../../assets/img/home/25.png'; 
import img26 from '../../assets/img/home/26.png'; 
import img27 from '../../assets/img/home/27.png'; 
import img28 from '../../assets/img/home/28.png'; 
import img29 from '../../assets/img/home/29.png'; 
import img30 from '../../assets/img/home/30.png'; 
import img31 from '../../assets/img/home/31.png'; 
import img32 from '../../assets/img/home/32.png'; 
import img33 from '../../assets/img/home/33.png'; 
import img34 from '../../assets/img/home/34.png'; 
import img35 from '../../assets/img/home/35.png'; 
import img36 from '../../assets/img/home/36.png'; 
import img37 from '../../assets/img/home/37.png'; 
import img38 from '../../assets/img/home/38.png'; 
import img39 from '../../assets/img/home/39.png'; 
import img40 from '../../assets/img/home/40.png'; 
import img41 from '../../assets/img/home/41.png'; 
import img42 from '../../assets/img/home/42.png'; 
import img43 from '../../assets/img/home/43.png'; 
import img44 from '../../assets/img/home/44.png'; 
import img45 from '../../assets/img/home/45.png'; 
import img46 from '../../assets/img/home/46.png'; 
import img47 from '../../assets/img/home/47.png'; 
import img48 from '../../assets/img/home/48.png'; 
import img49 from '../../assets/img/home/49.png'; 
import img50 from '../../assets/img/home/50.png'; 
import img51 from '../../assets/img/home/51.png'; 
import img52 from '../../assets/img/home/52.png'; 
import img53 from '../../assets/img/home/53.png'; 
import img54 from '../../assets/img/home/54.png'; 
import img55 from '../../assets/img/home/55.png'; 
import img56 from '../../assets/img/home/56.png'; 
import img57 from '../../assets/img/home/57.png'; 
import img58 from '../../assets/img/home/58.png'; 
import img59 from '../../assets/img/home/59.png'; 
import img60 from '../../assets/img/home/60.png'; 
import img61 from '../../assets/img/home/61.png'; 
import img62 from '../../assets/img/home/62.png'; 
import img63 from '../../assets/img/home/63.png'; 
import img64 from '../../assets/img/home/64.png'; 
import img65 from '../../assets/img/home/65.png'; 
import img66 from '../../assets/img/home/66.png'; 



export default function SlideClientes({
    imagens=[img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19,img20,img21,img22,img23,img24,img25,img26,img27,img28,img29,img30,img31,img32,img33,img34,img35,img36,img37,img38,img39,img40,img41,img42,img43,img44,img45,img46,img47,img48,img49,img50,img51,img52,img53,img54,img55,img56,img57,img58,img59,img60,img61,img62,img63,img64,img65,img66],
     numberOfSlides=imagens.length
    }) {
    // const imagens = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14]
    // const String = JSON.stringify(DataClientes);
    // const Parsed = JSON.parse(String)
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        // height: window.innerHeight,
    });
    console.log(dimensions);
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            //   height: window.innerHeight,
        });
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    const [sliderRef] = useKeenSlider(
        {
            loop: true,
            slides:
            {
                perView: 6,
                number: numberOfSlides,
                spacing: 10,
            },
            breakpoints: {
                '(max-width: 349px)': {
                    loop: true,
                    slides:
                    {
                        number: numberOfSlides,
                        perView: 2,
                        spacing: 10,
                        origin: 'auto',

                    },
                },
                '(min-width: 350px) and (max-width: 599px)': {
                    loop: true,
                    slides:
                    {
                        number: numberOfSlides,
                        perView: 3,
                        spacing: 10,
                        origin: 'auto',

                    },
                },
                '(min-width: 600px) and (max-width: 899px)': {
                    loop: true,
                    slides:
                    {
                        number: numberOfSlides,
                        perView: 3,
                        spacing: 5,
                        origin: 'auto',

                    },
                },
                '(min-width: 900px) and (max-width: 1200px)': {
                    loop: true,
                    slides:
                    {
                        number: numberOfSlides,
                        perView: 4,
                        spacing: 5,
                        origin: 'auto',

                    },
                },
            },
        },
        [
            (slider) => {
                let timeout
                let mouseOver = false
                function clearNextTimeout() {
                    clearTimeout(timeout)
                }
                function nextTimeout() {
                    clearTimeout(timeout)
                    if (mouseOver) return
                    timeout = setTimeout(() => {
                        slider.next()
                    }, 1000)
                }
                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true
                        clearNextTimeout()
                    })
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false
                        nextTimeout()
                    })
                    nextTimeout()
                })
                slider.on("dragStarted", clearNextTimeout)
                slider.on("animationEnded", nextTimeout)
                slider.on("updated", nextTimeout)
            },
        ]
    )
    return (
            <div ref={sliderRef} className="keen-slider">
                {imagens.map((post) => {
                    return (
                        <div className="keen-slider__slide  custom-box-client" key={post}>
                            <img width="160px" className={style.bitcoinimage} src={post} alt={post} />
                        </div>
                    )
                })}
            </div>
    );
}