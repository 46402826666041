import React, { Component } from 'react';
import './neutronpayComunicado.css';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';

/*Import custom Components */
import MailChimp from '../../../../../Componentes/MailChimp/MailChimp';
import SocialShare from '../../../../../Componentes/SocialShare/SocialShare';

/*import assets */
import IconArrow from '../../../../../assets/img/icon-row-right.svg';
// import IconFB from '../../../../../assets/img/ico-social/ico-fb.svg';
// import IconIn from '../../../../../assets/img/ico-social/ico-in.svg';
// import IconTwt from '../../../../../assets/img/ico-social/ico-twt.svg';
// import IconInsta from '../../../../../assets/img/ico-social/ico-insta.svg';

/*Import Images Feed */
import FeaturedImage from '../../../../../assets/img/img-comunicados/ditobanx-neutronpayrs.png';
import IconBuzon from '../../../../../assets/img/icon-buzon.svg';
import Profile from '../../../../../assets/img/img-comunicados/ditobanx-neutronpayrs.png';


export default class NeutronpayComunicado extends Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
                <div className='mt-5 pt-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-8 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <a href={'/noticias-prensa'} className='text-decoration-none'>
                                            <img  src={IconArrow} alt='' width={22} className='mb-2  '  />
                                            <span className='px-2 h1-custom'>Comunicados de prensa</span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="Neutronpay expands to Canada and partners Ditobanx"
                                            LinkedInTitle="Neutronpay expands to Canada and partners Ditobanx"
                                            Size="32px"
                                            className="d-none"

                                        />
                                    </div>
                                </div>
                                <Col className='py-3 col-12 px-2'>
                                    <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                    <span className='mx-2 color-custom-date'>MAYO 16, 2023</span>
                                </Col>
                                <Col className="mb-3 mx-2 w-100" style={{ padding: 0 }}>
  <img src={FeaturedImage} className="custom-featured-image rounded-3" alt='neutronpay' style={{ width: '600px', height: 'auto' }} />
  <div className='color-custom-date py-1 px-3 border-bottom'>Imagen creada por Team Ditobanx</div>
</Col>
                                <Col>
                                    <h1 className='fw-bold lh-1 pb-2 px-2'>Neutronpay expands to Canada and partners Ditobanx
                                    </h1>
                                    <span className='fw-bold px-2'>Por DitoBanx</span>
                                </Col>
                                <Col className="py-3 px-2">
                                    {/* <img src={Profile} style={{ width: '18.2rem' }} className="d-block pe-3 text-center mx-auto float-center float-md-start rounded-3 pb-3 pb-md-0" alt='itoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' /> */}
                                    <p className='lh-sm text-justify'>
                                    Neutronpay is a payment platform that uses Lightning Network infrastructure for real-time, low-cost transactions.<strong>  </strong>, and DitoBanx, 
                                    a <strong>Fintech for the unbanked in Latin America</strong>, today announced a partnership to offer secure and regulated digital asset custody services to banking institutions and fintech companies in the region.
                                    <br/><br/>

                                    The partnership between Neutronpay and Ditobanx aims to help Salvadorans in Canada to seamlessly send and receive money over the Lightning Network to Ditobanx. This could provide a more efficient and cost-effective way for Salvadorans in Canada to make money transfers to El Salvador, which could benefit both Ditobanx and its customers.
                                    <strong>This technology provides a secure and regulatory-compliant environment for customers to store, transfer, and manage their digital assets, including Bitcoin</strong>.
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>
                                     "Lightning-Powered Neutronpay App Will Allow Canadians To Send And Receive Money To And From Ditobanx Instantly And Transparently," said Albert Buu, <strong>CEO of Neutronpay</strong>The Lightning Network (LN) is a “layer 2” payment protocol that is built on top of Bitcoin (and other blockchains and cryptocurrencies). It is designed to enable fast transactions between participating nodes and has been proposed as a solution to the scalability problem of Bitcoin. The network allows for almost instantaneous micropayments .
                                     </span>
                                     <br/><br/>
                                     
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>
                                     "The partnership with Neutronpay is an exciting step for DitoBanx as we improve the life quality of Salvadorans,"  </span>
                                     
                                     said <strong>Guillermo Contreras, CEO</strong> of DitoBanx. 
                                     <span  style={{fontStyle: 'italic'}}>
                                     "The ability to offer institutional-grade digital asset custody services will be a game-changer for our customers, enabling them to securely store and manage their digital assets in a regulated environment."
                                     </span>
                                     <br/><br/>
                                     
                                     <br/><br/>



                                   
                                    </p>
                                </Col>
                                
                                <Col>
                                    <h3 className='fs-5'><strong>About Neutronpay: </strong> </h3>
                                    <p className='lh-sm text-justify'>
                                    Neutronpay is a payment platform that uses Lightning Network infrastructure for real-time, low-cost transactions. The company's decentralized multi-party computing (dMPC) technology provides a secure and regulated environment for customers to store, transfer, and manage their digital assets.
                                        {/* Bolt Observer es una empresa tecnológica fundada en 2022 que se dedica a resolver la complejidad asociada a la gestión de nodos Lightning Network y liquidez, permitiendo a las empresas mejorar su experiencia y al ecosistema entero a crecer. 
                                        Sitio web: <a style={{color:'#f7931a'}} href={'https://bolt.observer/'} target="_blank" >https://bolt.observer </a>para más información puede escribir a info@bolt.observer.  */}

                                    </p>
                                </Col>

                                <Col>
                                    <h3 className='fs-5'><strong>About DitoBanx:</strong> </h3>
                                    <p className='lh-sm text-justify'>
                                    DitoBanx is a fintech for the unbanked in Latin America. The company provides a range of financial services to its customers, including remittances, bill payments, and microfinance loans, and is committed to empowering those who have been excluded from traditional financial services.
                                    </p>
                                </Col>
                                <br/>
                                <Col>
                                   
                                    <p className='lh-sm text-justify'>
                                    For more information about Neutronpay Networks and DitoBanx, please visit their respective websites.
                                    
                                    
                                    </p>
                                </Col>
                                <br/>
                                <Col>
                                    Contact:
                                    <span> Albert Buu, CEO</span><br/>
                                    <span> Neutronpay Networks</span><br/>
                                    <span>  <a style={{color:'#f7931a'}} href={'https://neutronpay.com/'} target="_blank" >https://neutronpay.com/ </a> a*@neutronpay.com</span><br/><br/>
                                    <span> Guillermo Contreras, CEO</span><br/>
                                    <span> DitoBanx</span><br/>
                                    <span>  <a style={{color:'#f7931a'}} href={'https://www.ditobanx.com'} target="_blank" >www.ditobanx.com </a> hola@ditobanx.com</span><br/>
                                    
                                </Col>
                                <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                    <Row>
                                        <Col>
                                            <Card.Body className='pt-0'>
                                                <Card.Title className="mb-0 text-center text-md-start">Equipo DitoBanx</Card.Title>
                                                <span className='d-block text-center text-md-start fw-bold'>Noticias</span>
                                                <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                    Presentadora
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                        <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                            <div className="col text-center tex-md-end">
                                                <SocialShare
                                                    PostUrl={urlLocation}
                                                    TwitterTitle="News"
                                                    LinkedInTitle="Neutronpay expands to Canada and partners Ditobanx"
                                                    Size="32px"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='col-12 col-md-3 px-0 px-md-0 ms-0 ms-md-4'>
                                <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0 pt-5'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
