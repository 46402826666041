import React from 'react';
import { Helmet } from 'react-helmet';
// Fecha: 8/2/2022
function TerminosCondiciones() {
    return (
        <div>
            <Helmet>
                <title>Términos | Dito Pay</title>
            </Helmet>
            <br /><br />
            <div className="container-fluid bg-light mt-5">
                <div className="row">
                    <div className="col-lg-9 p-5 offset-lg-2 mt-4 mb-4 text-center bg-white shadow-sm p-3 mb-5 bg-body rounded-3 ">
                        <h1 className="fs-2"> Terminos y Condiciones</h1>
                        {/* <span>Version 2.0</span> */}
                        <p className="text-center lh-sm">
                           DitoBanx
                        </p>
                        <br />
                        
                        <p className="text-justify lh-sm">
                            <strong>NOSOTROS, DITOBANX EL SALVADOR, SOCIEDAD ANÓNIMA DE CAPITAL VARIABLE, </strong>, que se abrevia 
                            <strong>“DITOBANX EL SALVADOR, S.A. DE C.V.”,  </strong>
                            de nacionalidad Salvadoreña, del domicilio de la ciudad y departamento de San Salvador, con Número de Identificación Tributaria cero seis uno cuatro - dos cero cero cinco dos dos - uno uno tres - ocho, la cual en el transcurso de este instrumento se denominará de forma indistinta como
                            <strong>"DitoBanx"</strong> o <strong>“EL PROVEEDOR”</strong>, y por otra parte <strong>EL CLIENTE</strong> el cual en el transcurso de este instrumento se denominará de forma indistinta como <strong>“EL CLIENTE”</strong>, quienes en conjunto nos podremos denominar como “Las Partes”, y en las calidades en las que actuamos, OTORGAMOS: Que hemos convenido en formalizar un <strong>CONTRATO NO EXCLUSIVO PARA LA PROVISIÓN DE SERVICIOS DE PROCESAMIENTO DE PAGOS EN CRIPTOMONEDAS</strong>, en adelante denominado como el “Contrato” o el “Acuerdo”, que se regirá por las siguientes cláusulas:
                        </p>
                        <p className="text-justify lh-sm">
                        DITOBANX ofrece su plataforma de procesamiento de pagos de criptomonedas de múltiples capas, compuesta por aplicaciones móviles y sitios web proporcionados por y en nombre de DITOBANX (colectivamente, la "Plataforma"). Tal y como se utiliza en este Acuerdo, el término "Plataforma" incluye todas estas aplicaciones móviles y sitios web y todos los Servicios y Contenidos (tal y como se definen a continuación). La Plataforma permite a EL CLIENTE, sus empleados y contratistas (cada uno de ellos, un "Usuario") procesar transacciones de criptomoneda y verificar las identidades de los clientes, tal y como se describe en la Plataforma ("Servicios").
                        </p>
                        <p className="text-justify lh-sm">
                        Antes de convertirse en Usuario, y antes de acceder o utilizar la Plataforma, se requiere que EL CLIENTE acepte los términos de este Acuerdo. A menos que El Cliente y DITOBANX hayan firmado cada uno un acuerdo separado en relación con la Plataforma, este documento es el acuerdo completo y exclusivo entre EL CLIENTE y DITOBANX en relación con su acceso y uso de la Plataforma y sustituye cualquier propuesta oral o escrita, acuerdos no firmados u otra comunicación entre El Cliente y DITOBANX en relación con su acceso y uso de la Plataforma.
                        </p>
                        <p className="text-justify lh-sm">
                        ESTOS TÉRMINOS CONTIENEN UNA CLÁUSULA DE ARBITRAJE, QUE LIMITA LOS DERECHOS DE AMBAS PARTES A PRESENTAR UNA DEMANDA ANTE UN TRIBUNAL ORDINARIO Y A QUE POR EL CONTRARIO, LAS DISPUTAS SERÁN DECIDIDAS POR UN TRIBUNAL ARBITRAL, Y DISPOSICIONES QUE LIMITAN LA RESPONSABILIDAD DE DITOBANX HACIA EL CLIENTE. EL INCUMPLIMIENTO POR ALGUNA DE LAS PARTES DE CUALQUIERA DE LAS DISPOSICIONES DE ESTE ACUERDO REVOCARÁ Y PONDRÁ FIN AUTOMÁTICAMENTE, SIN NECESIDAD DE NOTIFICACIÓN U OTRA ACCIÓN, AL DERECHO DE ACCESO A LA PLATAFORMA.
                        </p>
                        <p className="text-justify lh-sm">
                            1.<strong> Término.</strong> El presente Acuerdo entrará en vigencia a partir de la fecha en que EL CLIENTE acceda o utilice por primera vez la Plataforma (la "Fecha de entrada en vigor") y continuará hasta que se rescinda según lo establecido en el presente documento.
                            <br /><br />
                            2.<strong> Modificaciones.</strong> DITOBANX se reserva el derecho, en cualquier momento, de modificar la Plataforma, así como los términos de este Acuerdo, ya sea haciendo que dichas modificaciones estén disponibles en la Plataforma o notificándose al cliente como se especifica en este Acuerdo. Cualquier modificación entrará en vigor en el momento de su publicación en la Plataforma o de la entrega de dicha notificación, a menos que la ley exija lo contrario. EL CLIENTE puede dejar de
 
 utilizar la Plataforma o rescindir el presente Acuerdo en cualquier momento si no está de acuerdo con cualquier modificación. Sin embargo, se considerará que EL CLIENTE ha aceptado todas las modificaciones a través de su uso continuado de la Plataforma después de cinco días hábiles contados a partir de dicha notificación.
 
                            <br /><br />
                            3.<strong> Elegibilidad.</strong> La Plataforma está dirigida a personas mayores de 18 años. Además, para acceder y utilizar la Plataforma es posible que EL CLIENTE tenga que cumplir con otras obligaciones legales o criterios requeridos en la jurisdicción de El Salvador. DITOBANX no garantiza que la Plataforma sea apropiada o esté disponible para su uso en todas las jurisdicciones. El acceso a cualquier parte de la Plataforma está prohibido desde cualquier jurisdicción en la que el acceso o el uso de la Plataforma esté restringido o prohibido por ley.
                            <br /><br />
                            4.<strong> Cuentas y conducta.</strong>
                            <br /><br />
                            4.1. <strong>Creación de una cuenta y responsabilidad.</strong> Antes de convertirse en Usuario, EL CLIENTE debe establecer una cuenta en la Plataforma (una "Cuenta"). La aprobación de su solicitud para establecer una Cuenta será a la sola discreción de DITOBANX. Cada Cuenta, así como la identificación de Usuario y la contraseña de cada Cuenta (la "Identificación de la Cuenta") es para su uso exclusivo y sólo puede ser utilizada por EL CLIENTE y por sus empleados y contratistas autorizados. EL CLIENTE no puede distribuir o transferir su Cuenta o ID de Cuenta o proporcionar a un tercero el derecho de acceso a su Cuenta o ID de Cuenta. EL CLIENTE es el único responsable de todo el uso de la Plataforma a través de su Cuenta. Todas las transacciones realizadas a través de su cuenta o con su identificación de cuenta se considerarán realizadas legalmente por EL CLIENTE. EL CLIENTE garantizará la seguridad y confidencialidad de su ID de Cuenta y notificará a DITOBANX inmediatamente, por correo electrónico a soporte@ditobanx.com , si cualquier ID de Cuenta se pierde, es robada o se ve comprometida de alguna manera. En relación con el establecimiento de una Cuenta, se le pedirá que envíe cierta información sobre EL CLIENTE ("Información de Registro"). EL CLIENTE acepta que (1) toda la Información de Registro que proporcione será verdadera y completa; y (2) mantendrá y actualizará puntualmente su información de registro para mantenerla exacta y actualizada. EL CLIENTE no puede: (a) seleccionar o utilizar un ID de Cuenta de otra persona con la intención de hacerse pasar por dicha persona; y (b) utilizar un ID de Cuenta que DITOBANX, a su entera discreción, considere ofensivo.
                            <br /><br />
                            4.2.<strong> Contenido.</strong> EL CLIENTE es el único responsable de todo el Contenido que proporcione, suba, envíe o publique, o que genere a través del acceso o uso de la Plataforma, incluyendo la conexión o comunicación con otros Usuarios ("Su Contenido"). Su responsabilidad por su Contenido se extiende a la resolución de cualquier disputa que pueda surgir entre EL CLIENTE y cualquier usuario u otra entidad a causa de su contenido. Al proporcionar, cargar, enviar, publicar o generar su contenido, EL CLIENTE otorga a DITOBANX y a sus representantes y contratistas autorizados, el derecho y la licencia no exclusivos mientras se encuentre en vigencia el presente contrato, para utilizar, procesar, almacenar, transmitir y divulgar el contenido no confidencial, siempre y cuando tenga como finalidad: (a) proporcionar los Servicios y cumplir con otras obligaciones descritas en este Acuerdo y (b) para desarrollar y proporcionar servicios para los clientes de DITOBANX. El CLIENTE entiende que en ciertos contextos su contenido no confidencial puede ser visible, enviado y visto por otros usuarios y renuncia expresamente a cualquier derecho de privacidad que pueda tener sobre su contenido en dichos contextos. EL CLIENTE acepta permitirnos, si así lo decidimos a nuestra entera discreción, proporcionar su contenido a otros usuarios o empresas exclusivamente para dar cumplimento con los servicios de verificación de identidad y prevención de lavado de dinero, descritos a continuación.
                            <br /><br />
                            EL CLIENTE declara, garantiza y acuerda que su Contenido (a) no viola este Acuerdo ni ninguna ley aplicable; (b) no es calumnioso, difamatorio, obsceno, abusivo, pornográfico, amenazante, o una invasión de la privacidad; (c) no constituye una infracción o apropiación indebida de los Derechos de Propiedad Intelectual (“DPI”) u otros derechos de cualquier tercero; (d) no es un anuncio o una solicitud de fondos, bienes o servicios; (e) no es falso, engañoso o inexacto; o (f) no
 
puede considerarse correo basura, spam, parte de un esquema piramidal, un mensaje comercial perturbador o un anuncio perturbador. DITOBANX no es responsable de la eliminación, corrección, destrucción, daño, pérdida o fallo en el almacenamiento o copia de seguridad de su Contenido. EL CLIENTE acepta que tiene todos los derechos, títulos, intereses y consentimientos sobre su contenido necesarios para permitir que DITOBANX lo utilice para los fines para los que EL CLIENTE proporciona su Contenido a DITOBANX.

                            <br /><br />
                            4.3. <strong>Conducta.</strong> EL CLIENTE se compromete a no acosar, promover el acoso, o participar en cualquier conducta que sea abusiva o perjudicial para cualquier Entidad. Nos reservamos el derecho, pero no estamos obligados, a investigar y/o prohibir cualquier conducta, o eliminar o rechazar la publicación de cualquier Contenido (incluyendo su Contenido), que consideremos, a nuestra entera discreción, ilegal, dañino, que viole este Acuerdo, o que sea ofensivo para EL CLIENTE, la Plataforma, los Usuarios, nuestros clientes, nuestros derechos, o cualquier entidad. No asumimos ninguna responsabilidad por cualquier acción o inacción con respecto a su conducta, comunicación, transacciones o contenido. Además, podemos revelar cualquier contenido o comunicación electrónica de cualquier tipo (i) para cumplir con cualquier ley o solicitud de una autoridad gubernamental; (ii) si dicha divulgación es necesaria o apropiada para el funcionamiento de la Plataforma; (iii) para proteger nuestros derechos o propiedades; o (iv) si, a nuestra entera discreción, dicho contenido o comunicación electrónica debe ser remitido a las autoridades policiales o gubernamentales.
                            <br /><br />
                            4.4. <strong>Retención.</strong> Su Contenido e información de la Cuenta será retenida de acuerdo con la Política de Privacidad de Binario ubicada en <a href='https://ditobanx.com/polica-de-privacidad' rel='nofollow nopenner'> https://ditobanx.com/polica-de-privacidad</a> ("Política de Privacidad").
                            <br /><br />
                            5.<strong> Acceso.</strong>
                            <br /><br />
                            5.1. <strong>A la plataforma DitoBanx.</strong>Sujeto a que EL CLIENTE cumpla con este Acuerdo, DITOBANX le permitirá acceder y utilizar la Plataforma únicamente para fines comerciales lícitos, de acuerdo con los términos de este Acuerdo y cualquier otro acuerdo que EL CLIENTE acepte antes de que se le dé acceso a cualquier área específica de la Plataforma. DITOBANX requiere que EL CLIENTE primero habilite la aceptación de pagos con Bitcoin a través de la plataforma, antes de aceptar también tarjetas de débito y/o transferencias bancarias seguras. Cualquier acuerdo adicional se suma a este Acuerdo y regirá Su uso de las partes de la Plataforma a las que se aplica el acuerdo adicional en caso de conflicto entre los términos de este Acuerdo y el acuerdo adicional.
                            <br /><br />
                            5.3. <strong>Al contenido.</strong> A menos que se indique lo contrario en la Plataforma, aparte del Contenido de EL CLIENTE, todo el Contenido disponible a través de la Plataforma ("Contenido de la Plataforma" o "Contenido") es propiedad de DITOBANX, de los usuarios que proporcionan ese contenido, o de otros proveedores de contenido de DITOBANX. Todo el Contenido de la Plataforma es sólo para fines informativos y EL CLIENTE es el único responsable de verificar la exactitud, integridad y aplicabilidad de todo el contenido de la Plataforma y de su uso. Sujeto al cumplimiento de este Acuerdo, EL CLIENTE puede acceder al contenido de la plataforma únicamente para sus propios fines comerciales internos en relación con su propio uso de la Plataforma. EL CLIENTE no podrá, ni permitirá que ningún tercero: (a) altere, modifique, reproduzca o cree trabajos derivados de cualquier Contenido de la Plataforma; (b) distribuya, venda, revenda, preste, arrende, licencie, sublicencie o transfiera cualquier Contenido de la Plataforma; o (c) altere, oculte o elimine cualquier derecho de autor, marca registrada o cualquier otro aviso que se proporcione en o en relación con cualquier Contenido de la Plataforma. DITOBANX no ha verificado la exactitud de, y no será responsable de ningún error u omisión en, cualquier Contenido de la Plataforma. Sin limitar lo anterior, DITOBANX no será responsable ante EL CLIENTE o cualquier otro tercero por cualquier articulo educativo o contenido audiovisual publicado en la Plataforma, bajo cualquier ley. A excepción de lo establecido en el presente Acuerdo, no se le concede a EL CLIENTE ninguna licencia ni derecho sobre ningún Contenido de la Plataforma, ni sobre ningún DPI en él o relacionado con él.
                            <br /><br />
                            5.4. <strong>A sitios y servicios de terceros.</strong> La Plataforma puede contener enlaces a sitios de terceros que no están bajo el control de DITOBANX. A menos que se indique lo contrario, cualquier otro sitio al que se acceda desde la Plataforma es independiente de nosotros, y no tenemos ningún control ni somos responsables de su contenido. Los enlaces a sitios web de terceros se proporcionan únicamente para su comodidad y EL CLIENTE accede a ellos bajo su propia responsabilidad. EL CLIENTE reconoce y acepta que DITOBANX no será responsable, directa o indirectamente, de cualquier daño o pérdida causada o supuestamente causada por o relacionada con el uso o la confianza en cualquier contenido, bienes o servicios disponibles a través de cualquier sitio web o recurso de terceros.
                            <br /><br />
                            Su acceso y uso de los sitios de terceros se rige por las condiciones de uso y las políticas de privacidad de estos sitios de terceros.
                            <br /><br />
                            5.5. <strong>A los servicios de terceros.</strong> El uso de ciertas características de la Plataforma puede requerir que EL CLIENTE obtenga acceso a servicios, contenido, funcionalidad, software y otras cosas desarrolladas, proporcionadas o mantenidas por terceros proveedores de servicios (colectivamente, "Servicios de Terceros"). Todos los Servicios de Terceros son proporcionados por terceros y no están bajo el control de DITOBANX. EL CLIENTE reconoce y acepta que DITOBANX no será responsable, directa o indirectamente, de su acceso o uso de cualquier Servicio de Terceros, incluyendo cualquier daño, pérdida, responsabilidad, fallo o problema causado por, relacionado con, o derivado de cualquier Servicio de Terceros.
                            <br /><br />
                            Su uso y acceso a cualquier Servicio de Terceros es exclusivamente entre EL CLIENTE y el proveedor de los Servicios de Terceros. Su acceso y uso de cualquier Servicio de Terceros está sujeto a cualquier término, condición, acuerdo o política de privacidad adicional que se proporcione o celebre en relación con los Servicios de Terceros (cada uno, un "Acuerdo de Terceros"). Los términos de cualquier Acuerdo de Terceros (que pueden incluir el pago de tarifas adicionales) se aplicarán a los Servicios de Terceros proporcionados bajo ese acuerdo, pero no se aplicarán a su acceso o uso de la Plataforma. Salvo lo dispuesto en el presente Acuerdo, en caso de conflicto entre los términos del presente Acuerdo y un Acuerdo de Terceros, los términos del Acuerdo de Terceros prevalecerán con respecto a su acceso y uso de cualquier Servicio de Terceros proporcionado en virtud de dicho acuerdo. El presente Contrato seguirá rigiendo en todos los demás aspectos.
                            {/* <br /><br />
                            5.5.1. <strong>A Pagar con tarjeta.</strong>La Plataforma puede proporcionar a EL CLIENTE acceso a una función de Pago con Tarjeta habilitada a través del proveedor de soluciones de DITOBANX: Wyre. Los usuarios de Pay by Card están obligados a cumplir con los Términos de Servicio de Wyre que se pueden encontrar en: https://www.sendwyre.com/user-agreement/
                             */}
                             <br /><br />
                            6.<strong> Servicios</strong>
                            <br /><br />
                            6.1. <strong>Transacciones.</strong> Los términos y condiciones determinados por DITOBANX se aplicarán en todos los casos, excepto cuando contradigan o violen la ley aplicable. Para cada transacción, EL CLIENTE es el único responsable de confirmar (a) la identidad de su cliente y la información de pago, (b) la recepción del pago en su totalidad, y (c) que el pago se realizó de acuerdo con las instrucciones dadas a través de la Plataforma. EL CLIENTE declara, garantiza y acuerda que no hará, proporcionará, recibirá o intentará hacer, proporcionar o recibir pagos de o a cualquier persona o entidad que sea (x) un tercero, que no sea su cliente o (y) que no cumpla con los parámetros y regulaciones de las leyes de prevención de lavado de dinero establecidas en el país que opere.
                            <br /><br />
                            <strong>
                            En el caso de que la funcionalidad de efectuar pagos salientes en Bitcoin este activa en su cuenta, deberá tomarse en cuenta que una vez que haya aprobado una transacción saliente de Bitcoin On-Chain, no es posible cancelarla, anularla, disputarla o recuperar o devolver sus activos. Una vez que se ha iniciado una transacción, DITOBANX no tiene ningún control sobre la misma y la transacción sólo puede ser aceptada mediante la confirmación a través de la red
 
 blockchain subyacente. EL CLIENTE asume la responsabilidad exclusiva de dicha confirmación y reconoce que DITOBANX no asume ninguna responsabilidad al respecto. EL CLIENTE debe notificar a DITOBANX cualquier discrepancia en las transacciones de pago dentro de los 180 días siguientes a la fecha en que se inició la transacción original.
 
                            </strong>
                            <br /><br />
                            6.2. <strong>Verificación de identidad.</strong> DITOBANX puede requerir que EL CLIENTE verifique su identidad antes de acceder o utilizar ciertos Servicios. Inmediatamente después de la solicitud de DITOBANX, EL CLIENTE proporcionará a DITOBANX todo el contenido, incluyendo su nombre completo y cualquier documento o fotografía, necesarios para que DITOBANX verifique su identidad. EL CLIENTE declara, garantiza y acuerda que dicho contenido es preciso, actual y completo. DITOBANX se reserva el derecho de congelar su Cuenta hasta que su identidad haya sido verificada de acuerdo con esta sección y de cancelar su Cuenta en caso de que EL CLIENTE no cumpla con sus obligaciones bajo esta sección. EL CLIENTE autoriza a DITOBANX y a sus agentes a realizar las investigaciones y solicitar los informes de terceros que considere necesarios para verificar su identidad y la de sus mandantes y otros agentes.
                            <br /><br />
                            6.3. <strong>Bifurcaciones de la cadena de bloques y nuevas criptomonedas.</strong> Es posible que puedan crearse criptodivisas nuevas y alternativas y/o bifurcaciones de los activos digitales disponibles en la cadena de bloques. Esto puede dar lugar a que los titulares de claves privadas que contengan un saldo de activos digitales reciban una cantidad correspondiente de la nueva criptodivisa en la blockchain recién creada ("Air-Drop"). DITOBANX se reserva el derecho, a su entera discreción, de determinar si la Plataforma apoyará cualquier nueva criptodivisa y/o bifurcación de blockchain. Si decidimos no admitir una nueva criptomoneda o bifurcación de blockchain, podemos, pero no estamos obligados, a compensar a los Usuarios que tuvieran activos digitales en su cuenta de DITOBANX en el momento del Air-Drop de la manera que consideremos apropiada. Nos reservamos el derecho a proporcionar dicha compensación convirtiendo la nueva criptomoneda en activos digitales y compartiendo dichos activos digitales entre los Usuarios que tuvieran un saldo de activos digitales en su cuenta en el momento del Air-Drop. Podemos cobrarle una tarifa de procesamiento que consideremos razonable (a nuestra entera discreción) para procesar dicha conversión previa aprobación por parte de EL CLIENTE. Este Acuerdo, incluyendo cualquier referencia a los activos digitales, se aplica a cualquier nueva criptomoneda soportada por la Plataforma. Bajo ninguna circunstancia DITOBANX será responsable ante EL CLIENTE por cualquier daño, pérdida, gasto o perjuicio resultante del ejercicio de los derechos de DITOBANX bajo esta Sección.
                            <br /><br />
                            7. <strong>Honorarios.</strong> Las tarifas establecidas para el uso y acceso a la plataforma son del 1% de comisión sobre el monto digitado en la terminal más el impuesto sobre el valor agregado vigente sobre el monto de comisión que haya sido cobrado. Dicha comisión será descontada automáticamente de la conciliación efectuada en cada desembolso. Las transacciones en bitcoin originadas a través de las herramientas de cobro están libres del riesgo de pérdida o ganancia cambiaria y estas serán desembolsadas en la cuenta bancaria de EL CLIENTE en dólares americanos al día siguiente de haber sido efectuadas.
                            <br /><br />
                            8. <strong>Rescisión.</strong> Cualquiera de las partes podrá rescindir el presente Acuerdo en cualquier momento, a su entera discreción, previa notificación a la otra parte según lo permitido en el presente Acuerdo. Tras la rescisión del presente Acuerdo por cualquier motivo (1) todos los derechos concedidos a EL CLIENTE bajo este Acuerdo terminarán; (2) EL CLIENTE cesará inmediatamente todo uso y acceso a la Plataforma y a todos los Servicios, incluyendo su contenido y cualquier contenido de la Plataforma que haya obtenido antes de la terminación; y (3) DITOBANX podrá, a su sola discreción, eliminar su Cuenta o su Contenido en cualquier momento. El Contenido de EL CLIENTE puede seguir existiendo en la Plataforma y ser visible para los usuarios después de la terminación de este acuerdo a menos que EL CLIENTE lo elimine activamente o contacte con el soporte de DITOBANX y solicite su eliminación. Si EL CLIENTE tiene activos de criptomoneda vinculados a su Cuenta, DITOBANX convertirá dichos activos de criptodivisas en moneda fiduciaria y depositará los mismos en la cuenta bancaria designada, menos cualquier tarifa de transacción aplicable incurrida.
                            <br /><br />
                            DITOBANX podrá solicitar, y EL CLIENTE deberá proporcionar, una prueba razonable de la propiedad de dicha cuenta bancaria antes de que DITOBANX complete el depósito. Las secciones 7 (Tarifas), 8 (Terminación), 10 (Tecnología de la Plataforma), 11 (Propiedad), 12 (Comentarios), 14 (Representaciones y Garantías), 15 (Renuncias), 16 (Indemnización),
17 (Limitación de Responsabilidad), 18 (Privacidad de Datos), 19 (Consentimiento TCPA), 20 (Reclamaciones por infracción), 21 (Fuerza mayor), 22 (Arbitraje), 23 (Renuncia a acciones colectivas), 24 (Ley aplicable y lugar de celebración), 25 (Notificaciones), 26 (Interpretación) y 27 (Condiciones adicionales) seguirán vigentes tras la expiración o la rescisión del presente Acuerdo hasta por un plazo de noventa días calendario.

                            <br /><br />
                            9. <strong>Suspensión.</strong> . Sin limitar el derecho de DITOBANX a rescindir este Acuerdo, DITOBANX también podrá suspender su acceso a su Cuenta y a la Plataforma (incluyendo su contenido), con o sin previo aviso a EL CLIENTE, ante cualquier incumplimiento real, amenaza o sospecha de incumplimiento de este Acuerdo o de la ley aplicable o ante cualquier otra conducta que DITOBANX considere inapropiada o perjudicial para DITOBANX, la Plataforma o cualquier otro cliente o usuario de DITOBANX.
                            <br /><br />
                            10. <strong>Tecnología de la Plataforma.</strong> La Plataforma, y las bases de datos, el software, el hardware y otra tecnología utilizada por o en nombre de DITOBANX para operar la Plataforma, y la estructura, organización y datos subyacentes, información y código de software de la misma (colectivamente, la "Tecnología"), pueden constituir valiosos secretos comerciales de DITOBANX. EL CLIENTE no permitirá, ni permitirá a ningún tercero en la medida de lo posible: (1) acceda o intente acceder a la Tecnología, salvo lo dispuesto expresamente en el presente Acuerdo; (2) utilice la Tecnología de forma ilegal o de cualquier otra forma que pueda dañar, deshabilitar, sobrecargar o deteriorar la Tecnología; (3) utilice scripts automatizados para recopilar información de la Tecnología o interactuar de otra forma con ella; (4) que altere, modifique, reproduzca, cree trabajos derivados de la Tecnología; (5) que distribuya, venda, revenda, preste, arrende, licencie, sublicencie o transfiera cualquiera de sus derechos de acceso o uso de la Tecnología o ponga la Tecnología a disposición de cualquier tercero; (6) realizar ingeniería inversa, desensamblar, descompilar o intentar derivar el método de funcionamiento de la Tecnología; (7) intentar eludir o superar cualquier medida de protección tecnológica destinada a restringir el acceso a cualquier parte de la Tecnología; (8) controlar la disponibilidad, el rendimiento o la funcionalidad de la Tecnología; o (9) interferir en el funcionamiento o el alojamiento de la Tecnología. DITOBANX utiliza medios razonables para proteger la seguridad de la Plataforma, pero reconoce que la seguridad perfecta en Internet es imposible y que, como resultado, su contenido puede quedar en algún momento expuesto en caso de violación.
                            <br /><br />
                            11. <strong>Propiedad.</strong> DITOBANX retiene todos los derechos, títulos e intereses, incluyendo, sin limitación, todos los DPI, en y para la Tecnología y cualquier adición, mejora, actualización y modificación de la misma. EL CLIENTE no recibe ningún interés de propiedad en o para la Tecnología y no se le otorga ningún derecho o licencia para usar la Tecnología en sí, aparte de su capacidad para acceder a la Plataforma bajo este Acuerdo. El nombre de DITOBANX y DitoBanx, el logotipo y todos los nombres de productos y servicios asociados a la Plataforma son marcas comerciales de DITOBANX y sus licenciantes y proveedores, y no se le concede a EL CLIENTE ningún derecho o licencia para utilizarlos. A efectos del presente Acuerdo, "DPI" significa todos los derechos de propiedad intelectual, derechos de propiedad, derechos de publicidad, derechos de privacidad, y todos y cada uno de los otros derechos legales que protegen los datos, la información o la propiedad intangible en todo el mundo, incluyendo, sin limitación, todos y cada uno de los derechos de autor, marcas comerciales, marcas de servicio, secretos comerciales, derechos de patente, derechos morales, derechos sui generis en bases de datos y derechos contractuales.
                            <br /><br />
                            12. <strong>Comentarios.</strong> EL CLIENTE acepta que cualquier comentario o idea que EL CLIENTE proporcione a DITOBANX en relación con la Plataforma o su Tecnología subyacente o cualquier sugerencia de mejora de la misma (en conjunto, el "Feedback") será propiedad exclusiva de DITOBANX. En la medida en que EL CLIENTE sea propietario de cualquier derecho sobre el Feedback, EL CLIENTE acepta ceder, y así lo hace, todos los derechos, títulos e intereses sobre el Feedback a DITOBANX. EL
 
 CLIENTE se compromete a realizar todos los actos razonablemente solicitados por DITOBANX para perfeccionar y hacer cumplir dichos derechos.
 
                            <br /><br />
                            13. <strong>Disponibilidad.</strong> DITOBANX hará esfuerzos razonables para asegurar que EL CLIENTE pueda acceder a la Plataforma de acuerdo con este Acuerdo; sin embargo, DITOBANX no garantiza que la Plataforma esté disponible en todo momento. DITOBANX hará esfuerzos razonables para avisar del mantenimiento planificado. EL CLIENTE aceptas los riesgos asociados al hecho de que no siempre podrás utilizar la Plataforma o realizar transacciones utilizando su Cuenta.
                            <br /><br />
                            14. <strong>Declaraciones y garantías.</strong>
                            <br /><br />
                            14.1. <strong>Generalidades.</strong> EL CLIENTE declara y garantiza a DITOBANX que: (a) EL CLIENTE tiene el derecho y la autoridad legal para celebrar este Acuerdo; (b) este Acuerdo constituye una obligación legal vinculante en su nombre; y (c) EL CLIENTE tiene el derecho y la autoridad legal para cumplir con sus obligaciones en virtud de este Acuerdo y para otorgar los derechos y licencias descritos en este Acuerdo.
                            <br /><br />
                            14.2. <strong>Cumplimiento de las leyes.</strong> EL CLIENTE reconoce que la Plataforma es un servicio en línea de propósito general y que no está diseñado específicamente para facilitar el cumplimiento de ninguna ley específica. El uso de la Plataforma en cumplimiento de cualquier ley, norma o regulación específica aplicable a EL CLIENTE, u otros datos o información que pueda proporcionar o generar a través de la Plataforma es única responsabilidad de EL CLIENTE. DITOBANX no es responsable de que EL CLIENTE de cumplimiento de cualquier ley de este tipo o de su incumplimiento.
                            <br /><br />
                            Independientemente de la jurisdicción en la que utilice o acceda a la Plataforma, EL CLIENTE declara y garantiza a DITOBANX que su uso y acceso a la Plataforma, incluyendo, sin limitación, su Contenido y cualquier otro dato o información que pueda proporcionar o generar a través de su uso o acceso a la Plataforma, cumplirá con todas las leyes, normas y reglamentos aplicables y no hará que DITOBANX viole ninguna ley aplicable. La obligación anterior incluye el cumplimiento de todas las leyes aplicables a la transmisión de datos en Internet, incluyendo, pero sin limitarse a, las leyes que rigen la transmisión de datos o fondos a través de fronteras internacionales, a países prohibidos, y que contengan información financiera, técnica y/o de identificación personal.
                            <br /><br />
                            14.3. <strong>Negocios restringidos.</strong>
                            <br /><br />
                            Las siguientes categorías de negocios y prácticas comerciales tienen prohibido el uso de los servicios de pago de DITOBANX ("Negocios restringidos"). Las categorías de Negocios Restringidos pueden ser impuestas a través de las Reglas de la Red o los requisitos de nuestras Soluciones de Pago y Proveedores de Servicios Financieros.
                            <br /><br />
                            En algunos casos, las empresas que se enumeran a continuación pueden ser elegibles para el procesamiento con <strong>la aprobación previa explícita de DITOBANX</strong>. Tenga en cuenta, sin embargo, que los negocios que ofrecen productos o servicios ilegales nunca son elegibles para usar los Servicios de DITOBANX. Los tipos de empresas que aparecen a continuación son representativos, pero no exhaustivos. Si no está seguro de si su negocio es un negocio restringido o tiene preguntas sobre cómo se aplican estos requisitos en su caso, póngase en contacto con nosotros.
                            <br /><br />
                            Al registrarte con nosotros, confirma que no utilizará el Servicio para aceptar pagos en relación con los siguientes negocios, actividades comerciales o prácticas comerciales, a menos que haya recibido la aprobación previa por escrito de DITOBANX:
                            <br /><br />
                            <strong> {'>'} Servicios monetarios y legales </strong>
                            <br />
                            Descripción
                            Instituciones financieras, transmisores de dinero y negocios de servicios monetarios, cambio de cheques, transferencias bancarias, giros postales; cambios o distribuidores de divisas; servicios de pago de facturas; crowdfunding; seguros; fianzas; agencias de cobro; bufetes de abogados que recauden fondos para cualquier propósito que no sea el pago de los honorarios adeudados al bufete por los servicios prestados por el mismo (por ejemplo, los bufetes no pueden utilizar DITOBANX para retener fondos de clientes, importes de cobros o liquidaciones, fondos en disputa, etc.)
                            <br /><br />
                            <strong> {'>'}Infracción de la propiedad intelectual o de los derechos de propiedad </strong>
                            <br />
                            Descripción
                            Venta, distribución o acceso a música, películas, programas informáticos u otros materiales con licencia falsificados sin la debida autorización del titular de los derechos; cualquier producto o servicio que infrinja directamente o facilite la infracción de la marca comercial, la patente, los derechos de autor, los secretos comerciales o los derechos de propiedad o privacidad de cualquier tercero.
Mercancías falsificadas o no autorizadas Mercancías falsificadas, también conocidas como "réplicas"; venta o reventa no autorizada de productos o servicios de marca o de diseño; venta de productos o servicios importados o exportados ilegalmente.
Juegos de azar ilegales o Loterías ilegales; subastas de honorarios de pujas; pronósticos deportivos o apuestas por un premio monetario o material; ligas de deportes de fantasía con premios en metálico; juegos de azar por Internet; concursos; sorteos; juegos de azar, incluyendo formas legales o ilegales de juego, juegos de azar por Internet, sorteos y concursos con una entrada o un premio en metálico; sorteos y rifas benéficas con el propósito explícito de recaudar fondos. Prácticas desleales, predatorias o engañosas

                            <br /><br />
                            15. <strong>Descargo de responsabilidad</strong>
                            <br /><br />
                            15.1.	No hay asesoramiento fiscal, legal, financiero o de inversión. EL CLIENTE no debe interpretar ningún Contenido proporcionado en la Plataforma como asesoramiento fiscal, jurídico, financiero o de inversión. No tenemos ninguna relación especial ni deber fiduciario con EL CLIENTE y su uso de la Plataforma no crea tal relación. EL CLIENTE acepta y reconoce que es el único responsable de llevar a cabo una revisión legal, contable y de otro tipo de diligencia debida sobre la información publicada en la Plataforma.
                            <br /><br />
                            15.2. <strong>Reconocimiento del riesgo de las criptomonedas.</strong> DITOBANX no respalda ni representa la fiabilidad o exactitud de ningún Contenido distribuido a través de la Plataforma o al que se acceda desde ella, y no ha realizado ninguna investigación sobre dicho Contenido. Por lo tanto, DITOBANX no será responsable de ninguna decisión de inversión o transacción realizada en base a cualquier Contenido disponible en la Plataforma. Cualquier confianza en dicho Contenido es bajo su propio riesgo.
                            <br /><br />
                            15.3. <strong>Disputas sobre las transacciones.</strong> La única participación de DITOBANX en las transacciones digitales en la Plataforma es como procesador de pagos. Si se produce un desacuerdo entre EL CLIENTE y un Usuario o cliente sobre una transacción (una "Disputa de Transacción"), EL CLIENTE y ese Usuario o cliente son los únicos responsables de resolver la Disputa de Transacción. DITOBANX renuncia a cualquier responsabilidad que surja de o esté relacionada con una Disputa de Transacción y no mediará, intentará resolver o se involucrará de alguna manera en cualquier Disputa de Transacción.
                            <br /><br />
                            15.4. Generalidades. La plataforma se proporciona "tal cual" y "como está disponible". DITOBANX y sus proveedores no aseguran ni garantizan la exactitud, integridad, disponibilidad, adecuación o actualidad de ningún servicio o contenido y no respaldan los puntos de vista u opiniones que puedan expresarse o proporcionarse a través de la plataforma.
                            <br /><br />
                            DITOBANX y sus proveedores no garantizan la adecuación o actualidad de la plataforma, y no respaldan los puntos de vista u opiniones que puedan ser expresados o proporcionados por los usuarios de la plataforma. Ninguna información o consejo oral o escrito dado por la plataforma, DITOBANX o sus empleados, proveedores o agentes creará una garantía de ningún tipo. DITOBANX y sus proveedores renuncian específicamente a cualquier representación o garantía de que la plataforma satisfaga sus necesidades o funcione sin interrupciones o sin errores.
                            <br /><br />
                            16. <strong>Indemnización.</strong> Por la presente, EL CLIENTE acepta indemnizar, defender y eximir de responsabilidad a DITOBANX y a sus funcionarios, directores, accionistas, afiliados, empleados, agentes, contratistas, cesionarios, Usuarios, clientes, proveedores, licenciatarios y sucesores en interés ("Partes Indemnizadas") de todas y cada una de las reclamaciones, pérdidas, responsabilidades, daños y perjuicios, honorarios, gastos y costes (incluidos los honorarios de los abogados, las costas judiciales, las indemnizaciones por daños y perjuicios y los importes de los acuerdos) que resulten de cualquier reclamación o alegación contra cualquier parte Indemnizada que hayan sido originados por el incumplimiento por parte de EL CLIENTE de cualquier declaración, garantía u otra disposición de este Acuerdo. DITOBANX notificará a EL CLIENTE de cualquier reclamación o alegación, y DITOBANX tendrá derecho a participar en la defensa de cualquier reclamación a su cargo.
                            <br /><br />
                            17. <strong> Limitación de la responsabilidad. </strong>
                            <br /><br />
                            La responsabilidad total acumulada de DITOBANX en relación con este acuerdo y su provisión de la plataforma bajo este acuerdo, ya sea en contrato o agravio o de otra manera, no excederá los honorarios que el cliente realmente pagó a DITOBANX por el uso y acceso a la plataforma en los doce meses anteriores. el cliente acepta que DITOBANX no celebraría este acuerdo sin estas limitaciones de su responsabilidad. En las jurisdicciones en las que no se permite la limitación de la responsabilidad por daños consecuentes o incidentales, la responsabilidad de DITOBANX se limita al máximo permitido por la ley.
                            <br /><br />
                            18. <strong>Privacidad de los datos.</strong> EL CLIENTE consiente expresamente el uso y la divulgación de su información personal identificable y de su Contenido como se describe en la Política de Privacidad. Sin perjuicio de lo establecido en la Política de Privacidad, DITOBANX tendrá derecho a recoger, extraer, compilar, sintetizar y analizar datos o información no identificables personalmente que resulten de su acceso y uso de la Plataforma.
                            <br /><br />
                            En la medida en que dichos datos o información no identificables personalmente sean recogidos o generados por DITOBANX, los datos y la información serán propiedad exclusiva de DITOBANX y podrán ser utilizados por DITOBANX para cualquier propósito comercial legal sin obligación de rendir cuentas a EL CLIENTE, siempre que los datos y la información se utilicen únicamente de forma agregada, sin identificar directamente a EL CLIENTE o a cualquier otra entidad o persona física como fuente de los mismos.
                            <br /><br />
                            19. <strong>Consentimiento de Comunicaciones.</strong> A pesar de cualquier elección actual o anterior para optar por recibir llamadas de telemarketing o mensajes SMS (incluyendo mensajes de texto) de DITOBANX o de cualquier persona que llame en nuestro nombre, EL CLIENTE consiente expresamente en ser contactado por DITOBANX y cualquier persona que llame en nuestro nombre para cualquier y todos los propósitos que surjan de o estén relacionados con este Acuerdo o su uso del Sistema o Servicios, en cualquier número de teléfono, o dirección física o electrónica que EL CLIENTE proporcione o en la que pueda ser localizado.
                            <br /><br />
                            EL CLIENTE acepta que podamos ponernos en contacto de cualquier forma, incluidos los mensajes SMS (incluidos los mensajes de texto), las llamadas con mensajes pregrabados o voz artificial, y las llamadas y mensajes emitidos mediante
 
un sistema de marcación telefónica automática o un sistema de mensajes de texto automáticos. Los mensajes automáticos pueden reproducirse cuando se contesta el teléfono, ya sea por EL CLIENTE o por otra persona. En el caso de que un agente o representante llame, también puede dejar un mensaje en su contestador automático, en el buzón de voz o enviar uno por texto. EL CLIENTE da su consentimiento para recibir mensajes SMS (incluyendo mensajes de texto), llamadas y mensajes (incluyendo voz pregrabada y artificial y marcación automática) de nosotros, nuestros agentes, representantes, afiliados o cualquier persona que llame en nuestro nombre al número(s) específico(s) que EL CLIENTE nos haya proporcionado, o a los números que podamos asociar razonablemente con su cuenta (a través de rastreo de llamadas, captura de identificación de llamadas u otros medios), con información o preguntas sobre su cuenta o el uso del Sistema o los servicios. EL CLIENTE certifica, garantiza y declara que los números de teléfono que nos ha proporcionado son sus números de contacto. Declara que está autorizado a recibir llamadas en cada uno de los números de teléfono que nos ha proporcionado y se compromete a avisarnos inmediatamente cuando deje de utilizar un número de teléfono concreto. Su proveedor de telefonía móvil o celular le cobrará según el tipo de plan que tenga. También acepta que podamos ponernos en contacto por correo electrónico, utilizando cualquier dirección de correo electrónico que nos haya proporcionado o que nos proporcione en el futuro. Podemos escuchar y/o grabar las llamadas telefónicas entre EL CLIENTEy nuestros representantes sin avisarle, según lo permita la legislación vigente. Por ejemplo, escuchamos y grabamos las llamadas con fines de control de calidad del servicio al cliente.

                            <br /><br />
                            20. <strong>Reclamaciones por infracción.</strong> DITOBANX respeta sus derechos de autor y otros derechos de propiedad intelectual y los de otros terceros. Si cree de buena fe que su trabajo protegido por derechos de autor ha sido reproducido en la Plataforma sin su autorización de una manera que constituye una infracción de los derechos de autor, puede notificarnos a nosotros o a nuestro agente designado de derechos de autor por correo a:<br /><br />
                            <a href='mailto:soporte@ditobanx.com' rel='nofollow nopenner'>soporte@ditobanx.com</a>
                            <br /><br />
                            Por favor, incluya la siguiente información en su correspondencia (1) la identidad de la obra infringida, y de la obra supuestamente infractora; (2) su nombre, dirección, número de teléfono diurno y dirección de correo electrónico, si está disponible; (3) una declaración de que EL CLIENTE cree de buena fe que el uso de la obra con derechos de autor no está autorizado por el propietario, su agente o la ley; (4) una declaración de que la información en la notificación es exacta y, bajo pena de perjurio, que EL CLIENTE está autorizado a actuar en nombre del propietario; y (5) su firma electrónica o física.
                            <br /><br />
                            21. <strong>Fuerza mayor.</strong> Ninguna de las partes será responsable del cumplimiento de sus obligaciones en virtud del presente documento cuando se vea retrasado u obstaculizado por acontecimientos que escapen a su control razonable, incluyendo, sin limitación, casos fortuitos o de cualquier organismo gubernamental (incluyendo la regulación, la aplicación, los controles o las restricciones sobre la criptodivisa, el blockchain, el Sistema o el Servicio), guerras o emergencias nacionales, disturbios o insurrecciones, sabotajes, embargos, incendios, inundaciones, accidentes, huelgas u otros disturbios laborales, o interrupciones o retrasos en los sistemas, la energía o las telecomunicaciones bajo el control de terceros.
                            <br /><br />
                            22. <strong>Arbitraje.</strong> Toda desavenencia, diferencia, conflicto, controversia o disputa que surja entre las partes contratantes, a excepción del incumplimiento por mora que será por vía judicial, relativa a su ejecución, interpretación, o cualquier otro asunto, se deberá dirimir amigablemente por trato directo entre las partes en un plazo de cinco días hábiles. De no llegar a un arreglo por trato directo, la controversia se someterá a arbitraje en la ciudad de San Salvador y ante el Centro de Mediación y Arbitraje de la Cámara de Comercio e Industria de El Salvador, sujeto a las reglas de procedimiento dictadas por el mismo Centro. El idioma del arbitraje será el castellano. En ausencia de regla procedimental expresa, las partes delegan en el tribunal arbitral el señalamiento de las mismas. El tribunal arbitral estará conformado por tres árbitros,
 
 designados uno por cada parte, los cuales antes de dar principio a su labor, designarán a un tercero que será el presidente. El laudo arbitral deberá dictarse con sujeción a derecho, dentro de los cuatro meses posteriores a la aceptación del cargo del último de los árbitros designados; lo anterior no es obstáculo para que las partes de común acuerdo puedan prorrogar el plazo. Las costas del arbitraje serán pagadas por partes iguales por cada parte, salvo las de sus propios abogados, asesores y peritos. No habrá especial condena en costas procesales, aun cuando si procediera condena al pago de las otras cantidades adeudadas que determine el correspondiente laudo arbitral.
 
                            <br /><br />
                            23. <strong>Renuncia a la acción colectiva.</strong> En la medida en que lo permita la ley aplicable, ningún arbitraje se unirá a un arbitraje que implique a cualquier otra parte sujeta a este acuerdo, ya sea a través de procedimientos de arbitraje de clase o de otro tipo. cualquier procedimiento de resolución de disputas, ya sea en un arbitraje o en un tribunal, se llevará a cabo únicamente de forma individual y no en una acción de clase o representativa o como miembro nombrado o no en una acción de clase, consolidada, representativa o de abogado general privado, a menos que tanto el cliente como DITOBANX lo acuerden específicamente por escrito tras el inicio del arbitraje.
                            <br /><br />
                            24. <strong>Ley aplicable y jurisdicción.</strong> La interpretación de los derechos y obligaciones de las partes en virtud de este Acuerdo, incluyendo, en la medida en que sea aplicable, cualquier negociación, arbitraje u otro procedimiento en virtud del mismo, se regirá en todos los aspectos exclusivamente por las leyes del Estado de la República de El Salvador.
                            <br /><br />
                            25. <strong>Notificaciones.</strong> A menos que se especifique lo contrario en este Acuerdo, cualquier notificación requerida o permitida en virtud de este Acuerdo se proporcionará a DITOBANX por correo electrónico a la siguiente dirección: empresas@ditobanx.com
                            <br /><br />
                            DITOBANX podrá proporcionarle a EL CLIENTE cualquier notificación requerida o permitida bajo este Acuerdo enviándole un correo electrónico a cualquier dirección de correo electrónico que proporcione a DITOBANX en relación con su Cuenta, DITOBANX podrá en su lugar proporcionar dicha notificación mediante su publicación en la Plataforma.
                            <br /><br />
                            Las notificaciones proporcionadas a DITOBANX se considerarán entregadas cuando sean efectivamente recibidas por DITOBANX. Las notificaciones proporcionadas a EL CLIENTE se considerarán entregadas al ser publicadas en la Plataforma o enviadas por correo electrónico, a menos que la ley prohíba lo contrario o (en cuanto al correo electrónico) la parte remitente sea notificada de que la dirección de correo electrónico no es válida.
                            <br /><br />
                            26. <strong>Términos adicionales.</strong> Este Contrato es el acuerdo completo y exclusivo entre EL CLIENTE y DITOBANX en relación con su acceso y uso de la Plataforma. Este Acuerdo sustituye cualquier acuerdo o propuesta anterior, oral o escrita, y cualquier otra comunicación entre EL CLIENTE y DITOBANX en relación con su uso de la Plataforma. Todas las renuncias por parte de DITOBANX bajo este Acuerdo deben ser por escrito o ser reconocidas posteriormente por DITOBANX por escrito. Cualquier renuncia o incumplimiento por parte de DITOBANX de cualquier disposición de este Acuerdo en una ocasión no se considerará una renuncia por parte de DITOBANX de cualquier otra disposición o de dicha disposición en cualquier otra ocasión. Si alguna disposición de este Acuerdo se considera inaplicable, dicha disposición será eliminada en la medida necesaria para cumplir con la ley, sustituida por una disposición que se aproxime lo más posible a la intención original y al efecto económico del original en la medida en que sea compatible con la ley, y las disposiciones restantes permanecerán en pleno vigor.
                            <br /><br />
                            La parte que prevalezca en cualquier pleito o procedimiento derivado de este Acuerdo o relacionado con él tendrá derecho a recibir sus costes, los honorarios de los peritos y los honorarios razonables de los abogados, incluidos los costes y los honorarios de la apelación. EL CLIENTE no puede ceder o transferir este Acuerdo o cualquiera de Sus derechos u obligaciones en virtud del mismo (en su totalidad o en parte e incluyendo por venta, fusión, consolidación u otra operación de ley) sin la aprobación previa por escrito de DITOBANX. Cualquier cesión que viole lo anterior será nula y sin efecto. DITOBANX podrá ceder este Acuerdo a cualquier parte que asuma las obligaciones de DITOBANX en virtud del mismo. Las partes del presente son contratistas independientes, no son agentes, empleados o empleadores de la otra parte, ni empresas conjuntas, y ninguna de ellas adquiere en virtud del presente ningún derecho o capacidad para obligar o contraer ninguna obligación en nombre de la otra.
                            <br /><br />
                            27. <strong>Instituciones de Supervisión Financiera. </strong>
                            <br /><br />
                            DITOBANX es una institución inscrita en el Banco Central de Reserva como proveedor de servicios Bitcoin y además está sujeta de supervisión por la Superintendencia del Sistema Financiero de El Salvador. Cualquier notificación de incumplimiento de las regulaciones o quejas no resueltas puede ser notificada directamente a dicha institución al correo: atencionalusuario@ssf.gob.sv o al teléfono 2505-6999 opción 1.
                            <br /><br />
                            En los términos expuestos los otorgantes, en las calidades con las que actuamos respectivamente, declaramos nuestra plena conformidad con el contenido del presente documento
                            <br /><br />
                            Leído lo escrito y bien enterados de su contenido, objeto, validez y efectos legales, lo ratificamos, aceptamos y firmamos, en dos ejemplares originales de igual valor y tenor, quedando cada otorgante en poder de una ejemplar.
                            <br /><br />
                            En la ciudad de San Salvador,
                        </p>
                        <div className='py-5'></div>
                        <div className='row'>
                            <div className='col-6 text-start'>
                            DITOBANX EL SALVADOR, S.A. DE C.V. 
                            </div>
                            <div className='col-6 text-start'>
                                POR EL CLIENTE
                            </div>
                        </div>
                        <div className='mt-5 pt-5 border-top'>
                            <a className="btn btn-primary btn-lg text-white" href="https://ditobanx.com/TERMINOS_Y_CONDICIONES.pdf">Descargar o Imprimir</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TerminosCondiciones;