import React, { Component } from 'react';
import './giftcard.css';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
/*Import custom Components */
import MailChimp from '../../../../../Componentes/MailChimp/MailChimp';
import SocialShare from '../../../../../Componentes/SocialShare/SocialShare';

/*import assets */
import IconArrow from '../../../../../assets/img/icon-row-right.svg';
// import IconFB from '../../../../../assets/img/ico-social/ico-fb.svg';
// import IconIn from '../../../../../assets/img/ico-social/ico-in.svg';
// import IconTwt from '../../../../../assets/img/ico-social/ico-twt.svg';
// import IconInsta from '../../../../../assets/img/ico-social/ico-insta.svg';

/*Import Images Feed */
import FeaturedImage from '../../../../../assets/img/img-comunicados/giftcardArticulo.jpg';
import IconBuzon from '../../../../../assets/img/icon-buzon.svg';
import Profile from '../../../../../assets/img/img-comunicados/ceo-ing-guillermo-contreras.jpeg';
import giftcardImage1 from '../../../../../assets/img/img-comunicados/giftcardPortada.jpg'

export default class ComunicadoGiftcard extends Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
            
                <div className='mt-5 pt-4'></div>
                <Helmet>
                    <title>Comunicado Giftcard| DitoBanx</title>
                    <meta property="og:title" content="Comunicado Giftcard| DitoBanx"/>
                    <meta property="og:description" content="DitoBanx anuncia Tarjeta raspable de Bitcoin para facilitar su adquisición a los no bancarizados"/>
                    <meta property="og:image" itemProp="image" content={giftcardImage1}/>
                    <meta property="og:type" content="website"/>
                    
                    {/* <image><img src={giftcardImage1} className="custom-featured-image  img-fluid rounded-3 giftcardPortada" alt='DitoBanx, giftcards' /></image> */}
                </Helmet>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-8 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <a href={'/noticias-prensa'} className='text-decoration-none'>
                                            <img src={IconArrow} alt='' width={22} className='mb-2' />
                                            <span className='px-2 h1-custom'>Comunicados de prensa</span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="DitoBanx anuncia Tarjeta raspable de Bitcoin para facilitar su adquisición a los no bancarizados"
                                            LinkedInTitle="DitoBanx anuncia Tarjeta raspable de Bitcoin para facilitar su adquisición a los no bancarizados"
                                            Size="32px"
                                            className="d-none"
                                            

                                        />
                                    </div>
                                </div>
                                <Col className='py-3 col-12 px-2'>
                                    <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                    <span className='mx-2 color-custom-date'>AGOSTO 26, 2022</span>
                                </Col>
                                <Col className="mb-3 mx-2">
                                    <img src={FeaturedImage} className="custom-featured-image img-fluid rounded-3 custom-featured-imageGf" alt='DitoBanx,  startup en servicios Bitcoin,
                                        se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' />
                                    <div className='color-custom-date py-1 px-3 border-bottom'>Imagen creada por Team Ditobanx</div>
                                </Col>
                                <Col>
                                    <h1 className='fw-bold lh-1 pb-2 px-2'>DitoBanx anuncia Tarjeta raspable de Bitcoin para facilitar su adquisición a los no
bancarizados
                                    </h1>
                                    <span className='fw-bold px-2'>Por DitoBanx</span>
                                </Col>
                                <Col className="py-3 px-2">
                                    {/* <img src={Profile} style={{ width: '18.2rem' }} className="d-block pe-3 text-center mx-auto float-center float-md-start rounded-3 pb-3 pb-md-0" alt='itoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' /> */}
                                    <p className='lh-sm text-justify'>
                                    <strong> El Salvador, 26 de Agosto de 2022.</strong> DitoBanx, <strong>fintech crypto enfocado en la inclusión
                                    financiera</strong> anunció este día el lanzamiento de su <strong>giftcard raspable</strong> la cual facilitará la compra
                                    de bitcoin sin necesidad de una cuenta bancaria o tarjeta de crédito.<br></br><br></br>

                                    A partir de este día cualquier persona podrá adquirir directamente en los comercios
                                    participantes una giftcard raspable o un voucher de bitcoin <strong>haciendo uso de efectivo</strong> la cual
                                    podrá ser inmediatamente redimido en cualquier wallet que acepte Bitcoin On-Chain o
                                    Bitcoin LNURL.<br></br><br></br>

                                    Los vouchers y tarjetas raspables estarán disponibles en valores de $5, $10, $20, $40, $60 y
                                    $100 dólares.<br></br><br></br>

                                    <span  style={{fontStyle: 'italic'}}>“Uno de los objetivos que perseguimos es que el 70% de la población que no cuenta con una
                                    tarjeta de crédito o cuenta bancaria tenga acceso para poder adquirir Bitcoin fácilmente ya
                                    que anteriormente esto estaba restringido únicamente para el sector bancarizado.<br></br><br></br>
                                    Este producto también estará disponible en Estados Unidos, México, Panamá, Argentina y
                                    Colombia facilitando a la vez el envío de remesas ya que una persona podrá adquirir la
                                    giftcard bitcoin en cualquier tienda participante y luego simplemente enviar los códigos a su
                                    familiar en otro país para que este lo redima y cambie inmediatamente a la moneda de su
                                    preferencia.”</span> puntualizó el  <strong>CEO</strong> y fundador de DitoBanx Guillermo Contreras.<br></br><br></br>
                                    Inicialmente las giftcards y vouchers bitcoin estarán disponibles en El Salvador en las tiendas
                                    TenGo Market Santa Elena, la casa del bitcoin de Paxful , Hope House- el Zonte y en los
                                    eventos desarrollados en conjunto con las billeteras Strike, Bitcoin Beach Wallet y los
                                    eventos educativos de Mi Primer Bitcoin<br></br><br></br>
                                    <span  style={{fontStyle: 'italic'}}>“En DitoBanx estamos enfocados en la inclusión financiera y bitcoin es clave en este proceso
                                    ya que permite que una persona tenga la capacidad de ahorrar , hacer y recibir envíos de
                                    dinero instantáneos de todo el mundo y a toda hora. Las herramientas innovadoras también
                                    nos permiten brindarles acceso al crédito a tasas justas.”</span><br></br><br></br>
                                    Dentro de la estrategia de DitoBanx está previsto aperturar operaciones también en México
                                    y otros países de la región centroamericana con el objetivo de continuar su plan de
                                    expansión y consolidación del uso de bitcoin como medio de intercambio de valor.<br></br>
                                    
                                        
                                    </p>
                                </Col>
                                
                                <Col>
                                    <h3 className='fs-5'><strong>Sobre DitoBanx</strong> </h3>
                                    <p className='lh-sm text-justify'>
                                        <strong>DitoBanx, </strong> es una fintech licenciada y regulada que tiene como propósito brindar
                                    oportunidades a la población tradicionalmente excluida del sector financiero por medio del
                                    uso de bitcoin
                                        <br /><br />
                                        
                                    </p>
                                    <p>
                                    Para mayor información<br></br>
                                    Sitio web   <a style={{color:'#f7931a'}} href={'https://ditobanx.com'} target="_blank" >https://ditobanx.com</a><br></br>
                                    Información adicional <a href={'mailto:empresas@ditobanx.com'} target="_blank" >empresas@ditobanx.com</a>
                                    </p>
                                </Col>
                                <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                    <Row>
                                        <Col>
                                            <Card.Body className='pt-0'>
                                                <Card.Title className="mb-0 text-center text-md-start">Equipo DitoBanx</Card.Title>
                                                <span className='d-block text-center text-md-start fw-bold'>Noticias</span>
                                                <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                    Presentadora
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                        <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                            <div className="col text-center tex-md-end">
                                                <SocialShare
                                                    PostUrl={urlLocation}
                                                    TwitterTitle="DitoBanx anuncia Tarjeta raspable de Bitcoin para facilitar su adquisición a los no bancarizados"
                                                    LinkedInTitle="DitoBanx anuncia Tarjeta raspable de Bitcoin para facilitar su adquisición a los no bancarizados"
                                                    Size="32px"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='col-12 col-md-3 px-0 px-md-0 ms-0 ms-md-4'>
                                <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0 pt-5'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
