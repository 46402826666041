import React, { Component } from 'react'
import Helmet from 'react-helmet'
import './MailChimp.css'

export default class MailChimp extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
                </Helmet>
                <div id="mc_embed_signup">
                    <form action="https://ditobanx.us5.list-manage.com/subscribe/post?u=79bfdc98a643e2ac233a73e3d&amp;id=71cf8cb195"
                        method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank"
                        novalidate>
                        <div id="mc_embed_signup_scroll">
                            <div className="indicates-required"><span className="asterisk">*</span> campo requerido</div>
                            <div className="mc-field-group">
                                {/* <label for="mce-FNAME">Nombre </label> */}
                                <input type="text" name="FNAME" className="email" id="mce-FNAME" placeholder="Nombre" />
                            </div>
                            <div className="mc-field-group">
                                {/* <label for="mce-EMAIL"> <span className="asterisk">*</span>
                                </label> */}
                                <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Correo electrónico" />
                            </div>
                            <div id="mce-responses" className="clear">
                                <div className="response" id="mce-error-response" ></div>
                                <div className="response" id="mce-success-response" ></div>
                            </div>
                            <div className="clear text-center-btn">
                                <input type="submit" value="Enviar" name="subscribe" id="mc-embedded-subscribe"
                                    className="button" />

                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}
