import React, { Component } from 'react';
// import Contacto from '../../Paginas/Contacto/Contacto';
// import Inicio from '../../Paginas/Inicio/Inicio';
// import Precio from '../../Paginas/Precio/Precio';
// import Productos from '../../Paginas/Productos/Productos';
// import logo from '../../assets/img/Logo_dito_pay.svg';
import logoditobanxdark from '../../assets/img/ditobanx-dark.svg';
//social icons//
import IconEmail from '../../assets/img/ico-email.svg';
import IconWha from '../../assets/img/ico-wha.svg';
import IconFB from '../../assets/img/ico-facebook.svg';
import IconIn from '../../assets/img/ico-in.svg';
import IconTwt from '../../assets/img/ico-twt.svg';
import IconInsta from '../../assets/img/ico-insta.svg';
import './Navbar.css';
// import { Helmet } from 'react-helmet';

//Import Boostram components
import { NavDropdown, Container, Navbar, Nav, Button } from 'react-bootstrap';

class NavbarHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        };
        this.handleMenuHover = this.handleMenuHover.bind(this);
    }
    handleMenuHover() {
        this.setState({
            isHovered: !this.state.isHovered
        });
    }
    render() {
        
        return (
          <>
            <div className="pb-3">
              <header className="fixed-top">
                <div className="container-fluid py-2 py-lg-1 bg-dark ">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <a
                          target="_blank"
                          rel="nofollow noreferrer"
                          className="text-white text-decoration-none text-navbar-whatsapp"
                          href="https://wa.me/+50322392425"
                        >
                          <img
                            src={IconWha}
                            className="social-icon d-inline-block align-text-midle  mx-1"
                            alt="Facebook Dito Pay"
                            width="22px"
                          />
                          WhatsApp
                        </a>
                        <a
                          target="_blank"
                          rel="nofollow noreferrer"
                          className="text-white d-none d-lg-inline link-light text-decoration-none mx-3 "
                          href="mailto:hola@ditopay.io"
                        >
                          <img
                            src={IconEmail}
                            className="social-icon d-inline-block align-text-midle  mx-1"
                            alt="Facebook Dito Pay"
                            width="22px"
                          />
                          hola@ditobanx.com
                        </a>
                      </div>
                      <div className="col align-middle text-end">
                        <a
                          target="_blank"
                          rel="nofollow noreferrer"
                          href="https://www.facebook.com/ditobanx"
                        >
                          <img
                            src={IconFB}
                            className="social-icon social-icon-navbar d-inline-block align-text-top  mx-1"
                            alt="Facebook Dito Pay"
                            width="24px"
                          />
                        </a>
                        <a
                          target="_blank"
                          rel="nofollow noreferrer"
                          href="https://www.linkedin.com/company/ditobanx"
                        >
                          <img
                            src={IconIn}
                            className="social-icon social-icon-navbar d-inline-block align-text-top mx-1"
                            alt="Linkedin Dito Pay"
                            width="24px"
                          />
                        </a>
                        <a
                          target="_blank"
                          rel="nofollow noreferrer"
                          href="https://twitter.com/ditobanx"
                        >
                          <img
                            src={IconTwt}
                            className="social-icon social-icon-navbar d-inline-block align-text-top mx-1"
                            alt="Twitter Dito Pay"
                            width="24px"
                          />
                        </a>
                        <a
                          target="_blank"
                          rel="nofollow noreferrer"
                          href="https://www.instagram.com/ditobanx/"
                        >
                          <img
                            src={IconInsta}
                            className="social-icon social-icon-navbar d-inline-block align-text-top mx-1"
                            alt="Instagram Dito Pay"
                            width="24px"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <Navbar expand="xl" className="bg-white p-0">
                  <Container expand="xl">
                    <Navbar.Brand href="/">
                      <img
                        src={logoditobanxdark}
                        className="d-inline-block align-text-top logo-ditobanx-navbar"
                        alt="Dito Banx El Salvador"
                        width="170"
                      />
                      ®
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse
                      id="responsive-navbar-nav"
                      className=" d-xl-flex justify-content-center justify-content-xl-between"
                    >
                      {/* <Nav className="me-auto"></Nav> */}
                      <Nav className="xl-auto">
                        <Nav.Link
                          className="f-primary-regular text-center text-md-center"
                          href={'/'}
                        >
                          Inicio
                        </Nav.Link>
                        <NavDropdown
                          className="f-primary-regular text-center mb-3 mb-md-0"
                          title="Servicios"
                          id="collasible-nav-dropdown"
                        >
                          <NavDropdown.Item
                            href={'/procesamiento-de-pagos'}
                            className="text-center text-xl-start"
                          >
                            Procesamiento de pagos empresarial
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            href={'/dito-wallet'}
                            className="text-center text-xl-start"
                          >
                            Dito wallet
                          </NavDropdown.Item>
                         
                            <NavDropdown.Item
                              href={'/compra-bitcoin-usdc'}
                              className="text-center text-xl-start"
                            >
                              Compra Bitcoin y USDC
                            </NavDropdown.Item>
                          </NavDropdown>
                        <NavDropdown
                          className="f-primary-regular text-center mb-3 mb-md-0"
                          title="Noticias"
                          id="collasible-nav-dropdown"
                        >
                          <NavDropdown.Item
                            href={'/noticias-prensa'}
                            className="text-center text-xl-start"
                          >
                            Comunicado de prensa
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            href={'/en-las-noticias'}
                            className="text-center text-xl-start"
                          >
                            En las noticias
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            href={'/articulos'}
                            className="text-center text-xl-start"
                          >
                            Articulos
                          </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                          className="f-primary-regular text-center mb-3 mb-md-0"
                          title="Sobre nosotros"
                          id="collasible-nav-dropdown"
                        >
                          <NavDropdown.Item
                            href={'/nuestra-historia'}
                            className="text-center text-xl-start"
                          >
                            Nuestra historia
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            href={'/equipo-ditobanx'}
                            className="text-center text-xl-start"
                          >
                            Nuestro equipo
                          </NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Link
                          className="f-primary-regular text-center text-md-left"
                          href={'/precio'}
                        >
                          Precio
                        </Nav.Link> */}
                        <Nav.Link
                          className="f-primary-regular text-center text-md-left"
                          href={'/contacto'}
                        >
                          Contáctenos
                        </Nav.Link>
                      </Nav>
                      <div className="d-grid gap-1 gap-lg-0 d-xl-block ">
                        <Button
                          variant="outline-dark"
                          className="navbar-custom px-3 border-2 mx-1 fw-bold btn-giftcard-navbar"
                          rel="nofollow noreferrer"
                          target="_blank"
                          href={'https://tarjetas.ditobanx.com/'}
                        >
                          Canjea tu Gift Card
                        </Button>
                        <Button
                          variant="outline-dark"
                          className="navbar-custom px-3 border-2 mx-1 fw-bold"
                          rel="nofollow noreferrer"
                          target="_blank"
                          href={'https://portal.ditobanx.com/'}
                        >
                          Ingresar
                        </Button>
                        <Button
                          variant="dark"
                          className="navbar-custom px-3 border-2 fw-bold mx-1 text-white"
                          rel="nofollow noreferrer"
                          target="_blank"
                          href={'https://portal.ditobanx.com/register'}
                        >
                          Registrarse
                        </Button>
                        <div className="py-3 d-md-none d-block"></div>
                      </div>
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
              </header>
            </div>
          </>
        );
    }
}

export default NavbarHeader;