import React, { Component } from 'react';
import './boltComunicado.css';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';

/*Import custom Components */
import MailChimp from '../../../../../Componentes/MailChimp/MailChimp';
import SocialShare from '../../../../../Componentes/SocialShare/SocialShare';

/*import assets */
import IconArrow from '../../../../../assets/img/icon-row-right.svg';
// import IconFB from '../../../../../assets/img/ico-social/ico-fb.svg';
// import IconIn from '../../../../../assets/img/ico-social/ico-in.svg';
// import IconTwt from '../../../../../assets/img/ico-social/ico-twt.svg';
// import IconInsta from '../../../../../assets/img/ico-social/ico-insta.svg';

/*Import Images Feed */
import FeaturedImage from '../../../../../assets/img/img-comunicados/boltobserver-ditobanx.png';
import IconBuzon from '../../../../../assets/img/icon-buzon.svg';
import Profile from '../../../../../assets/img/img-comunicados/ceo-ing-guillermo-contreras.jpeg';


export default class BoltComunicado extends Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
            
                <div className='mt-5 pt-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-8 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <a href={'/noticias-prensa'} className='text-decoration-none'>
                                            <img src={IconArrow} alt='' width={22} className='mb-2' />
                                            <span className='px-2 h1-custom'>Comunicados de prensa</span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="Bolt Observer y DitoBanx anuncian alianza para investigar y mejorar los servicios de monitoreo de infraestructura Bitcoin Lightning"
                                            LinkedInTitle="Bolt Observer y DitoBanx anuncian alianza para investigar y mejorar los servicios de monitoreo de infraestructura Bitcoin Lightning"
                                            Size="32px"
                                            className="d-none"

                                        />
                                    </div>
                                </div>
                                <Col className='py-3 col-12 px-2'>
                                    <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                    <span className='mx-2 color-custom-date'>FEBRERO 1, 2023</span>
                                </Col>
                                <Col className="mb-3 mx-2">
                                    <img src={FeaturedImage} className="custom-featured-image img-fluid rounded-3 custom-featured-imageGf" alt='Bolt Observer y DitoBanx anuncian alianza para investigar y mejorar los servicios de monitoreo de infraestructura Bitcoin Lightning' />
                                    <div className='color-custom-date py-1 px-3 border-bottom'>Imagen creada por Team Ditobanx</div>
                                </Col>
                                <Col>
                                    <h1 className='fw-bold lh-1 pb-2 px-2'>Bolt Observer y DitoBanx anuncian alianza para investigar y mejorar los servicios de monitoreo de infraestructura Bitcoin Lightning
                                    </h1>
                                    <span className='fw-bold px-2'>Por DitoBanx</span>
                                </Col>
                                <Col className="py-3 px-2">
                                    {/* <img src={Profile} style={{ width: '18.2rem' }} className="d-block pe-3 text-center mx-auto float-center float-md-start rounded-3 pb-3 pb-md-0" alt='itoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' /> */}
                                    <p className='lh-sm text-justify'>

                                    <strong>Bolt Observer y DitoBanx</strong>, dos líderes en el espacio de Bitcoin, han anunciado su alianza para investigar y mejorar los servicios de administración de infraestructura de <strong>Bitcoin Lightning.</strong>
                                            <br/><br/>
                                            Esta alianza permitirá desarrollar herramientas profesionales de gestión de nodos para que  las empresas de Bitcoin mejoren la eficiencia y la seguridad de su infraestructura. Bolt Observer es una empresa con experiencia en el desarrollo de software especializado para el monitoreo y la gestión de nodos Lightning de Bitcoin con base en Estados Unidos. Por su parte, DitoBanx es una fintech bitcoin para los no bancarizados de Latino America con una amplia gama de servicios financieros. Juntos, estas dos empresas están trabajando para crear una solución integral para la administración y monitoreo de nodos Lightning.
                                            <br/><br/>
                                            La alianza permitirá a los operadores de infraestructura lightning acceder a una amplia gama de herramientas y servicios, incluyendo <strong>monitoreo en tiempo real de la infraestructura,  alertas tempranas de liquidez,  gestión de liquidez y servicios orientados a las necesidades de las empresas.</strong> Además, esta alianza ayudará a mejorar la escalabilidad y la eficiencia de la infraestructura de Bitcoin, lo que se traducirá en una experiencia de usuario más satisfactoria.
                                            <br/><br/>
                                            El despliegue de estos servicios a nivel global es un paso importante para el futuro de Bitcoin y su adopción masiva. Al proporcionar una infraestructura confiable y segura, los operadores de servicios Bitcoin podrán enfocarse en brindar a sus usuarios una experiencia fluida y sin problemas con sus transacciones.
                                            <br/><br/>
                                            <span  style={{fontStyle: 'italic'}}>"Estamos emocionados de trabajar con DitoBanx para investigar y mejorar la administración de infraestructura de Lightning en unos de los países más avanzados del mundo"</span>, dijo el <strong>CEO de Bolt Observer, Aljaz Ceru.</strong> <span  style={{fontStyle: 'italic'}}>"Creemos que esta alianza ayudará a impulsar la adopción de Bitcoin y a mejorar la experiencia de los usuarios en todo el mundo".</span>
                                    
                                        
                                    </p>
                                </Col>
                                
                                <Col>
                                    <h3 className='fs-5'><strong>Acerca de Bolt Observer </strong> </h3>
                                    <p className='lh-sm text-justify'>
                                        Bolt Observer es una empresa tecnológica fundada en 2022 que se dedica a resolver la complejidad asociada a la gestión de nodos Lightning Network y liquidez, permitiendo a las empresas mejorar su experiencia y al ecosistema entero a crecer. 
                                        Sitio web: <a style={{color:'#f7931a'}} href={'https://bolt.observer/'} target="_blank" >https://bolt.observer </a>para más información puede escribir a info@bolt.observer. 

                                    </p>
                                </Col>

                                <Col>
                                    <h3 className='fs-5'><strong>Acerca de DitoBanx</strong> </h3>
                                    <p className='lh-sm text-justify'>
                                        DitoBanx es una empresa regulada fundada en 2022, catalogado como  una fintech Bitcoin para los no bancarizados de Latino America con sede en El Salvador. Entre los productos y servicios que ofrece se encuentran: Wallet Digital con Tarjeta de Débito Internacional , Herramientas de procesamiento de pago para comercios y Tarjetas Raspables de Bitcoin. Sitio Web: <a style={{color:'#f7931a'}} href={'https://ditobanx.com'} target="_blank" > www.ditobanx.com </a> para mas informacion pued escribir a hola@ditobanx.com

                                    </p>
                                </Col>
                                <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                    <Row>
                                        <Col>
                                            <Card.Body className='pt-0'>
                                                <Card.Title className="mb-0 text-center text-md-start">Equipo DitoBanx</Card.Title>
                                                <span className='d-block text-center text-md-start fw-bold'>Noticias</span>
                                                <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                    Presentadora
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                        <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                            <div className="col text-center tex-md-end">
                                                <SocialShare
                                                    PostUrl={urlLocation}
                                                    TwitterTitle="Bolt Observer y DitoBanx anuncian alianza para investigar y mejorar los servicios de monitoreo de infraestructura Bitcoin Lightning"
                                                    LinkedInTitle="Bolt Observer y DitoBanx anuncian alianza para investigar y mejorar los servicios de monitoreo de infraestructura Bitcoin Lightning"
                                                    Size="32px"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='col-12 col-md-3 px-0 px-md-0 ms-0 ms-md-4'>
                                <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0 pt-5'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
