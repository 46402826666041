import React, { Component } from 'react';
import './actDigEspComunicado.css';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';

/*Import custom Components */
import MailChimp from '../../../../../Componentes/MailChimp/MailChimp';
import SocialShare from '../../../../../Componentes/SocialShare/SocialShare';

/*import assets */
import IconArrow from '../../../../../assets/img/icon-row-right.svg';
// import IconFB from '../../../../../assets/img/ico-social/ico-fb.svg';
// import IconIn from '../../../../../assets/img/ico-social/ico-in.svg';
// import IconTwt from '../../../../../assets/img/ico-social/ico-twt.svg';
// import IconInsta from '../../../../../assets/img/ico-social/ico-insta.svg';

/*Import Images Feed */
import FeaturedImage from '../../../../../assets/img/img-comunicados/activosDitoBanx.png';
import IconBuzon from '../../../../../assets/img/icon-buzon.svg';
import Profile from '../../../../../assets/img/img-comunicados/ceo-ing-guillermo-contreras.jpeg';


export default class ActDigEspComunicado extends Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
            
                <div className='mt-5 pt-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-8 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <a href={'/noticias-prensa'} className='text-decoration-none'>
                                            <img src={IconArrow} alt='' width={22} className='mb-2' />
                                            <span className='px-2 h1-custom'>Comunicados de prensa</span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="DitoBanx obtiene licencia de activos digitales en El Salvador, ampliando su oferta de inclusión financiera"
                                            LinkedInTitle="DitoBanx obtiene licencia de activos digitales en El Salvador, ampliando su oferta de inclusión financiera"
                                            Size="32px"
                                            className="d-none"

                                        />
                                    </div>
                                </div>
                                <Col className='py-3 col-12 px-2'>
                                    <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                    <span className='mx-2 color-custom-date'>San Salvador 14th April 2023</span>
                                </Col>
                                <Col className="mb-3 mx-2">
                                    <img src={FeaturedImage} className="custom-featured-image img-fluid rounded-3 custom-featured-imageGf" alt='Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America' />
                                    <div className='color-custom-date py-1 px-3 border-bottom'>Imagen creada por Team Ditobanx</div>
                                </Col>
                                <Col>
                                    <h1 className='fw-bold lh-1 pb-2 px-2'>DitoBanx obtiene licencia de activos digitales en El Salvador, ampliando su oferta de inclusión financiera
                                    </h1>
                                    <span className='fw-bold px-2'>Por DitoBanx</span>
                                </Col>
                                <Col className="py-3 px-2">
                                    {/* <img src={Profile} style={{ width: '18.2rem' }} className="d-block pe-3 text-center mx-auto float-center float-md-start rounded-3 pb-3 pb-md-0" alt='itoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' /> */}
                                    <p className='lh-sm text-justify'>
                                    San Salvador, El Salvador - DitoBanx, una importante empresa de tecnología financiera, anunció hoy que ha obtenido una licencia de activos digitales en El Salvador, lo que le permite ampliar su oferta de inclusión financiera en el país.
                                    <br/><br/>

                                    Recientemente, El Salvador hizo historia al convertirse en el primer país del mundo en reconocer el Bitcoin como moneda de curso legal, y DitoBanx está listo para desempeñar un papel clave en el ecosistema de activos digitales. La licencia permite a la empresa ofrecer servicios de activos digitales a sus clientes, incluyendo la posibilidad de comprar, vender y almacenar criptomonedas.
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>
                                     "Estamos emocionados de anunciar nuestra nueva licencia de activos digitales en El Salvador" </span>, dijo Guillermo Contreras, CEO de DitoBanx. <span  style={{fontStyle: 'italic'}}>"Esta licencia nos permite proporcionar a nuestros clientes acceso a activos digitales y otros servicios financieros, lo que ayuda a promover la inclusión financiera y el crecimiento económico en el país".</span>
                                    
                                     <br/><br/>
                                     La licencia fue otorgada por la Comisión de Activos Digitales (DAC) del gobierno salvadoreño, el organismo regulador financiero. La DAC ha estado a la vanguardia de la regulación del emergente mercado de activos digitales del país, y ha implementado un marco regulatorio claro para garantizar la seguridad y la protección de los consumidores.
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>"DitoBanx tiene un fuerte compromiso con el cumplimiento normativo, y nos complace recibir una licencia de activos digitales",</span> dijo Guillermo Contreras.  <span  style={{fontStyle: 'italic'}}>"Creemos que los activos digitales tienen el potencial de promover la inclusión financiera y el crecimiento económico en El Salvador, y esperamos trabajar con el gobierno para lograr estos objetivos".</span>
                                     <br/><br/>
                                     DitoBanx ha estado a la vanguardia de los esfuerzos de inclusión financiera en El Salvador, ofreciendo una amplia gama de servicios financieros digitales a sus clientes. Con la nueva licencia de activos digitales, la empresa podrá ampliar su oferta, permitiendo que los clientes accedan a los beneficios de los activos digitales y participen en la economía global.
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>"Nos comprometemos a promover la inclusión financiera y el crecimiento económico en El Salvador"</span>, dijo Contreras.<span  style={{fontStyle: 'italic'}}> "Con nuestra nueva licencia de activos digitales, podremos ofrecer a nuestros clientes aún más opciones para gestionar sus finanzas y participar en la economía digital".</span>
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>"Estamos emocionados de brindar más oportunidades a El Salvador", </span>dijo Guillermo Contreras, CEO de DitoBanx.<span  style={{fontStyle: 'italic'}}> "Nuestra plataforma y nuestra billetera digital ofrecerán a los clientes una forma rápida, segura y fácil de gestionar su dinero digital, y también ofrecerá acceso a otros servicios financieros".</span>
                                     <br/><br/>
                                     La adquisición de la licencia de activos digitales en El Salvador es un hito significativo para DitoBanx, y refleja el compromiso continuo de la compañía con la promoción de la inclusión financiera y el crecimiento económico en los mercados emergentes. Con su sólido historial de cumplimiento regulatorio y enfoque en el servicio al cliente, DitoBanx está bien posicionada para convertirse en un jugador líder en el ecosistema de activos digitales en El Salvador y más allá.
                                     <br/><br/>
                                     <strong>SOBRE DITOBANX</strong>
                                     <br/><br/>
                                     <strong>DitoBanx</strong> cuenta con licencia de proveedor de Servicios Bitcoin emitida por el Banco Central de Reserva y Licencia de Proveedor de Servicios de Activos digitales además está supervisada por la superintendencia de bancos y tiene como propósito brindar oportunidades a la población tradicionalmente excluida del sector financiero por medio del uso herramientas y productos innovadores.
                                     <br/><br/>
                                     Su oferta de servicios comprende Wallets Digitales , tarjeta Mastercard, servicios de procesamiento de pagos para comercios,  giftcards raspables de bitcoin, otorgamiento de créditos, servicios de cash in/cash out tercerizados y estructuración de tokenización de activos digitales.
                                     <br/><br/>
                                     DitoBanx está dirigido por un equipo multidisciplinario de empresarios experimentados que, entre ellos, han creado y escalado previamente plataformas basadas en tecnología en mercados emergentes, tienen más de una década de experiencia en la industria de tecnología financiera y también han creado producto en algunos de los principales países del mundo. Está respaldada por fondos de VC internacionales  incluidos Ark Fund de México e inversionistas de la región europea.

                                    
                                    </p>
                                </Col>
                                
            

                              
                                <br/>
                                <Col>
                                   
                                    <p className='lh-sm text-justify'>
                                    Para mayor información:

                                    </p>
                                </Col>
                                <br/>
                                <Col>
                                    Contact:
                                   
                                    <span> Sitio Web</span><br/>
                                    <span>  <a style={{color:'#f7931a'}} href={'https://www.ditobanx.com'} target="_blank" >www.ditobanx.com </a> </span><br/><br/>
                                    <span> Información adicional</span><br/>
                                    <span>  empresas@ditobanx.com</span><br/>
                                    
                                </Col>
                                <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                    <Row>
                                        <Col>
                                            <Card.Body className='pt-0'>
                                                <Card.Title className="mb-0 text-center text-md-start">Equipo DitoBanx</Card.Title>
                                                <span className='d-block text-center text-md-start fw-bold'>Noticias</span>
                                                <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                    Presentadora
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                        <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                            <div className="col text-center tex-md-end">
                                                <SocialShare
                                                    PostUrl={urlLocation}
                                                    TwitterTitle="DitoBanx obtiene licencia de activos digitales en El Salvador, ampliando su oferta de inclusión financiera"
                                                    LinkedInTitle="DitoBanx obtiene licencia de activos digitales en El Salvador, ampliando su oferta de inclusión financiera"
                                                    Size="32px"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='col-12 col-md-3 px-0 px-md-0 ms-0 ms-md-4'>
                                <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0 pt-5'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
