import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import './DitoWallet.css';
import bitcoin from '../../assets/img/ditowallet/DitoBanxBitcoin.png';
import celular from '../../assets/img/ditowallet/phonewallet.png';
import dolarIcon from '../../assets/img/ditowallet/icons/dolarIcon.png';
import facturaIcon from '../../assets/img/ditowallet/icons/facturaIcon.png';
import incomeIcon from '../../assets/img/ditowallet/icons/incomeIcon.png';
import ditomastercardIcon from '../../assets/img/ditowallet/icons/lilcard.png';
import darkBTCIcon from '../../assets/img/ditowallet/icons/darkBTCIcon.png';
import walletIcon from '../../assets/img/ditowallet/icons/walletIcon.png';
import exchangeIcon from '../../assets/img/ditowallet/icons/exchangeIcon.png';
import bankIcon from '../../assets/img/ditowallet/icons/bankIcon.png';
import mastercardCircles from '../../assets/img/ditowallet/Mastercard-circles.png';
import cards from '../../assets/img/ditowallet/mastercardr.png';
import lockIcon from '../../assets/img/ditowallet/icons/lockIcon.png';
import phoneIcon from '../../assets/img/ditowallet/icons/phoneIcon.png';
import settingsIcon from '../../assets/img/ditowallet/icons/settingsIcon.png';
import worldIcon from '../../assets/img/ditowallet/icons/world.png';
import percentIcon from '../../assets/img/ditowallet/icons/percentIcon.png';
import handPhone from '../../assets/img/ditowallet/handlePhoneCrop.png';
import handPhoneBGV2 from '../../assets/img/ditowallet/BG-phone-hand.png';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import appStore from '../../assets/img/ditowallet/Appstore-ditopay.png'
import playStore from '../../assets/img/ditowallet/Playstore-ditopay.png'

export const DitoWallet = () => {

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
      // if not a hash link, scroll to top
      if (hash === '') {
        window.scrollTo(0, 0);
      }
      // else scroll to id
      else {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
      }
    }, [pathname, hash, key]);

    const form = useRef()

    const initialForm = {
        email: '',
        to_email: process.env.REACT_APP_EMAILJS_TO_EMAIL,
        cc_email: process.env.REACT_APP_EMAILJS_CC_EMAIL,
    }

    const [values, setValues] = useState(initialForm);

    const {email, to_email, cc_email } = values;

    const reset = (newFormState = {}) => {
        setValues(newFormState);
    }

    const handleInputChange = ({target}) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    }

    const sendEmail = (e) => {
        e.preventDefault();
        console.log('servideId: ', )

        if (email === '' || to_email === '' || cc_email === '' || to_email !== process.env.REACT_APP_EMAILJS_TO_EMAIL || cc_email !== process.env.REACT_APP_EMAILJS_CC_EMAIL) {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Campos incompletos',
                iconColor: '#F79327',
                text: 'Por favor complete todos los campos',
                showConfirmButton: false,
                timer: 2500
            });
            return;
        } else {
          console.log('form: ', form.current)
          console.log('form: ', email)
            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, values, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
            .then((result) => {
                console.log(result.text);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: '¡Gracias!',
                    text: 'Gracias por contactarnos, pronto nos comunicaremos con usted',
                    showConfirmButton: false,
                    timer: 3500
                })
            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: '¡Error!',
                    text: 'Ha ocurrido un error, por favor intente nuevamente',
                    showConfirmButton: false,
                    timer: 2500
                })
            });
            reset(initialForm);
        }
    }


  return (
    <>
      <Helmet>
        <title>Dito wallet | DitoBanx</title>
      </Helmet>
      <div className="mt-5 pt-5 pt-md-4 mb-5">
        <Row className="row-banner-bg" id="dito-wallet">
          <Col xs={12} md={6} className="row-container-title">
            <div className="container-title">
              <h1 className="h1-text-wallet animate__animated animate__fadeInDownBig">
                Dito Wallet{' '}
                <img
                  src={bitcoin}
                  className="img-bitcoin animate__animated animate__fadeInUpBig"
                  alt="bitcoin"
                />
              </h1>
              <div className="title-separator-wallet animate__animated animate__fadeInLeftBig"></div>
              <h1 className="h1-text-wallet-prox animate__animated animate__fadeInDownBig">
                DESCARGALA YA!
              </h1>
              <h1 className="h1-text-wallet-description animate__animated animate__fadeInDownBig">
                Recibe y envía pagos en bitcoin On-Chain, vía lightning o USDC
              </h1>
              <br />
              <div className="row ">
                  <div className="col-6 "><a href="https://play.google.com/store/apps/details?id=com.dito.ditowallet"> <img className='w-100 h-100' src={playStore} alt="Disponible en playstore" /> </a></div>
                  <div className="col-6 "><a href="https://apps.apple.com/sv/app/ditobanx-wallet-personas/id6446959527"> <img className='w-100 h-100' src={appStore} alt="Disponible en appstore" /> </a></div>
              </div>
            {/*   <form
                onSubmit={sendEmail}
                ref={form}
                encType="multipart/form-data"
              >
                <div className="container-form-wallet">
                  <div className="container-input-form-wallet">
                    <Form.Label
                      htmlFor="email"
                      className="label-form-wallet bold-wallet"
                    ></Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      placeholder="ejemplo@ejemplo.com"
                      onChange={handleInputChange}
                      aria-describedby="emailHelp"
                      className="input-form-wallet"
                    />
                    <button className="btn-form-wallet" type="submit">
                      ENVIAR
                    </button>
                  </div>
                </div>
              </form> */}
            </div>
          </Col>
          <Col xs={0} md={6}></Col>
        </Row>
      </div>
      <Container className="container-white-phone">
        <Row>
          <Col className="mt-5 text-align-center">
            <h1 className="title-black">BENEFICIOS</h1>
          </Col>
        </Row>
        <Row
          className="mt-1 mb-5 remove-margin-padding-RL"
          style={{ justifyContent: 'center' }}
        >
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 3, order: 1 }}
            sm={{ span: 3, order: 1 }}
            className="remove-margin-padding-RL col-phone-white-right"
          >
            <div className="col-container-phone-white-right">
              <div className="col-phone-white-right-text mt-5">
                <img src={dolarIcon} alt="dolarIcon" />
                <div className="div-p-text-wallet">
                  <p className="mt-2 mb-2 p-text-wallet">
                    Retira y deposita en efectivo en nuestros corresponsales.
                  </p>
                </div>
              </div>
              <div className="mt-5 col-phone-white-right-text">
                <img src={facturaIcon} alt="facturaIcon" />
                <div className="div-p-text-wallet">
                  <p className="mt-2 mb-2 p-text-wallet">
                    Efectúa el pago de facturas y servicios.{' '}
                  </p>
                </div>
              </div>
              <div className="mt-5 col-phone-white-right-text">
                <img src={ditomastercardIcon} alt="ditomastercardIcon" />
                <div className="div-p-text-wallet">
                  {/* <p className="mt-2 mb-2 p-text-wallet">
                    Obtén tu tarjeta virtual  sin costo.
                  </p> */}
                  <p className="mt-2 mb-2 p-text-wallet">
                    Obtén tu tarjeta virtual Master Card sin costo.
                  </p>
                </div>
              </div>
              <div className="mt-5 col-phone-white-right-text">
                <img src={incomeIcon} alt="incomeIcon" />
                <div className="div-p-text-wallet">
                  <p className="mt-2 mb-2 p-text-wallet">
                    Lineas de creditos especiales para emprendedores.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 6, order: 2 }}
            sm={{ span: 6, order: 2 }}
            className="text-align-center remove-margin-padding-RL"
            style={{ zIndex: '-1', marginLeft: '-4%', marginRight: '-4%' }}
          >
            <img
              className="img-phone-white img-fluid"
              src={celular}
              alt="celular"
            />
          </Col>
          <Col
            xs={{ span: 12, order: 3 }}
            md={{ span: 3, order: 3 }}
            sm={{ span: 3, order: 3 }}
            className="remove-margin-padding-RL col-phone-white-left"
          >
            <div className="col-container-phone-white-left">
              <div className="col-phone-white-left-text mt-5">
                <img src={darkBTCIcon} alt="darkBTCIcon" />
                <div className="div-p-text-wallet">
                  <p className="mt-2 mb-2 p-text-wallet">
                    Envía y recibe pagos en bitcoin On-Chain, vía Lightning o
                    USDC.
                  </p>
                </div>
              </div>
              <div className="mt-5 col-phone-white-left-text">
                <img src={walletIcon} alt="walletIcon" />
                <div className="div-p-text-wallet">
                  <p className="mt-2 mb-2 p-text-wallet">
                    Mantén tus saldos en bitcoin y USDC.
                  </p>
                </div>
              </div>
              <div className="mt-5 col-phone-white-left-text">
                <img src={exchangeIcon} alt="exchangeIcon" />
                <div className="div-p-text-wallet">
                  <p className="mt-2 mb-2 p-text-wallet">
                    Recibe tus pagos con conversión automática a Dólares.
                  </p>
                </div>
              </div>
              <div className="mt-5 col-phone-white-left-text">
                <img src={bankIcon} alt="bankIcon" />
                <div className="div-p-text-wallet">
                  <p className="mt-2 mb-2 p-text-wallet">
                    Envía tus saldos a tu cuenta bancaria.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="container-mastercard-bg">
        <Row className="remove-margin-padding-RL" id="tarjeta-prepagada">
          <Col
            xs={1}
            md={2}
            className="remove-margin-padding-RL"
            style={{
              marginTop: '0',
              marginBottom: '0',
              paddingTop: '0',
              paddingBottom: '0',
            }}
          ></Col>
          <Col
            xs={10}
            md={8}
            className="remove-margin-padding-RL"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: '1',
            }}
          >
            {/* <h1 className="h1-text-mastercard">
              Recibe tu tarjeta virtual <br />
              instantáneamente y sin costo.
            </h1> */}
            <h1 className="h1-text-mastercard">
              Recibe tu tarjeta virtual Master Card <br />
              instantáneamente y sin costo.
            </h1>
          </Col>
          <Col
            xs={1}
            md={2}
            className="remove-margin-padding-RL"
            style={{
              marginTop: '0',
              marginBottom: '0',
              paddingTop: '0',
              paddingBottom: '0',
            }}
          >
            <img
              style={{ float: 'right', zIndex: '0' }}
              className="img-circle-mastercard"
              src={mastercardCircles}
              alt="circles"
            />
          </Col>
        </Row>
      </div>
      <div>
        <Row className="mt-5 mb-5 remove-margin-padding-RL">
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 7, order: 1 }}
            sm={{ span: 7, order: 1 }}
          >
            <div className="container-beneficios-list">
              <h1 className="title-black">BENEFICIOS</h1>
              <div className="container-icons-dark-beneficios">
                <img
                  src={worldIcon}
                  alt="worldIcon"
                  className="icons-dark-beneficios"
                />
                <h2 className="bold-wallet">Tarjeta internacional</h2>
              </div>
              <h3>Aceptada en millones de comercios al rededor del mundo.</h3>

              <div className="container-icons-dark-beneficios">
                <img
                  src={percentIcon}
                  alt="percentIcon"
                  className="icons-dark-beneficios"
                />
                <h2 className="bold-wallet">Descuentos y promociones</h2>
              </div>
              <h3>Accede a cupones, descuentos y promociones exclusivas.</h3>

              <div className="container-icons-dark-beneficios">
                <img
                  src={phoneIcon}
                  alt="phoneIcon"
                  className="icons-dark-beneficios"
                />
                <h2 className="bold-wallet">Olvídate de las filas</h2>
              </div>
              <h3>
                Únicamente necesitas tu documento de identificación y un numero
                telefónico para obtenerla.{' '}
              </h3>

              <div className="container-icons-dark-beneficios">
                <img
                  src={lockIcon}
                  alt="lockIcon"
                  className="icons-dark-beneficios"
                />
                <h2 className="bold-wallet">Seguridad</h2>
              </div>
              <h3>
                Recibe notificaciones y correo electrónico cuando efectúas una
                compra.
              </h3>

              <div className="container-icons-dark-beneficios">
                <img
                  src={settingsIcon}
                  alt="settingsIcon"
                  className="icons-dark-beneficios"
                />
                <h2 className="bold-wallet">Administración</h2>
              </div>
              <h3>
                Tu decides el saldo disponible en tu tarjeta en todo momento.{' '}
              </h3>

              <h2 className="bold-wallet last-h2-beneficios">
                *Tarjeta física disponible con costo adicional.
              </h2>
            </div>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 5, order: 2 }}
            sm={{ span: 5, order: 2 }}
            className="mt-5 mb-5 remove-margin-padding-RL"
            style={{ zIndex: '-1' }}
          >
            <img
              className="img-cards img-fluid animate__animated animate__fadeIn"
              src={cards}
              alt="cards"
            />
          </Col>
        </Row>
      </div>

      {/* <div className="container-form">
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={0} md={0} sm={0} lg={2}></Col>
            <Col xs={12} md={12} sm={12} lg={8}>
              <form onSubmit={sendEmail} ref={form}>
                <div className="container-form-wallet">
                  <h1 className="bold-wallet pt-5">REGISTRATE AHORA</h1>
                  <p className="me-5 ms-5 bold-wallet text-form-wallet">
                    Completa tus datos para conocer los siguientes pasos para
                    obtener tu Dito Wallet.
                  </p>

                  <div className="container-input-form-wallet">
                    <Form.Label
                      htmlFor="name"
                      className="bold-wallet label-form-wallet"
                    >
                      Nombre completo*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleInputChange}
                      aria-describedby="nameHelp"
                      className="input-form-wallet"
                    />
                    <Form.Label
                      htmlFor="email"
                      className="label-form-wallet bold-wallet"
                    >
                      Correo electrónico*
                    </Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                      aria-describedby="emailHelp"
                      className="input-form-wallet"
                    />
                    <Form.Label
                      htmlFor="tel"
                      className="label-form-wallet bold-wallet"
                    >
                      Teléfono celular*
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      id="tel"
                      name="tel"
                      value={tel}
                      onChange={handleInputChange}
                      aria-describedby="telHelp"
                      className="input-form-wallet"
                    />
                    <Form.Label
                      htmlFor="pais"
                      className="label-form-wallet bold-wallet"
                    >
                      País*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="pais"
                      name="pais"
                      value={pais}
                      onChange={handleInputChange}
                      aria-describedby="paisHelp"
                      className="input-form-wallet"
                    />
                    <div>
                      <input
                        type="hidden"
                        name="to_email"
                        value={to_email}
                        onChange={handleInputChange}
                      />
                      <input
                        type="hidden"
                        name="cc_email"
                        value={cc_email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <p className="mt-5 bold-wallet text-form-wallet">
                    *Todos tus datos ingresados se manejarán de manera
                    confidencial y segura.
                  </p>
                  <button className="btn-form-wallet mt-5 mb-5" type="submit">
                    ENVIAR
                  </button>
                </div>
              </form>
            </Col>
            <Col xs={0} md={0} sm={0} lg={2}></Col>
          </Row>
        </Container>
      </div> */}

      <div>
        <Row
          className="mt-5 remove-margin-padding-RL"
          style={{ backgroundColor: '#1A1A1A' }}
        >
          <Col xs={12} md={12} sm={12} lg={7}>
            <div className="container-handphone">
              <h1>Dito Wallet</h1>

              <div className="col-div-container-phone">
                <h3>
                  Envía, recibe y almacena
                  <img
                    src={bitcoin}
                    style={{
                      maxHeight: '1.5rem',
                      alignItems: 'center',
                      marginBottom: '5px',
                    }}
                    alt="bitoin"
                  />
                </h3>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            md={12}
            sm={12}
            lg={5}
            className="remove-margin-padding-RL bg-handphone"
            style={{
              backgroundImage: `url(${handPhoneBGV2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="container-img-handphone">
              <img className="img-hand img-fluid" src={handPhone} alt="Phone" />
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
export default DitoWallet