import React, { Component } from 'react';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';

/*Import custom Components */
import SocialShare from '../../../Componentes/SocialShare/SocialShare';

/*import assets */
import LogoDito from '../../../assets/img/og-opengraph-v2.png';

/*Import Images Feed */
import FeaturedImage from '../../../assets/img/img-comunicados/Ditobanx, Startup en servicios btc en el salvador.png';
import Profile from '../../../assets/img/img-comunicados/ceo-ing-guillermo-contreras.jpeg';
import Helmet from 'react-helmet';


export default class Historia extends Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
                <Helmet>
                    <title>Historia DitoBanx | Soluciones Empresariales bitcoin</title>
                </Helmet>
                <div className='mt-5 pt-5 pt-md-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-12 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <h1 className='px-2 fw-bold fs-3 lh-1'>Nuestra Historia</h1>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="Historia DitoBanx | Soluciones Empresariales bitcoin"
                                            LinkedInTitle="Historia DitoBanx | Soluciones Empresariales bitcoin"
                                            Size="32px"
                                            className="d-none"

                                        />
                                    </div>
                                </div>
                                <img src={LogoDito} className="d-block d-md-none img-fluid" alt='Historia de DitoBanx' />
                                <Col className="pt-4 pb-3 px-2">
                                    <img src={Profile} style={{ width: '11.5rem' }} className="d-none d-md-block pe-3 text-center mx-auto float-center float-md-start rounded-3 pb-3 pb-md-0" alt='Fundador CEO | Historia DitoBanx' />
                                    <h2 className='fw-bold lh-1 pb-2 px-2 text-center text-md-start fs-3'>¡Gracias por interesarte en nuestra historia!
                                    </h2>
                                    <div className='pe-0 pe-md-4'>
                                        <p className='lh-sm text-justify'>
                                            Queremos contarte como nacimos y en que nos estamos convirtiendo en corto tiempo. Nos hemos dedicado por mas de 15 años al desarrollo
                                            de software de alta transaccionalidad. En 2017 tuvimos la visión de advertir la oportunidad que estaba tocando las puertas del mundo y de El
                                            Salvador. Entendimos que había llegado el momento de la revolución financiera, de la verdadera inclusión, y nos preparamos para la
                                            transformación con un proyecto innovador y disruptivo.
                                        </p>
                                        <p className='lh-sm text-justify'>
                                            Con mucho orgullo <strong>DitoBanx</strong>, se constituyo como el primer startup FINTECH en El Salvador especializado en servicios <strong>Bitcoin</strong>. Cuando
                                            presentamos nuestra idea de negocio a un grupo de inversionistas bitcoiners que visitaron El Salvador, rápidamente captamos su atención y
                                            confianza logrando asi nuestro <a href={'/noticias-prensa/ditoBanx-startup-en-servicios-bitcoin'}> primer levantamiento de fondos de inversión por medio millón de dólares de capital semilla</a>, con una valuación
                                            significativa de más de $3.125 millones para la expansión de nuestras operaciones
                                        </p>
                                        <p className='lh-sm text-justify'>
                                            El levantamiento de fondos de inversión lo obtuvimos en un momento clave en el que El Salvador destaca como el epicentro del <strong>Bitcoin</strong> al
                                            contar con una legislación que permite su curso legal
                                        </p>
                                        <p className='lh-sm text-justify'>
                                            Toda la familia de <strong>DitoBanx</strong>, aquellos que han creído en nosotros y a quienes también les ha emocionado nuestra idea de negocio, estamos
                                            orgullosos que esta primera ronda de capital semilla nos permita sentar las bases corporativas de una organización que está pensada para
                                            expandirse muy rápidamente y nos permite tener el empuje inicial de la visión que perseguimos de ser líderes en el mercado, ofreciendo
                                            productos digitales de calidad que conecten a miles de personas a nuevas oportunidades y servicios de inclusión financiera basada en <strong>Bitcoin</strong>
                                        </p>
                                    </div>
                                    <img src={Profile} style={{ width: '11.5rem' }} className="d-block d-md-none pe-3 text-center mx-auto float-center float-md-start rounded-3 " alt='Fundador CEO | Historia DitoBanx' />
                                </Col>
                                <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                    <Row>
                                        <Col>
                                            <Card.Body className='pt-0'>
                                                <Card.Title className="mb-0 text-center text-md-start">Guillermo Contreras</Card.Title>
                                                <span className='d-block text-center text-md-start fw-bold'>CEO de DitoBanx</span>
                                                <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                    Presentadora
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                        <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                            <div className="col text-center tex-md-end">
                                                <SocialShare
                                                    PostUrl={urlLocation}
                                                    TwitterTitle="Historia DitoBanx | Soluciones Empresariales bitcoin"
                                                    LinkedInTitle="Historia DitoBanx | Soluciones Empresariales bitcoin"
                                                    Size="32px"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
