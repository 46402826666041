import React from 'react';
import logoditobanxwhite from '../../assets/img/ditobanx-white.svg';
import IconFB from '../../assets/img/ico-facebook.svg';
import IconIn from '../../assets/img/ico-in.svg';
import IconTwt from '../../assets/img/ico-twt.svg';
import IconInsta from '../../assets/img/ico-insta.svg';
import './FooterV2.css';
import { Col, Container, Row } from 'react-bootstrap';

function FooterV2() {
    return (
        <footer className="bg-color pt-4">
            <Container>
                <Row>
                    <Col xs={12} md={4} className='text-center text-lg-start'>
                        <img src={logoditobanxwhite} className="d-inline-block align-text-top img-fluid img-logo-ditobanx mt-2" alt="DitoBanx El Salvador" width="300" />
                        <hr/>
                        <p className="justify-content-center lh-sm justify-content-sm-start link-secondary text-footer-v2">
                        Soluciones empresariales.
                        </p>
                    </Col>
                    <Col xs={12} md={4} className='text-center text-lg-start'>
                        <h3 className="fs-6 mb-3 text-uppercase mt-5">Empresa</h3><hr />
                        <ul className="list-unstyled text-small">
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href={'contacto'}>Contacto</a></li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href={'polica-de-privacidad'}>Políticas de Privacidad</a></li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href={'terminos-y-condiciones'}>Términos y Condiciones </a></li>
                        </ul>
                    </Col>
                    <Col xs={12} md={4} className='text-center text-lg-start'>
                        <h3 className="fs-6 mb-3 text-uppercase mt-5">Siguenos:</h3><hr />
                        <a target="_blank" rel="nofollow noreferrer noopenner" href="https://www.facebook.com/ditobanx">
                            <img src={IconFB} className="social-icon d-inline-block align-text-top mt-2 me-2" alt="Facebook Dito Pay" width="26px" />
                        </a>
                        <a target="_blank" rel="nofollow noreferrer noopenner" href="https://www.linkedin.com/company/ditobanx">
                            <img src={IconIn} className="social-icon d-inline-block align-text-top m-2" alt="Linkedin Dito Pay" width="26px" />
                        </a>
                        <a target="_blank" rel="nofollow noreferrer noopenner" href="https://twitter.com/ditobanx">
                            <img src={IconTwt} className="social-icon d-inline-block align-text-top m-2" alt="Twitter Dito Pay" width="26px" />
                        </a>
                        <a target="_blank" rel="nofollow noreferrer noopenner" href="https://www.instagram.com/ditobanx/">
                            <img src={IconInsta} className="social-icon d-inline-block align-text-top m-2" alt="Instagram Dito Pay" width="26px" />
                        </a>
                    </Col>
                </Row>
                <Row className='border-top border-secondary pb-1 pt-1'>
                    <Col sm={true} className='text-center link-secondary ts-foot'>
                        Institucion supervisada por la Super Intendendia del Sistema Financiero de El Salvador. Para consultas o quejas no resueltas puede contactar a : atencionalusuario@ssf.gob.sv
                    </Col>
                </Row>
                <Row className='border-secondary pb-2 pt-1'>
                    <Col sm={true} className='text-center'>
                    <a className="link-secondary text-decoration-none p-2" href="https://ditobanx.com">DitoBanx ® | 2022 .Todos los derechos reservados</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
export default FooterV2;