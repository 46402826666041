import React, { Component } from 'react';
import './actDigEngComunicado.css';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';

/*Import custom Components */
import MailChimp from '../../../../../Componentes/MailChimp/MailChimp';
import SocialShare from '../../../../../Componentes/SocialShare/SocialShare';

/*import assets */
import IconArrow from '../../../../../assets/img/icon-row-right.svg';
// import IconFB from '../../../../../assets/img/ico-social/ico-fb.svg';
// import IconIn from '../../../../../assets/img/ico-social/ico-in.svg';
// import IconTwt from '../../../../../assets/img/ico-social/ico-twt.svg';
// import IconInsta from '../../../../../assets/img/ico-social/ico-insta.svg';

/*Import Images Feed */
import FeaturedImage from '../../../../../assets/img/img-comunicados/activosDitoBanx.png';
import IconBuzon from '../../../../../assets/img/icon-buzon.svg';
import Profile from '../../../../../assets/img/img-comunicados/ceo-ing-guillermo-contreras.jpeg';


export default class ActDigEngComunicado extends Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
            
                <div className='mt-5 pt-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-8 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <a href={'/noticias-prensa'} className='text-decoration-none'>
                                            <img src={IconArrow} alt='' width={22} className='mb-2' />
                                            <span className='px-2 h1-custom'>Comunicados de prensa</span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="DitoBanx Obtains Digital Asset License in El Salvador, Expanding Financial Inclusion Offerings"
                                            LinkedInTitle="DitoBanx Obtains Digital Asset License in El Salvador, Expanding Financial Inclusion Offerings"
                                            Size="32px"
                                            className="d-none"

                                        />
                                    </div>
                                </div>
                                <Col className='py-3 col-12 px-2'>
                                    <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                    <span className='mx-2 color-custom-date'>San Salvador 14th April 2023</span>
                                </Col>
                                <Col className="mb-3 mx-2">
                                    <img src={FeaturedImage} className="custom-featured-image img-fluid rounded-3 custom-featured-imageGf" alt='Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America' />
                                    <div className='color-custom-date py-1 px-3 border-bottom'>Imagen creada por Team Ditobanx</div>
                                </Col>
                                <Col>
                                    <h1 className='fw-bold lh-1 pb-2 px-2'>DitoBanx Obtains Digital Asset License in El Salvador, Expanding Financial Inclusion Offerings
                                    </h1>
                                    <span className='fw-bold px-2'>Por DitoBanx</span>
                                </Col>
                                <Col className="py-3 px-2">
                                    {/* <img src={Profile} style={{ width: '18.2rem' }} className="d-block pe-3 text-center mx-auto float-center float-md-start rounded-3 pb-3 pb-md-0" alt='itoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' /> */}
                                    <p className='lh-sm text-justify'>
                                    San Salvador, El Salvador - DitoBanx, a leading financial technology company, announced today that it has obtained a digital asset license in El Salvador, enabling the company to expand its financial inclusion offerings in the country.
                                    <br/><br/>

                                    El Salvador recently made history by becoming the first country in the world to recognize Bitcoin as legal tender, and DitoBanx is poised to play a key role in the digital asset ecosystem. The license enables the company to offer digital asset services to its customers, including the ability to buy, sell, and store cryptocurrencies.
                                    <br/><br/>
                                    <span  style={{fontStyle: 'italic'}}>"We are excited to announce our new digital asset license in El Salvador,"</span> said Guillermo Contreras, CEO of DitoBanx. <span  style={{fontStyle: 'italic'}}><strong>"This license allows us to provide our customers with access to digital assets and other financial services, helping to promote financial inclusion and economic growth in the country."</strong></span>

                                     <br/><br/>
                                     The license was granted by the El Salvadoran government's financial regulatory body, the <strong>Digital Assets Comission (DAC). </strong>The DAC has been at the forefront of regulating the country's emerging digital asset market, and has implemented a clear regulatory framework to ensure the safety and security of consumers.
                                      <br/><br/>
                                      <span  style={{fontStyle: 'italic'}}><strong>“DitoBanx has a strong commitment to regulatory compliance, and we are pleased to receive a digital asset license,"</strong></span> said Guillermo Contreras. <span  style={{fontStyle: 'italic'}}> <strong>"We believe that digital assets have the potential to promote financial inclusion and economic growth in El Salvador, and we look forward to working with the government to achieve these goals."</strong></span>

                                     <br/><br/>
                                    DitoBanx has been at the forefront of financial inclusion efforts in El Salvador, offering a range of digital financial services to its customers. With the new digital asset license, the company will be able to expand its offerings, enabling customers to access the benefits of digital assets and participate in the global economy.
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}><strong>"We are committed to promoting financial inclusion and economic growth in El Salvador,"</strong></span> said Contreras. <span  style={{fontStyle: 'italic'}}><strong>"With our new digital asset license, we will be able to provide our customers with even more options for managing their finances and participating in the digital economy."</strong></span>
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>"We are excited to bring more opportunities to El Salvador," </span>said Guillermo Contreras, CEO of DitoBanx. <span  style={{fontStyle: 'italic'}}>"Our platform and Digital Wallet will offer customers a fast, secure, and user-friendly way to manage their digital money, while also providing access to other financial services."</span>
                                     <br/><br/>
                                     The acquisition of the digital asset license in El Salvador is a significant milestone for DitoBanx, and reflects the company's ongoing commitment to promoting financial inclusion and economic growth in emerging markets. With its strong regulatory compliance record and focus on customer service, DitoBanx is well positioned to become a leading player in the digital asset ecosystem in El Salvador and beyond.
                                     <br/><br/>
                                    <strong>ABOUT DITOBANX</strong>
                                     <br/><br/>
                                     <strong>DitoBanx</strong> is a company characterized as the <span  style={{fontStyle: 'italic'}}><strong>"First Salvadoran fintech for the unbanked"</strong></span> and holds a license as a Bitcoin Service Provider issued by the Central Reserve Bank and a license as a Digital Asset Service Provider. It is also supervised by the banking superintendent and aims to provide opportunities to the population traditionally excluded from the financial sector through the use of innovative tools and products.
                                     <br/><br/>
                                     Its service offering includes Digital Wallets, Mastercard cards, payment processing services for merchants, scratchable bitcoin gift cards, lending, third-party cash in/cash out services, and structuring of digital asset tokenization.
                                      <br/><br/>
                                      DitoBanx is led by a multidisciplinary team of experienced entrepreneurs who have previously created and scaled technology-based platforms in emerging markets, have over a decade of experience in the fintech industry, and have also created products in some of the world's leading countries. It is backed by international VC funds including Ark Fund from Mexico and investors from the European region.

                                   
                                    </p>
                                </Col>
                                
                               

                               
                                <br/>
                                <Col>
                                   
                                    <p className='lh-sm text-justify'>
                                    For more information:

                                    </p>
                                </Col>
                                <br/>
                                <Col>
                                    
                                    <span> Website:</span><br/>
                                    <span>  <a style={{color:'#f7931a'}} href={'https://www.ditobanx.com'} target="_blank" >www.ditobanx.com </a> </span><br/>
                                    <span> Additional information:</span><br/>
                                    <span> empresas@ditobanx.com</span><br/>
                                   
                                    
                                </Col>
                                <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                    <Row>
                                        <Col>
                                            <Card.Body className='pt-0'>
                                                <Card.Title className="mb-0 text-center text-md-start">Equipo DitoBanx</Card.Title>
                                                <span className='d-block text-center text-md-start fw-bold'>Noticias</span>
                                                <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                    Presentadora
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                        <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                            <div className="col text-center tex-md-end">
                                                <SocialShare
                                                    PostUrl={urlLocation}
                                                    TwitterTitle="DitoBanx Obtains Digital Asset License in El Salvador, Expanding Financial Inclusion Offerings"
                                                    LinkedInTitle="DitoBanx Obtains Digital Asset License in El Salvador, Expanding Financial Inclusion Offerings"
                                                    Size="32px"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='col-12 col-md-3 px-0 px-md-0 ms-0 ms-md-4'>
                                <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0 pt-5'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
