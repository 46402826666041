import React, { Component } from 'react';
import './mastercardComunicadoEn.css';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';

/*Import custom Components */
import MailChimp from '../../../../../Componentes/MailChimp/MailChimp';
import SocialShare from '../../../../../Componentes/SocialShare/SocialShare';

/*import assets */
import IconArrow from '../../../../../assets/img/icon-row-right.svg';
// import IconFB from '../../../../../assets/img/ico-social/ico-fb.svg';
// import IconIn from '../../../../../assets/img/ico-social/ico-in.svg';
// import IconTwt from '../../../../../assets/img/ico-social/ico-twt.svg';
// import IconInsta from '../../../../../assets/img/ico-social/ico-insta.svg';

/*Import Images Feed */
import FeaturedImage from '../../../../../assets/img/img-comunicados/ditobanx-mastercard.png';
import IconBuzon from '../../../../../assets/img/icon-buzon.svg';
import Profile from '../../../../../assets/img/img-comunicados/ditobanx-mastercard.png';


export default class MastercardComunicadoEn extends Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
                <div className='mt-5 pt-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-8 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <a href={'/noticias-prensa'} className='text-decoration-none'>
                                            <img  src={IconArrow} alt='' width={22} className='mb-2  '  />
                                            <span className='px-2 h1-custom'>Comunicados de prensa</span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="DitoBanx enhances the financial market in El Salvador and launches a digital wallet that allows consumers to spend their cryptocurrencies"
                                            LinkedInTitle="DitoBanx enhances the financial market in El Salvador and launches a digital wallet that allows consumers to spend their cryptocurrencies"
                                            Size="32px"
                                            className="d-none"

                                        />
                                    </div>
                                </div>
                                <Col className='py-3 col-12 px-2'>
                                    <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                    <span className='mx-2 color-custom-date'>El Salvador,JULY 10, 2023</span>
                                </Col>
                                <Col className="mb-3 mx-2" style={{marginBottom:'4rem'}}>
                                    <img src={FeaturedImage} className="custom-featured-image img-fluid rounded-3 custom-featured-imageGf" alt='DitoBanx and Mastercard' />
                                    
                                </Col>
                                <Col className="mb-3 mx-2">
                                <div className='color-custom-date py-1 px-3 border-bottom'>Image created by Team Ditobanx</div>
                                </Col>
                                
                                <Col>
                                    <h1 className='fw-bold lh-1 pb-2 px-2'>DitoBanx enhances the financial market in El Salvador and launches a digital wallet that allows consumers to spend their cryptocurrencies
                                    </h1>
                                    <span className='fw-bold px-2'>By DitoBanx</span>
                                </Col>
                                <Col className="py-3 px-2">
                                    {/* <img src={Profile} style={{ width: '18.2rem' }} className="d-block pe-3 text-center mx-auto float-center float-md-start rounded-3 pb-3 pb-md-0" alt='itoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' /> */}
                                    <p className='lh-sm text-justify'>
                                    DitoBanx, a Salvadorian Fintech focused on<strong> financial inclusion</strong>, has launched an innovative product for the Salvadorian market: <strong>Dito Wallet, a digital wallet powered by a Mastercard prepaid card.</strong>
                                    <br/><br/>

                                    This new product, available for download on app stores starting in June,<strong> will allow users to sell their Bitcoin and USDC stablecoin holdings and upload the fiat currency into their Mastercard prepaid card</strong>, which can be used at over 100 million acceptance locations worldwide.
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>
                                     The launch of this product will allow Salvadorians to download the app from their smartphone's app store and, after a quick registration process, will immediately provide them with a Mastercard digital prepaid card.<strong> In addition, users will also be able to request a physical card through the app.</strong>
                                     </span>
                                     <br/><br/>
                                     
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>
                                     Guillermo Contreras, DitoBanx CEO, considered this product launch <strong>a massive step towards financial inclusion in the country: "At DitoBanx, we are focused on financial inclusion, and Bitcoin as a key element of this process</strong>, since it allows users to make payments and receive money instantly, from anywhere in the world, at all times. To obtain it, you only need to be of legal age, have an ID and a phone number."</span>
                                     
                                     <span  style={{fontStyle: 'italic'}}>
                                     The digital wallet powered by a Mastercard prepaid card provides Salvadorian users with relevant benefits, as pointed out by Contreras:<strong> "Users can pay their water, electricity, and phone bills, and other 120 services directly through the app, or they can withdraw cash from their balance at an ATM, through a corresponding or a traditional bank account."</strong>
                                     </span>
                                     <br/><br/>
                                     
                                                                   
                                    </p>
                                </Col>
                                
                                <Col>
                                    <h3 className='fs-5'><strong>About DitoBanx</strong> </h3>
                                    <p className='lh-sm text-justify'>
                                    DitoBanx is a company considered the “first Salvadorian Fintech for the unbanked”. It has a Bitcoin Services Provider license issued by the Central Bank, and a digital assets service provider license and is supervised by El Salvador's Financial Superintendency. Its key objective is to open up opportunities for those traditionally excluded from the financial sector through the use of innovative financial products and tools.<br></br>
                                    <br/><br/>
                                    <p className='lh-sm text-justify'>
                                    Its product offering includes Digital Wallets, payment processing services, Bitcoin gift cards, credits, cash in / cash out services and the tokenization of digital assets.
                                    </p>
                                  
                                    <p className='lh-sm text-justify'>DitoBanx is run by a multidisciplinary team of experienced businesspeople who, together, have previously funded and grown technology platforms in emerging markets. They have over a decade of experience in the financial technology industry and have developed products in some of the world’s leading countries. It is backed with funding from international VC companies including Ark Fund in Mexico and investors in the European region. For additional information please visit our website: https://ditobanx.com or through empresas@ditobanx.com.</p>
                                    </p>
                                </Col>

                                <Col>
                                    <h3 className='fs-5'><strong>About Mastercard (NYSE: MA)</strong> </h3>
                                    <p className='lh-sm text-justify'>
                                    Mastercard is a global technology company in the payments industry. Our mission is to connect and power an inclusive, digital economy that benefits everyone, everywhere by making transactions safe, simple, smart and accessible. Using secure data and networks, partnerships and passion, our innovations and solutions help individuals, financial institutions, governments and businesses realize their greatest potential. With connections across more than 210 countries and territories, we are building a sustainable world that unlocks priceless possibilities for all. 
                                    </p>
                                </Col>
                                <br/>
                                <Col>
                                   
                                    <p className='lh-sm text-justify'>
                                    For more information about DitoBanx and Mastercard, please visit their respective websites.” 
                                    
                                    
                                    </p>
                                </Col>
                                <br/>
                                <Col>
                                    Contact:
                                    <span> Michael Miebach, CEO</span><br/>
                                    <span> Mastercard Networks</span><br/>
                                    <span>  <a style={{color:'#f7931a'}} href={' www.mastercard.com/'} target="_blank" > www.mastercard.com/ </a>https://b2b.mastercard.com/contact-us/</span><br/><br/>
                                    <span> Guillermo Contreras, CEO</span><br/>
                                    <span> DitoBanx</span><br/>
                                    <span>  <a style={{color:'#f7931a'}} href={'https://www.ditobanx.com'} target="_blank" >www.ditobanx.com </a> hola@ditobanx.com</span><br/>
                                    
                                </Col>
                                <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                    <Row>
                                        <Col>
                                            <Card.Body className='pt-0'>
                                                <Card.Title className="mb-0 text-center text-md-start">Equipo DitoBanx</Card.Title>
                                                <span className='d-block text-center text-md-start fw-bold'>Noticias</span>
                                                <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                    Presentadora
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                        <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                            <div className="col text-center tex-md-end">
                                                <SocialShare
                                                    PostUrl={urlLocation}
                                                    TwitterTitle="DitoBanx enhances the financial market in El Salvador and launches a digital wallet that allows consumers to spend their cryptocurrencies"
                                                    LinkedInTitle="DitoBanx enhances the financial market in El Salvador and launches a digital wallet that allows consumers to spend their cryptocurrencies"
                                                    Size="32px"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='col-12 col-md-3 px-0 px-md-0 ms-0 ms-md-4'>
                                <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0 pt-5'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
