import React, { Component } from 'react'
/*Import Components Boostrap */
import { Col, Row, Button, Card } from 'react-bootstrap';
import {Link} from "react-router-dom";

/*Import Custom Components */
import SocialShare from '../SocialShare/SocialShare'
import './PostCard.css'

/*Import Assets */
// import imagen1 from '../../assets/img/bitcoinss2021.jpg';


export default class PostCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FeaturedImage: '',
            Category: '',
            Date: '',
            Titulo: '',
            Author: '',
            Puesto: ''
        }
    }
    render() {
        const urlLocation = window.location.href;
        const urlShareFacebook = urlLocation + '/' + this.props.Slug;
        return (
            <>
                <Card className='vertical-align-custom2 rounded-3 shadow-sm mb-4 mb-md-5 bg-body border-0'>
                    <Link
                        to={"/articulos/" + this.props.Slug} className='text-decoration-none'>
                        <img src={this.props.FeaturedImage} className="custom-featured-image img-fluid rounded-3" alt={this.props.Titulo} />
                    </Link>
                    <Card.Body className='pb-0'>
                        <Row className='d-flex flex-column'>
                            <Col className='lh-1 text-start col-md-12'>
                                <Button variant="outline-dark rounded-pill fw-bold border-2 date-h3-fs-custom px-2 py-0">{this.props.Category}</Button>
                                <span className='mx-2 color-custom-date date-h3-fs-custom'>{this.props.Date}</span>
                                <Link
                                    to={"/articulos/" + this.props.Slug} className='text-decoration-none'>
                                    <h2 className='fw-bold lh-1 mt-2 pb-2 h3-fs-custom custom-hv-link'>{this.props.Titulo}</h2>
                                </Link>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Row className='py-2 px-1 border-0 col-12 bg-gray' style={{ backgroundColor: '#f5f5f5' }}>
                        <Col className='d-none d-md-block'>
                            <Card.Body className='p-0 fs-h3-custom-author'>
                                Publicado por:
                                <Card.Title className="mb-0 text-md-start fs-h3-custom-author fw-bold lh-1">{this.props.Author}</Card.Title>
                                <span className='d-block text-center text-md-start speciality fs-h3-custom-author'>{this.props.Puesto}</span>
                            </Card.Body>
                        </Col>
                        <Col className='col-12 col-md-4 vertical-align-custom p-0'>
                            <div className="col text-center tex-md-end">
                                <SocialShare
                                    PostUrl={urlShareFacebook}
                                    TwitterTitle={this.props.Titulo}
                                    LinkedInTitle={this.props.TituloLinkedin}
                                    Size="24px"
                                    fontSize="fs-14px"
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </>
        )
    }
}
