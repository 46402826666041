import React, { useEffect, useState } from "react";
import { RampInstantSDK } from "@ramp-network/ramp-instant-sdk";
import "./CompraBitcoin.css";
import Helmet from 'react-helmet';
import Logo from '../../assets/img/ditobanx-dark.png'



const CompraBitcoin = () => {



  useEffect(() => {
    function initializationRampInstantSDK(variant) {
      let rampInstantSDK = new RampInstantSDK({
        hostAppName: "DitoBanx",
        hostLogoUrl: Logo,
        hostApiKey: "ywms89e7c66g7wpfo84huaezdkhkno8kmk2zeujj",
        variant: variant,
        containerNode: document.getElementById(variant),
        useSendCryptoCallback: true,
      });

      rampInstantSDK.on("SEND_CRYPTO", (event) => {
        const { assetInfo, amount, address } = event.payload;

        const sendCryptoResultEvent = {
          eventVersion: 1,
          type: "SEND_CRYPTO_RESULT",
          payload: {
            txHash: "0x0000....",
          },
        };
        rampInstantSDK.onSendCrypto(sendCryptoResultEvent);
      });
      rampInstantSDK.show();

    }

    if(window.innerWidth < 768){

      const renderMobile = initializationRampInstantSDK("embedded-mobile"); 
    }else{
      const renderDesktop = initializationRampInstantSDK("embedded-desktop");
    }

  }, []);

  if (document.getElementById("ramp-container"))
    console.log(document.getElementById("ramp-container"));
 
  return (
    <>
    <Helmet>
      <title>Compra bitcoin | DitoBanx</title>
    </Helmet>
      <br />
      <div className='container-fluid bg-dark container-md mt-5 pb-5'>
        <div className='row justify-content-center mt-5'>
          <div className='col-11 col-sm-12 col-md-8 col-lg-6 mt-5'></div>
          <div id='embedded-mobile' className='embedded-mobile pb-5 mb-5'></div>
          <div id='embedded-desktop' className='embedded-desktop'>

          </div>
        </div>
      </div>
    </>
  );
};


export default CompraBitcoin; 