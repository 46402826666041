import React from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'react-bootstrap';
import './Inicio.css';
import celular from '../../assets/img/home/Celular nueva era financiera.png';
import circleWoman from '../../assets/img/home/CircleMujer.png';
import bitcoin from '../../assets/img/home/bitcoin.png';
import bitcoinWallet from '../../assets/img/home/bitcoinWallet.png';
import celularWallet from '../../assets/img/ditowallet/phonewallet.png';
import googlePlay from '../../assets/img/home/googleplay.png';
import appStore from '../../assets/img/home/appstore.png';
import tarjetas from '../../assets/img/ditowallet/mastercardr.png';
// import tarjetas from '../../assets/img/home/Tarjetadedebito.png'
import giftCard from '../../assets/img/home/GiftCardBitcoin.png';
import appPago from '../../assets/img/home/appPago.png';
import iconHandButton from '../../assets/img/home/icons/iconHandButton.png';
import iconEmail from '../../assets/img/home/icons/iconEmail.png';
import iconEcommerce from '../../assets/img/home/icons/iconEcommerce.png';
import iconLink from '../../assets/img/home/icons/iconLink.png';
// import aliados from '../../assets/img/home/aliadosImagen.png';
import { useHistory } from 'react-router-dom';
import SlideClientes from '../../Componentes/SlideClientes/SlideClientes';

import img1 from '../../assets/img/home/1.png'; 
import img2 from '../../assets/img/home/2.png'; 
import img3 from '../../assets/img/home/3.png'; 
import img4 from '../../assets/img/home/4.png'; 
import img5 from '../../assets/img/home/5.png'; 
import img6 from '../../assets/img/home/6.png'; 
import img7 from '../../assets/img/home/7.png'; 
import img8 from '../../assets/img/home/8.png'; 
import img9 from '../../assets/img/home/9.png'; 
import img10 from '../../assets/img/home/10.png'; 
import img11 from '../../assets/img/home/11.png'; 
import img12 from '../../assets/img/home/12.png'; 
import img13 from '../../assets/img/home/13.png'; 
import img14 from '../../assets/img/home/14.png'; 
import img15 from '../../assets/img/home/15.png'; 
import img16 from '../../assets/img/home/16.png'; 
import img17 from '../../assets/img/home/17.png'; 
import img18 from '../../assets/img/home/18.png'; 
import img19 from '../../assets/img/home/19.png'; 
import img20 from '../../assets/img/home/20.png'; 
import img21 from '../../assets/img/home/21.png'; 
import img22 from '../../assets/img/home/22.png'; 
import img23 from '../../assets/img/home/23.png'; 
import img24 from '../../assets/img/home/24.png'; 
import img25 from '../../assets/img/home/25.png'; 
import img26 from '../../assets/img/home/26.png'; 
import img27 from '../../assets/img/home/27.png'; 
import img28 from '../../assets/img/home/28.png'; 
import img29 from '../../assets/img/home/29.png'; 
import img30 from '../../assets/img/home/30.png'; 
import img31 from '../../assets/img/home/31.png'; 
import img32 from '../../assets/img/home/32.png'; 
import img33 from '../../assets/img/home/33.png'; 
import img34 from '../../assets/img/home/34.png'; 
import img35 from '../../assets/img/home/35.png'; 
import img36 from '../../assets/img/home/36.png'; 
import img37 from '../../assets/img/home/37.png'; 
import img38 from '../../assets/img/home/38.png'; 
import img39 from '../../assets/img/home/39.png'; 
import img40 from '../../assets/img/home/40.png'; 
import img41 from '../../assets/img/home/41.png'; 
import img42 from '../../assets/img/home/42.png';  
import img43 from '../../assets/img/home/43.png'; 
import img44 from '../../assets/img/home/44.png'; 
import img45 from '../../assets/img/home/45.png'; 
import img46 from '../../assets/img/home/46.png'; 
import img47 from '../../assets/img/home/47.png'; 
import img48 from '../../assets/img/home/48.png'; 
import img49 from '../../assets/img/home/49.png'; 
import img50 from '../../assets/img/home/50.png'; 
import img51 from '../../assets/img/home/51.png'; 
import img52 from '../../assets/img/home/52.png'; 
import img53 from '../../assets/img/home/53.png'; 
import img54 from '../../assets/img/home/54.png'; 
import img55 from '../../assets/img/home/55.png'; 
import img56 from '../../assets/img/home/56.png'; 
import img57 from '../../assets/img/home/57.png'; 
import img58 from '../../assets/img/home/58.png'; 
import img59 from '../../assets/img/home/59.png'; 
import img60 from '../../assets/img/home/60.png'; 
import img61 from '../../assets/img/home/61.png'; 
import img62 from '../../assets/img/home/62.png'; 
import img63 from '../../assets/img/home/63.png'; 
import img64 from '../../assets/img/home/64.png'; 
import img65 from '../../assets/img/home/65.png'; 
import img66 from '../../assets/img/home/66.png'; 
import mastercard from '../../assets/img/ditowallet/mastercardln.png'

import AOS from 'aos';
import 'aos/dist/aos.css';


export const Inicio = () => {

    const DataClientes = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22];
    const DataClientes2 = [img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img39, img40, img41, img42, img43, img44];
    const DataClientes3 = [img45, img46, img47, img48, img49, img50, img51, img52, img53, img54, img55, img56, img57, img58, img59, img60, img61, img62, img63, img64, img65, img66];

    let history = useHistory();
    AOS.init({
        once: true,
        // duration: 1000,
    });

    return (
      <>
        <Helmet>
          <title>DitoBanx | Soluciones Bitcoin para Empresas</title>
        </Helmet>

        {/* banner */}
        <div className="mt-5 container-banner-home">
          <Row className="row-banner-bg-home">
            <Col xs={12} md={8} data-aos="fade-right">
              <div className="container-title-home">
                <h1 className="h1-title-home ">
                  El <strike style={{ color: 'red' }}>futuro </strike> <br />
                  presente del dinero
                </h1>
                <p className="p-subtitle-home ">
                  Servicios financieros digitales para el acceso al crédito y la
                  inclusión financiera.
                </p>
              </div>
            </Col>
            <Col xs={12} md={4} data-aos="fade-down">
              <div className="div-img-celular-home">
                <img src={celular} alt="celular" className="img-celular-home" />
              </div>
            </Col>
          </Row>
          <img src={mastercard} alt="mastercard" className="mastercard-img" />
        </div>

        {/* Ditowallet */}
        <div className="row-ditowallet-home">
          <Container className="">
            <Row>
              <Col xs={{ span: 12, order: 2 }} md={{ span: 5, order: 1 }}>
                <div data-aos="zoom-in">
                  <img
                    src={celularWallet}
                    alt="celular"
                    className="img-celular-ditowallet"
                  />
                </div>
              </Col>
              <Col
                xs={{ span: 12, order: 1 }}
                md={{ span: 7, order: 2 }}
                data-aos="zoom-in"
              >
                <div className="container-ditowallet-home">
                  <h1 className="h1-ditowallet-home">
                    Dito Wallet&nbsp;
                    <img
                      src={bitcoinWallet}
                      alt="bitcoin"
                      className="img-bitcoinWallet-home"
                    />
                  </h1>
                  <h4 className="h2-ditowallet-home">
                    Recibe y envía pagos en bitcoin On-Chain, vía Lightning o
                    USDC.
                  </h4>
                  <button
                    className="btn-box-creditos"
                    onClick={() => {
                      history.push('/dito-wallet#dito-wallet')
                    }}
                  >
                    Más información
                  </button>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <a target="_blank" rel="nofollow noreferrer noopenner" href='https://play.google.com/store/apps/details?id=com.dito.ditowallet' >
                            <img src={googlePlay} alt="" className='img-fluid'/> <br /><br />
                        </a>
                    {/* <img
                      src={googlePlay}
                      alt="googlePlay"
                      className="img-stores-mobile"
                    /> */}
                     <a target="_blank" rel="nofollow noreferrer noopenner" href='https://apps.apple.com/sv/app/ditobanx-wallet-personas/id6446959527' >
                            <img src={appStore} alt="" className='img-fluid'/> <br /><br />
                        </a>
                    {/* <img
                      src={appStore}
                      alt="appStore"
                      className="img-stores-mobile" */}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* tarjeta prepago */}
        <div className="container-tarjetaprepago-row">
          <Row className="row-tarjetaprepago-home">
            <Col xs={12} md={12} sm={12} xl={4} style={{ zIndex: '1' }}>
              <div data-aos="fade-right">
                <img
                  src={tarjetas}
                  alt="tarjetas"
                  className="img-tarjetasprepago-home"
                />
              </div>
            </Col>
            <Col xs={12} md={12} sm={12} xl={8}>
              <div className="container-tarjetaprepago-home">
                <h1 className="h1-tarjetaprepado-home">
                  Tarjeta prepagada&nbsp;
                  <img
                    src={bitcoinWallet}
                    alt="bitcoin"
                    className="img-bitcoinprepago-home"
                  />
                </h1>
                <p className="p-tarjetaprepado-home">
                  Obtén tu tarjeta virtual al instante y solicita tu tarjeta
                  física por medio de nuestra wallet.
                </p>
                <div className="div-btn-tarjetaprepago">
                  <button
                    className="btn-box-creditos"
                    onClick={() => {
                      history.push('/dito-wallet#tarjeta-prepagada')
                    }}
                  >
                    Información y beneficios
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* Giftcard */}
        <Container className="container-giftcard-home">
          <Row>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 5, order: 1 }}
              className="col-giftcard-home"
              data-aos="fade-up"
            >
              <div className="div-container-giftcard">
                <h1 className="h1-giftcard-home">
                  Gift Card&nbsp;
                  <img
                    src={bitcoinWallet}
                    alt="bitcoin"
                    className="img-bitcoinGiftcard-home"
                  />
                </h1>
                <p className="p-giftcard-home">
                  Recibe bitcoin al instante en tu billetera.
                </p>
                <a
                  rel="nofollow noreferrer"
                  target="_blank"
                  href={'https://tarjetas.ditobanx.com/'}
                  style={{ textDecoration: 'none' }}
                >
                  <button className="btn-giftcard-home">
                    Canjea tu Gift Card
                  </button>
                </a>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 7, order: 2 }}>
              <div data-aos="fade-down">
                <img
                  src={giftCard}
                  alt="giftcard"
                  className="img-giftcard-home"
                />
              </div>
            </Col>
          </Row>
        </Container>

        {/* pagos */}
        <div className="container-pagos-home">
          <Container>
            <Row>
              <Col
                xs={{ span: 12, order: 2 }}
                md={{ span: 6, order: 1 }}
                className="col-container-pagos-home"
                data-aos="fade-down"
              >
                <div className="div-container-img-appPago">
                  <img
                    src={appPago}
                    alt="appPago"
                    className="img-appPago-home"
                  />
                  <Row>
                    <Col xs={6} md={6}>
                      <p className="p-pagosServices-home">
                        <img
                          src={iconEcommerce}
                          alt="iconEcommerce"
                          className="iconPagosServices-home"
                        />
                        &nbsp;Plugins Ecommerce
                      </p>
                    </Col>
                    <Col xs={6} md={6}>
                      <p className="p-pagosServices-home">
                        {' '}
                        <img
                          src={iconLink}
                          alt="iconLink"
                          className="iconPagosServices-home"
                        />
                        &nbsp;Enlace de pago
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6}>
                      <p className="p-pagosServices-home">
                        {' '}
                        <img
                          src={iconEmail}
                          alt="iconEmail"
                          className="iconPagosServices-home"
                        />
                        &nbsp;Email de pago
                      </p>
                    </Col>
                    <Col xs={6} md={6}>
                      <p className="p-pagosServices-home">
                        <img
                          src={iconHandButton}
                          alt="iconHandButton"
                          className="iconPagosServices-home"
                        />
                        &nbsp;Botón de pago
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                xs={{ span: 12, order: 1 }}
                md={{ span: 6, order: 2 }}
                className="col-container-pagos-home"
                data-aos="fade-up"
              >
                <div className="div-container-pagos-title">
                  <h1 className="h1-pagos-home">
                    Recepción de pagos empresariales
                  </h1>
                  <p className="p-pagos-home">
                    Recepción de pagos bitcoin con conversión automática a
                    Dólares.
                  </p>
                  <button
                    className="btn-info-white"
                    onClick={() => {
                      history.push('/procesamiento-de-pagos')
                    }}
                  >
                    Más información
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* section creditos */}
        <Container className="container-creditos-home">
          <Row>
            <Col xs={12} md={12} sm={12} xl={5}>
              <div className="div-container-img-woman" data-aos="fade-right">
                <img
                  src={circleWoman}
                  alt="celular"
                  className="img-circle-woman-home "
                />
              </div>
            </Col>
            <Col xs={12} md={12} sm={12} xl={7} data-aos="zoom-in">
              <div className="col-box-creditos">
                <h1 className="h1-creditos-home">Créditos para empresas</h1>
                <div className="container-box-creditos">
                  <div className="box-creditos">
                    <h2 className="title-box-creditos pt-5 pb-3">
                      Créditos para empresas
                    </h2>
                    <p className="p-box-credito">
                      Líneas de créditos tradicionales basadas en flujo de
                      efectivo.
                    </p>
                    <button className="btn-box-creditos mt-4">
                      Más información
                    </button>
                  </div>
                  <div className="box-creditos">
                    <h2 className="title-box-creditos pt-4">
                      Créditos colateralizados con&nbsp;
                      <img
                        src={bitcoin}
                        alt="bitcoin"
                        className="img-bitcoin-home"
                      />
                    </h2>
                    <p className="p-box-credito">
                      Obtén un préstamo poniendo tu bitcoin como garantía.
                    </p>
                    <button
                      className="btn-box-creditos mt-4"
                      onClick={() => {
                        history.push(
                          '/creditos-colaterizados-bitcoin#creditos-colateralizados'
                        )
                      }}
                    >
                      Más información
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {/* aliados */}
        <Container>
          <Row>
            <Col className="container-titleAliados-home">
              <div>
                <h1 className="h1-titleAliados-home">Aliados comerciales</h1>
                <p className="p-titleAliados-home">
                  Empresas y plataformas que ya cuentan con la tecnología de
                  DitoBanx integrada.
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="container-clientes-home">
          <Container>
            <Row>
              <Col className="col-aliados-home">
                <SlideClientes imagens={DataClientes} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="col-aliados-home">
                <SlideClientes imagens={DataClientes2} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="col-aliados-home">
                <SlideClientes imagens={DataClientes3} />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
}



export default Inicio;