import React from 'react';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';

/*Import custom Components */
import MailChimp from '../../../../Componentes/MailChimp/MailChimp';
import SocialShare from '../../../../Componentes/SocialShare/SocialShare';

/*Import Blog Post */
import DataPost from '../Articulos.json'

/*import assets */
import IconArrow from '../../../../assets/img/icon-row-right.svg';

/*Import Images Feed */
// import FeaturedImage from '../../../../assets/img/img-comunicados/Ditobanx, Startup en servicios btc en el salvador.png';
// import Profile from '../../../../assets/img/img-comunicados/ceo-ing-guillermo-contreras.jpeg';
import IconBuzon from '../../../../assets/img/icon-buzon.svg';

const SinglePost = (props) => {
    const urlLocation = window.location.href;
    const PropURL = props.match.params.name;

    /* Filtrar Data de JSON */
    const postData = DataPost.find((p) => p.slugpost === PropURL);
    if (postData === undefined) {
        // mostrar error
    }

    return (
        <>
            <div className='mt-4 mt-md-5 pt-5 pt-md-4'></div>
            <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4 mt-3 mt-md-0'>
                <Container>
                    <Row className='py-4'>
                        <Col className='col-12 col-md-8 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                            <div className="row justify-content-between border-bottom py-3">
                                <div className="col-12 col-md-6 d-flex">
                                    <a href={'/articulos'} className='text-decoration-none'>
                                        <img src={IconArrow} alt='' width={22} className='mb-2' />
                                        <span className='px-2 h1-custom'>Comunicados de prensa</span>
                                    </a>
                                </div>
                                <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                    <SocialShare
                                        PostUrl={urlLocation}
                                        TwitterTitle={postData.titletwitter}
                                        LinkedInTitle={postData.titlelinkedin}
                                        Size="32px"
                                        className="d-none"

                                    />
                                </div>
                            </div>
                            <Col className='py-3 col-12 px-2'>
                                <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">{postData.category}</Button>
                                <span className='mx-2 color-custom-date'>{postData.datepost}</span>
                            </Col>
                            <Col>
                                <h1 className='fw-bold lh-1 pb-2 px-2'>{postData.title}</h1>
                                <span className='fw-bold px-2'>Publicado por {postData.author}</span>
                            </Col>
                            <Col className="py-3 px-2">
                                {postData.metadrescription}
                            </Col>
                            <Col className="mb-3 mx-2">
                                <img src={postData.featuredimg} className="custom-featured-image img-fluid rounded-3" alt={postData.title} />
                                <div className='color-custom-date py-1 px-3 border-bottom'>Imagen creada por {postData.authorfeaturedimg}</div>
                            </Col>
                            <Col className='px-2'>
                                <div dangerouslySetInnerHTML={{ __html: postData.metapost }} />
                            </Col>
                            <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                <Row>
                                    <Col>
                                        <Card.Body className='pt-0'>
                                            <Card.Title className="mb-0 text-center text-md-start">Equipo DitoBanx</Card.Title>
                                            <span className='d-block text-center text-md-start fw-bold'>{postData.puesto}</span>
                                            <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                Presentadora
                                            </Card.Text>
                                        </Card.Body>
                                    </Col>
                                    <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                        <div className="col text-center tex-md-end">
                                            <SocialShare
                                                PostUrl={urlLocation}
                                                TwitterTitle={postData.titletwitter}
                                                LinkedInTitle={postData.titlelinkedin}
                                                Size="32px"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col className='col-12 col-md-3 px-0 px-md-0 ms-0 ms-md-4'>
                            <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0 pt-5'>
                                <img alt='' src={IconBuzon} width={64} />
                                <Card.Body>
                                    <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                    <Card.Text className='lh-1 text-center '>
                                        Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                        <MailChimp />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default SinglePost;

// export default class SinglePost extends Component {
//     render() {
//         const urlLocation = window.location.href;


//         return (
//         )
//     }
// }
