import React, { Component } from 'react';
import './qredoComunicado.css';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';

/*Import custom Components */
import MailChimp from '../../../../../Componentes/MailChimp/MailChimp';
import SocialShare from '../../../../../Componentes/SocialShare/SocialShare';

/*import assets */
import IconArrow from '../../../../../assets/img/icon-row-right.svg';
// import IconFB from '../../../../../assets/img/ico-social/ico-fb.svg';
// import IconIn from '../../../../../assets/img/ico-social/ico-in.svg';
// import IconTwt from '../../../../../assets/img/ico-social/ico-twt.svg';
// import IconInsta from '../../../../../assets/img/ico-social/ico-insta.svg';

/*Import Images Feed */
import FeaturedImage from '../../../../../assets/img/img-comunicados/qredo-ditobanx.jpeg';
import IconBuzon from '../../../../../assets/img/icon-buzon.svg';
import Profile from '../../../../../assets/img/img-comunicados/ceo-ing-guillermo-contreras.jpeg';


export default class QredoComunicado extends Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
            
                <div className='mt-5 pt-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-8 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <a href={'/noticias-prensa'} className='text-decoration-none'>
                                            <img src={IconArrow} alt='' width={22} className='mb-2' />
                                            <span className='px-2 h1-custom'>Comunicados de prensa</span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America"
                                            LinkedInTitle="Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America"
                                            Size="32px"
                                            className="d-none"

                                        />
                                    </div>
                                </div>
                                <Col className='py-3 col-12 px-2'>
                                    <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                    <span className='mx-2 color-custom-date'>San Salvador 5th February 2023</span>
                                </Col>
                                <Col className="mb-3 mx-2">
                                    <img src={FeaturedImage} className="custom-featured-image img-fluid rounded-3 custom-featured-imageGf" alt='Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America' />
                                    <div className='color-custom-date py-1 px-3 border-bottom'>Imagen creada por Team Ditobanx</div>
                                </Col>
                                <Col>
                                    <h1 className='fw-bold lh-1 pb-2 px-2'>Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America
                                    </h1>
                                    <span className='fw-bold px-2'>Por DitoBanx</span>
                                </Col>
                                <Col className="py-3 px-2">
                                    {/* <img src={Profile} style={{ width: '18.2rem' }} className="d-block pe-3 text-center mx-auto float-center float-md-start rounded-3 pb-3 pb-md-0" alt='itoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' /> */}
                                    <p className='lh-sm text-justify'>
                                    Qredo, a leading provider of institutional-grade<strong> digital asset custody solutions</strong>, and DitoBanx, 
                                    a <strong>fintech for the unbanked in Latin America</strong>, today announced a partnership to offer secure and regulated digital asset custody services to banking institutions and fintech companies in the region.
                                    <br/><br/>

                                    The partnership enables DitoBanx to offer its customers financial-grade digital asset custody services using Qredo's decentralized multi-party computing (dMPC) technology.
                                     This technology<strong> provides a secure and regulatory-compliant environment for customers to store, transfer, and manage their digital assets, including Bitcoin</strong>.
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>
                                     "We are thrilled to partner with DitoBanx to bring institutional-grade digital asset custody solutions to the unbanked in Latin America," said Anthony Foy, <strong>CEO of Qredo</strong>. "By leveraging Qredo's cutting-edge dMPC technology, DitoBanx can now offer its customers secure and regulated digital asset services, enabling them to participate in the growing digital economy."
                                     </span>
                                     <br/><br/>
                                     The use of dMPC technology ensures that all digital assets are stored in a secure and decentralized manner, with multiple copies of the data stored in multiple locations to minimize the risk of loss or theft. This level of security is especially important in regions where traditional financial institutions are not widely available, as it provides customers with a secure and reliable way to store their digital assets.
                                     <br/><br/>
                                     <span  style={{fontStyle: 'italic'}}>
                                     "The partnership with Qredo is an exciting step for DitoBanx as we strive to bring financial services to the unbanked in Latin America,"  </span>
                                     
                                     said <strong>Guillermo Contreras, CEO</strong> of DitoBanx. 
                                     <span  style={{fontStyle: 'italic'}}>
                                     "The ability to offer institutional-grade digital asset custody services will be a game-changer for our customers, enabling them to securely store and manage their digital assets in a regulated environment."
                                     </span>
                                     <br/><br/>
                                     In addition to its secure digital asset custody services, DitoBanx offers a range of other financial services to its customers, including Digital Wallets with International debit cards, remittances, bill payments, and microfinance loans. The integration of Qredo's solutions into the DitoBanx ecosystem will allow customers to manage all of their financial assets in one place, providing a seamless and convenient experience.
                                     <br/><br/>



                                   
                                    </p>
                                </Col>
                                
                                <Col>
                                    <h3 className='fs-5'><strong>About Qredo: </strong> </h3>
                                    <p className='lh-sm text-justify'>
                                    Qredo is a leading provider of institutional-grade digital asset custody solutions. The company's decentralized multi-party computing (dMPC) technology provides a secure and regulated environment for customers to store, transfer, and manage their digital assets.
                                        {/* Bolt Observer es una empresa tecnológica fundada en 2022 que se dedica a resolver la complejidad asociada a la gestión de nodos Lightning Network y liquidez, permitiendo a las empresas mejorar su experiencia y al ecosistema entero a crecer. 
                                        Sitio web: <a style={{color:'#f7931a'}} href={'https://bolt.observer/'} target="_blank" >https://bolt.observer </a>para más información puede escribir a info@bolt.observer.  */}

                                    </p>
                                </Col>

                                <Col>
                                    <h3 className='fs-5'><strong>About DitoBanx:</strong> </h3>
                                    <p className='lh-sm text-justify'>
                                    DitoBanx is a company for the unbanked in Latin America. The company provides a range of financial services to its customers, including remittances, bill payments, and microfinance loans, and is committed to empowering those who have been excluded from traditional financial services.
                                    </p>
                                </Col>
                                <br/>
                                <Col>
                                   
                                    <p className='lh-sm text-justify'>
                                    For more information about Qredo Networks and DitoBanx, please visit their respective websites.
                                    
                                    
                                    </p>
                                </Col>
                                <br/>
                                <Col>
                                    Contact:
                                    <span> Anthony Foy, CEO</span><br/>
                                    <span> Qredo Networks</span><br/>
                                    <span>  <a style={{color:'#f7931a'}} href={'https://www.qredo.com'} target="_blank" >www.qredo.com </a> PR@qredo.com</span><br/><br/>
                                    <span> Guillermo Contreras, CEO</span><br/>
                                    <span> DitoBanx</span><br/>
                                    <span>  <a style={{color:'#f7931a'}} href={'https://www.ditobanx.com'} target="_blank" >www.ditobanx.com </a> hola@ditobanx.com</span><br/>
                                    
                                </Col>
                                <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                    <Row>
                                        <Col>
                                            <Card.Body className='pt-0'>
                                                <Card.Title className="mb-0 text-center text-md-start">Equipo DitoBanx</Card.Title>
                                                <span className='d-block text-center text-md-start fw-bold'>Noticias</span>
                                                <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                    Presentadora
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                        <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                            <div className="col text-center tex-md-end">
                                                <SocialShare
                                                    PostUrl={urlLocation}
                                                    TwitterTitle="Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America"
                                                    LinkedInTitle="Qredo and DitoBanx Partner to Offer Institutional-Grade Digital Asset Custody Solutions to the Unbanked in Latin America"
                                                    Size="32px"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='col-12 col-md-3 px-0 px-md-0 ms-0 ms-md-4'>
                                <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0 pt-5'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
