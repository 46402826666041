import React, { Component } from 'react';
import './PlantillaComunicado.css';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';

/*Import custom Components */
import MailChimp from '../../../../../Componentes/MailChimp/MailChimp';
import SocialShare from '../../../../../Componentes/SocialShare/SocialShare';

/*import assets */
import IconArrow from '../../../../../assets/img/icon-row-right.svg';
// import IconFB from '../../../../../assets/img/ico-social/ico-fb.svg';
// import IconIn from '../../../../../assets/img/ico-social/ico-in.svg';
// import IconTwt from '../../../../../assets/img/ico-social/ico-twt.svg';
// import IconInsta from '../../../../../assets/img/ico-social/ico-insta.svg';

/*Import Images Feed */
import FeaturedImage from '../../../../../assets/img/img-comunicados/Ditobanx, Startup en servicios btc en el salvador.png';
import IconBuzon from '../../../../../assets/img/icon-buzon.svg';
import Profile from '../../../../../assets/img/img-comunicados/ceo-ing-guillermo-contreras.jpeg';


export default class PlantillaComunicado extends Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
                <div className='mt-5 pt-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-8 rounded-3 shadow-sm p-3 mb-3 mb-md-5 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <a href={'/noticias-prensa'} className='text-decoration-none'>
                                            <img src={IconArrow} alt='' width={22} className='mb-2' />
                                            <span className='px-2 h1-custom'>Comunicados de prensa</span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="DitoBanx,  startup en servicios Bitcoin,
                                            se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares"
                                            LinkedInTitle="DitoBanx,  startup en servicios Bitcoin,
                                            se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares"
                                            Size="32px"
                                            className="d-none"

                                        />
                                    </div>
                                </div>
                                <Col className='py-3 col-12 px-2'>
                                    <Button variant="outline-dark rounded-pill fw-bold border-2 f-bange-custom">NOTICIAS BITCOIN</Button>
                                    <span className='mx-2 color-custom-date'>ENERO 24, 2022</span>
                                </Col>
                                <Col>
                                    <h1 className='fw-bold lh-1 pb-2 px-2'>DitoBanx,  startup en servicios Bitcoin,
                                        se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares
                                    </h1>
                                    <span className='fw-bold px-2'>Por DitoBanx</span>
                                </Col>
                                <Col className="py-3 px-2">
                                    <img src={Profile} style={{ width: '18.2rem' }} className="d-block pe-3 text-center mx-auto float-center float-md-start rounded-3 pb-3 pb-md-0" alt='itoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' />
                                    <p className='lh-sm text-justify'>
                                        <strong>El Salvador, enero de 2022. DitoBanx</strong> empresa 100% salvadoreña, con tecnología propia y con una matriz única de servicios financieros basados en Bitcoin, obtiene su primer levantamiento de fondos de inversión por medio millón de dólares en ronda de capital semilla, con una valuación significativa de más de $3.125 millones para la expansión de sus operaciones.
                                        <br /><br />
                                        El levantamiento de fondos de inversión se da en momentos en que El Salvador destaca como el epicentro del Bitcoin al contar con una legislación que permite su curso legal.
                                        <br /><br />
                                        “Esta primera ronda de capital semilla nos permite sentar las bases corporativas de una organización que está pensada para expandirse muy rápidamente y nos permite tener el empuje inicial de la visión que perseguimos de ser líderes en el mercado, ofreciendo productos digitales de calidad  que conecten a miles de personas a nuevas oportunidades y servicios de inclusión financiera basada en Bitcoin”,  afirma Guillermo Contreras CEO de <strong>DitoBanx</strong>.
                                        <br /><br />
                                        El inversor ángel de <strong>DitoBanx</strong> cuenta con experiencia de más de 10 años en inversiones relacionadas a Bitcoin principalmente en mercados europeos. “Nuestro inversor ángel vio un compromiso serio en nosotros para poder llevar esta visión de construir la estructura financiera de la próxima generación y llegar a otras latitudes”, comenta Contreras.
                                        <br /><br />
                                        Esta primera inversión es el inicio de un ciclo de levantamiento de capital. En un período relativamente corto de tiempo se proyecta dar paso a la siguiente ronda de inversión, Serie A, que permitirá a <strong>DitoBanx</strong> entrar a mercados con economías de escala como México y Brasil que requieren de fondos bastante significativos. Centroamérica es un mercado importante para <strong>DitoBanx</strong>; sin embargo observa oportunidades más cercanas en economías de mayor tamaño que ya han dado pasos muy certeros y concretos respecto al Bitcoin en Latinoamérica como Colombia, Argentina, México y Brasil. “No perdemos de vista las economías con mayor volumen transaccional alrededor del Bitcoin como Estados Unidos y Canadá”,  explica el ejecutivo de <strong>DitoBanx</strong>.
                                        <br /><br />
                                        En términos de competencia <strong>DitoBanx</strong> visualiza un ecosistema que está naciendo muy saludable y con propuestas de valor y servicios innovadores. “La comunidad Bitcoin se caracteriza por ser muy abierta y colaborativa, hemos apoyado y guiado a otras empresas para que sigan nuestro camino y lo hacemos con total satisfacción. Hoy los cambios ya no toman 10 años si no que al contrario, la sociedad hiperconectada en la que vivimos permite que un cambio de paradigmas ocurra en 2-3 años máximo”, concluye el joven empresario.
                                    </p>
                                </Col>
                                <Col className="mb-3 mx-2">
                                    <img src={FeaturedImage} className="custom-featured-image img-fluid rounded-3" alt='DitoBanx,  startup en servicios Bitcoin,
                                        se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares' />
                                    <div className='color-custom-date py-1 px-3 border-bottom'>Imagen creada por Team Ditobanx</div>
                                </Col>
                                <Col>
                                    <h3 className='fs-5'><strong>Sobre DitoBanx</strong> </h3>
                                    <p className='lh-sm text-justify'>
                                        <strong>DitoBanx</strong> brinda servicios financieros basados en Bitcoin a empresas y personas a nivel latinoamericano para aceptación de pagos en punto de venta y transacciones internacionales.
                                        <br /><br />
                                        <strong>DitoBanx</strong> tiene como propósito impulsar el uso de Bitcoin como un medio estandarizado de pagos y almacenamiento de valor a nivel empresarial.
                                    </p>
                                </Col>
                                <Card className='px-2 pt-4 pb-4 bg-card-custom border-0'>
                                    <Row>
                                        <Col>
                                            <Card.Body className='pt-0'>
                                                <Card.Title className="mb-0 text-center text-md-start">Equipo DitoBanx</Card.Title>
                                                <span className='d-block text-center text-md-start fw-bold'>Noticias</span>
                                                <Card.Text className='d-none lh-1 d-block text-center text-md-start'>
                                                    Presentadora
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                        <Col className='col-12 col-md-3 vertical-align-custom pe-4'>
                                            <div className="col text-center tex-md-end">
                                                <SocialShare
                                                    PostUrl={urlLocation}
                                                    TwitterTitle="DitoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares"
                                                    LinkedInTitle="DitoBanx,  startup en servicios Bitcoin, se constituye como la primera Fintech salvadoreña en lograr fondos de inversión por medio millón de dólares"
                                                    Size="32px"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col className='col-12 col-md-3 px-0 px-md-0 ms-0 ms-md-4'>
                                <Card className='vertical-align-custom rounded-3 shadow-sm mb-5 bg-body border-0 pt-5'>
                                    <img alt='' src={IconBuzon} width={64} />
                                    <Card.Body>
                                        <Card.Title className='fw-bold text-center'>Mantente informado</Card.Title>
                                        <Card.Text className='lh-1 text-center '>
                                            Suscríbase para obtener un resumen de nuestras últimas noticias y boletines informativos.
                                            <MailChimp />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
