import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

/*Import custom Components */
import SocialShare from '../../../Componentes/SocialShare/SocialShare';

import datapost from './dataenlasnoticias.json'

/*Import Images Feed */
import logodineronews from '../../../assets/img/logodinero.png';
import Helmet from 'react-helmet';

const postData = datapost.find((p) => p.slugpost === "Enero");
if (postData === undefined) {
    // mostrar error
}

export default class Enlasnoticias extends React.Component {
    render() {
        const urlLocation = window.location.href;
        return (
            <>
                <Helmet>
                    <title>DitoBanx en las noticias</title>
                </Helmet>
                <div className='mt-5 pt-5 pt-md-4'></div>
                <Container fluid style={{ backgroundColor: '#EFEFEF' }} className='pt-4'>
                    <Container>
                        <Row className='py-4'>
                            <Col className='col-12 col-md-12 rounded-3 shadow-sm p-3 mb-3 mb-md-3 bg-body pt-0'>
                                <div className="row justify-content-between border-bottom py-3">
                                    <div className="col-12 col-md-6 d-flex">
                                        <h1 className='px-2 fw-bold fs-3 lh-1'>DitoBanx en las Noticias</h1>
                                    </div>
                                    <div className="col-12 col-md-6 text-end d-md-inline d-none">
                                        <SocialShare
                                            PostUrl={urlLocation}
                                            TwitterTitle="DitoBanx en las Noticias"
                                            LinkedInTitle="DitoBanx en las Noticias"
                                            Size="32px"
                                            className="d-none"

                                        />
                                    </div>
                                </div>
                                <Col className="pt-4 pb-3 px-2">
                                    <div className='px-3 container'>
                                        {datapost.map((post) => (
                                            <div key={post.id} className="row align-middlerow border-bottom mb-4 pb-4 flex-row-reverse flex-md-row">
                                                 <Col className='text-center text-md-end d-md-none mb-3'>
                                                    <a href={post.urlpost} target="_blank" ref="nofollow noopener noreferrer" rel="noreferrer">
                                                        <img alt='' src={post.urlimage} width={150} />
                                                    </a>
                                                </Col>
                                                <Col className='col-12 col-md-9 pb-4 pb-md-0'>
                                                    <h2 className='fs-5'>
                                                        {' "' + post.title + '" '}
                                                    </h2>
                                                    <span className='text-center text-md-start fw-bold'>{post.datepost}</span>
                                                    <a href={post.urlpost} className='mx-0 mt-2 mx-md-3 d-block d-md-inline btn btn-outline-dark' target="_blank" ref="nofollow noopener noreferrer" rel="noreferrer">Leer noticia </a>
                                                </Col>
                                                <Col className='text-center text-md-end d-none d-md-inline'>
                                                    <a href={post.urlpost} target="_blank" ref="nofollow noopener noreferrer" rel="noreferrer">
                                                        <img alt='' src={post.urlimage} width={150} />
                                                    </a>
                                                </Col>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        )
    }
}
