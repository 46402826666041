import React from 'react';
import logoditobanxwhite from '../../assets/img/ditobanx-white.svg';
import IconFB from '../../assets/img/ico-facebook.svg';
import IconIn from '../../assets/img/ico-in.svg';
import IconTwt from '../../assets/img/ico-twt.svg';
import IconInsta from '../../assets/img/ico-insta.svg';
import GooglePlay from '../../assets/img/googleplay-mv.png';
// import PoliticasPrivacidad from '../../Paginas/PoliticasPrivacidad/PoliticasPrivacidad';
// import TerminosCondiciones from '../../Paginas/TerminosCondiciones/TerminosCondiciones';
import './Footer.css';

function Footer() {
    return (
        <footer className="bg-color pt-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-12 text-center text-lg-start">
                        <img src={logoditobanxwhite} className="d-inline-block align-text-top img-fluid" alt="DitoBanx El Salvador" width="300" />
                        <hr/>
                        <p className="d-flex justify-content-center lh-sm justify-content-sm-start link-secondary">
                        Soluciones empresariales bitcoin para aceptacion de pagos y transacciones cross-border | El Salvador
                        </p>
                    </div>
                    <div className="col-md-2 col-6">
                        <h3 className="fs-6 mb-3 text-uppercase">Area de Clientes</h3><hr />
                        <ul className="list-unstyled text-small">
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://portal.ditopay.io">Portal Administrativo</a></li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://portal.ditopay.io/register">Aperturar cuenta</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-6">
                        <h3 className="fs-6 mb-3 text-uppercase">Empresa</h3><hr />
                        <ul className="list-unstyled text-small">
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href={'contacto'}>Contacto</a></li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href={'polica-de-privacidad'}>Políticas de Privacidad</a></li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href={'terminos-y-condiciones'}>Términos y Condiciones </a></li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-12 text-center text-lg-start">
                        <h3 className="fs-6 text-uppercase">Aplicaciones móviles</h3><hr />
                        <a target="_blank" rel="nofollow noreferrer noopenner" href='https://play.google.com/store/apps/details?id=com.dito.ditowallet' >
                            <img src={GooglePlay} alt="" className='img-fluid'/> <br /><br />
                        </a>
                        <p className="link-secondary text-decoration-none">
                            <span style={{marginBottom:"-30px", display:"block"}}>Siguenos</span><br/>
                            <a target="_blank" rel="nofollow noreferrer noopenner" href="https://www.facebook.com/ditobanx">
                                <img src={IconFB} className="social-icon d-inline-block align-text-top mt-2 me-2" alt="Facebook Dito Pay" width="26px" />
                            </a>
                            <a target="_blank" rel="nofollow noreferrer noopenner" href="https://www.linkedin.com/company/ditobanx">
                                <img src={IconIn} className="social-icon d-inline-block align-text-top m-2" alt="Linkedin Dito Pay" width="26px" />
                            </a>
                            <a target="_blank" rel="nofollow noreferrer noopenner" href="https://twitter.com/ditobanx">
                                <img src={IconTwt} className="social-icon d-inline-block align-text-top m-2" alt="Twitter Dito Pay" width="26px" />
                            </a>
                            <a target="_blank" rel="nofollow noreferrer noopenner" href="https://www.instagram.com/ditobanx/">
                                <img src={IconInsta} className="social-icon d-inline-block align-text-top m-2" alt="Instagram Dito Pay" width="26px" />
                            </a>
                        </p>
                    </div>
                </div>
                <div className="row border-top border-secondary pb-1 pt-1">
                    <div className="col-sm text-center link-secondary ts-foot">
                        Institucion supervisada por la Super Intendencia del Sistema Financiero de El Salvador. Para consultas o quejas no resueltas puede contactar a : atencionalusuario@ssf.gob.sv
                    </div>
                </div>
                <div className="row border-secondary pb-2 pt-1">
                    <div className="col-sm text-center">
                        <a className="link-secondary text-decoration-none p-2" href="https://ditobanx.com">DitoBanx ® | 2022 .Todos los derechos reservados</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer;