import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";//link
import Inicio from "../Paginas/Inicio/Inicio";
import PosFisico from "../Paginas/Productos/PosFisico";
import Precio from "../Paginas/Precio/Precio";
/*import Faqs from "../Paginas/Faqs/Faqs";*/
import Contacto from "../Paginas/Contacto/Contacto";
import Footer from "../Componentes/Footer/Footer";
import NavbarHeader from "../Componentes/Navbar/Navbar";
import NoticiasPrensa from "../Paginas/Noticias/NoticiasPrensa/NoticiasPrensa";
import Articulos from "../Paginas/Noticias/Articulos/Articulos";
// import GiftCards from "../Paginas/Giftcards/GiftCards";
import PoliticasPrivacidad from "../Paginas/PoliticasPrivacidad/PoliticasPrivacidad";
import TerminosCondiciones from "../Paginas/TerminosCondiciones/TerminosCondiciones";
/*Comunicados */
import PlantillaComunicado from "../Paginas/Noticias/NoticiasPrensa/ComunicadosPrensa/PlantillaComunicado/PlantillaComunicado";
import ComunicadoGiftcard from "../Paginas/Noticias/NoticiasPrensa/ComunicadosPrensa/GiftcardComunicado/giftcard";
import BoltComunicado from "../Paginas/Noticias/NoticiasPrensa/ComunicadosPrensa/DitoBanxBoltComunicado/boltComunicado";
 import SinglePost from "../Paginas/Noticias/Articulos/SinglePost/SinglePost";
 import Historia from "../Paginas/SobreNosotros/Historia/Historia";
 import Enlasnoticias from "../Paginas/Noticias/Enlasnoticias/Enlasnoticias"
 import CompraBitcoin from '../Paginas/CompraBitcoin/CompraBitcoin';
import  DitoWallet  from "../Paginas/DitoWallet/DitoWallet";
import FooterV2 from "../Componentes/FooterV2/FooterV2";
import { useEffect, useRef } from "react";
import QredoComunicado from "../Paginas/Noticias/NoticiasPrensa/ComunicadosPrensa/QredoComunicado/qredoComunicado";
import ActDigEspComunicado from "../Paginas/Noticias/NoticiasPrensa/ComunicadosPrensa/DitoActDigEspComunicado/actDigEspComunicado";
import ActDigEngComunicado from "../Paginas/Noticias/NoticiasPrensa/ComunicadosPrensa/DitoActDigEngComunicado/actDigEngComunicado";
import WalletTerminosCondiciones from "../Paginas/WalletTerminosCondiciones/WalletTerminosCondiciones";
import NeutronpayComunicado from "../Paginas/Noticias/NoticiasPrensa/ComunicadosPrensa/NeutronpayComunicado/neutronpayComunicado";
import MastercardComunicado from "../Paginas/Noticias/NoticiasPrensa/ComunicadosPrensa/MastercardComunicado/mastercardComunicado";
import MastercardComunicadoEn from "../Paginas/Noticias/NoticiasPrensa/ComunicadosPrensa/MastercardComunicadoEn/mastercardComunicadoEn";


function App() {

    let location = useLocation();
    const main = useRef();

    useEffect(() => {

        setTimeout(() => {
            
            main.current.classList.remove("animate__fadeIn");
            main.current.classList.remove("animate__animated");
        }, 3000);
   
    }, []);

    
    return (
        <Router>
            <div className="App">
                <NavbarHeader />
                <div className="content animate__animated animate__fadeIn" ref={main}>
                    <Switch>

                        <Route exact path="/" component={Inicio} />
                        <Route exact path="/procesamiento-de-pagos" component={PosFisico} />
                        <Route exact path="/compra-bitcoin-usdc" component={CompraBitcoin} />
                        <Route exact path="/dito-wallet" component={DitoWallet} />
                        <Route exact path="/contacto" component={Contacto} />

                        <Route path="/noticias-prensa" component={ComunicadosPrensaRouter} />
                        <Route path="/nuestra-historia" component={Historia} />

                        <Route exact path="/en-las-noticias" component={Enlasnoticias} />
                        
                        <Route exact path="/articulos" component={Articulos} />
                        <Route exact path="/articulos/:name" component={SinglePost} />


                        <Route exact path="/precio" component={Precio} />
                        <Route exact path="/polica-de-privacidad" component={PoliticasPrivacidad} />
                        <Route exact path="/terminos-y-condiciones" component={TerminosCondiciones} />
                        <Route exact path="/wallet-terminos-y-condiciones" component={WalletTerminosCondiciones} />
                        <Route path="*" component={Inicio} />

                    </Switch>
                </div>
                {location.pathname === "/dito-wallet" ? <FooterV2 /> : <Footer />}
            </div>
        </Router>
    );
}

export default App;

function ComunicadosPrensaRouter() {
    return (
        <Switch>
            
            <Route exact path="/noticias-prensa" component={NoticiasPrensa}  />
            <Route exact path="/noticias-prensa/ditoBanx-startup-en-servicios-bitcoin" component={PlantillaComunicado} />
            <Route exact path="/noticias-prensa/ditobanx-giftcard" component={ComunicadoGiftcard}  />
            <Route exact path="/noticias-prensa/ditobanx-bolt" component={BoltComunicado} />
            <Route exact path="/noticias-prensa/ditobanx-qredo" component={QredoComunicado}></Route>
            <Route exact path='/noticias-prensa/ditobanx-activos-digitales-es' component={ActDigEspComunicado}></Route>
            <Route exact path='/noticias-prensa/ditobanx-activos-digitales-en' component={ActDigEngComunicado}></Route>
            <Route exact path='/noticias-prensa/ditobanx-neutronpay' component={NeutronpayComunicado}></Route>
            <Route exact path='/noticias-prensa/ditobanx-mastercard-es' component={MastercardComunicado}></Route>
            <Route exact path='/noticias-prensa/ditobanx-mastercard-en' component={MastercardComunicadoEn}></Route>


        </Switch>
    );
}