import React, { Component } from 'react'
// import { BrowserRouter as Router, Link } from "react-router-dom";
/*Import Assets */
import IconFB from '../../assets/img/ico-social/ico-fb.svg';
import IconIn from '../../assets/img/ico-social/ico-in.svg';
import IconTwt from '../../assets/img/ico-social/ico-twt.svg';
// import IconInsta from '../../assets/img/ico-social/ico-insta.svg';

import './SocialShare.css'

export default class SocialShare extends Component {
    render() {
        return (
            <>
                
                    <div className='text-center text-md-center'>
                        <span className={this.props.fontSize + " " + this.props.className}>Comparte</span>
                    </div>
                    <a href={"https://www.facebook.com/sharer/sharer.php?u="+ this.props.PostUrl } target="_blank" rel="nofollow noreferrer">
                        <img src={IconFB} className="social-icon d-inline-block align-text-top  mx-1 " alt="Facebook Dito Pay" width={this.props.Size} />
                    </a>
                    <a href={"https://www.linkedin.com/shareArticle?url=" + this.props.PostUrl + "&title="+ this.props.LinkedInTitle} target="_blank" rel="nofollow noreferrer" >
                        <img src={IconIn} className="social-icon d-inline-block align-text-top mx-1" alt="Linkedin Dito Pay" width={this.props.Size} />
                    </a>
                    <a href={"https://twitter.com/share?url="+ this.props.PostUrl + "&text="+ this.props.TwitterTitle +"&via=ditobanx"} target="_blank" rel="nofollow noreferrer" >
                        <img src={IconTwt} className="social-icon d-inline-block align-text-top mx-1" alt="Twitter Dito Pay" width={this.props.Size} />
                    </a>
                    {/* <a target="_blank" rel="nofollow noreferrer" href="https://www.instagram.com/ditobanx/">
                        <img src={IconInsta} className="social-icon d-inline-block align-text-top mx-1" alt="Instagram Dito Pay" width={this.props.Size} />
                    </a> */}
                
            </>
        )
    }
}
