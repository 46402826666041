import React from 'react';
import emailjs from 'emailjs-com';
import './Contacto.css';

import IconFB from '../../assets/img/ico-facebook-dark.svg';
import IconIn from '../../assets/img/ico-in-dark.svg';
import IconTwt from '../../assets/img/ico-twt-dark.svg';
import IconInsta from '../../assets/img/ico-insta-dark.svg';
import IconWha from '../../assets/img/ico-wha.svg';
import Helmet from 'react-helmet';

export default function Contacto() {

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_4hi63au', 'template_m1avv5f', e.target, 'user_U1Zt5WH0kvNLMktLX2982')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div id="Contactenos" className="mt-5 bg-light pb-5">
            <Helmet>
                <title>Contáctenos | DitoBanx</title>
            </Helmet>
            <br /><br />
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col py-4">
                            <h1 className="text-center PrimaryColor fw-bolder ls-2 Tspace">Contactenos</h1>
                            <p className="mt- fs-5 text-center lh-sm">
                                Contactenos ahora, sera un gusto poder ayudarle.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="container bg-white shadow p-3 bg-body rounded-3">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <form className="row g-3 p-3 contact-form" onSubmit={sendEmail}>
                                            <div className="col-12">
                                                <label className="form-label fs-5">Nombre</label>
                                                <input type="text" className="form-control form-control-lg" name="name" placeholder="Nombre" aria-label="Nombre" />
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label fs-5">Correo electronico</label>
                                                <input type="email" name="email" className="form-control form-control-lg" placeholder="example@dominio.com" id="inputEmail4" />
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label fs-5">Mensaje</label>
                                                <textarea className="form-control form-control-lg" name="message" id="message" placeholder="Escriba su mensaje" rows="3"></textarea>
                                            </div>
                                            <div class="g-recaptcha" data-sitekey="6LdJxfIdAAAAAFxYRX2xPUS8JCO3l9u2UxJYxPXm"></div>
                                            <div className="col-12 d-flex justify-content-center justify-content-lg-end ">
                                                <input type="submit" value="ENVIAR" className="btn btn-primary btn-lg text-end" />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-lg-6 text-center text-lg-start">
                                        <h3 className="fs-4 border-bottom border-2 pb-2 me-0 mt-5 mt-lg-0 me-lg-5 text-center">
                                            Otras formas de contacto
                                        </h3>
                                        <br />
                                        <h4 className="fw-bold fs-5">
                                            CORREOS
                                        </h4>
                                        <p>
                                            <a href="mailto:hola@ditobanx.com" className="link-primary text-decoration-none">hola@ditobanx.com</a>
                                            <br />
                                            <a href="mailto:soporte@ditobanx.com" className="link-primary text-decoration-none">soporte@ditobanx.com</a>
                                        </p>
                                        <h4 className="fw-bold fs-5">
                                            TÉLEFONO
                                        </h4>
                                        <p className="lh-sm">
                                        <a
                                            target="_blank"
                                            rel="nofollow noreferrer"
                                            className="text-black text-decoration-none text-navbar-whatsapp"
                                            href="https://wa.me/+50322392425"
                                            
                                        >
                                            WhatsApp
                                           
                                        </a>
                                        </p>
                                        <h4 className="fw-bold fs-5">
                                            SIGUENOS EN:
                                        </h4>
                                        <a target="_blank" rel="nofollow noreferrer" href="https://www.facebook.com/ditobanx">
                                            <img src={IconFB} className="d-inline-block align-text-top mt-2 me-2" alt="Facebook DitoBanx" width="32px" />
                                        </a><a target="_blank" rel="nofollow noreferrer" href="https://www.linkedin.com/company/ditobanx">
                                            <img src={IconIn} className="d-inline-block align-text-top m-2" alt="Linkedin DitoBanx" width="32px" />
                                        </a><a target="_blank" rel="nofollow noreferrer" href="https://twitter.com/ditobanx">
                                            <img src={IconTwt} className="d-inline-block align-text-top m-2" alt="Twitter DitoBanx" width="32px" />
                                        </a>
                                        <a target="_blank" rel="nofollow noreferrer" href="https://www.instagram.com/ditobanx/">
                                            <img src={IconInsta} className="d-inline-block align-text-top m-2" alt="Instagram DitoBanx" width="32px" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}